.payment-result-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 80px);
  padding: 20px;
  background: #f8f9fa;
}

.success-container,
.failure-container {
  text-align: center;
  padding: 48px;
  background: white;
  border-radius: 16px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.08);
  width: 100%;
  max-width: 440px;
}

.success-icon,
.failure-icon {
  margin-bottom: 24px;
}

.success-container h2,
.failure-container h2 {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 12px;
}

.success-container h2 {
  color: #2E7D32;
}

.message {
  font-size: 16px;
  color: #666;
  margin-bottom: 32px;
}

.redirect-message {
  margin-top: 32px;
}

.redirect-message p {
  color: #666;
  font-size: 14px;
  margin-top: 12px;
}

.loading-bar {
  height: 4px;
  background: #e0e0e0;
  border-radius: 2px;
  overflow: hidden;
}

.loading-progress {
  height: 100%;
  background: #4CAF50;
  transition: width 0.3s ease;
}

/* Animation for success icon */
.success-icon svg {
  animation: scaleIn 0.3s ease-out;
}

@keyframes scaleIn {
  0% { transform: scale(0); }
  70% { transform: scale(1.2); }
  100% { transform: scale(1); }
} 