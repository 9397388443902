.sector-overview-container {
  padding: 24px 32px;
}

.sector-overview-metrics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 24px;
  margin-bottom: 40px;
}

.sector-overview-metric-card {
  background: #ffffff;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border: 1px solid #f0f0f0;
  transition: all 0.3s ease;
}

.sector-overview-metric-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.sector-overview-metric-label {
  font-size: 15px;
  color: #666666;
  margin-bottom: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 500;
}

.sector-overview-metric-value {
  font-size: 24px;
  color: #1a1a1a;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 12px;
}

.sector-overview-change {
  font-size: 16px;
  padding: 4px 10px;
  border-radius: 6px;
  font-weight: 500;
}

.sector-overview-change.positive {
  color: #2E7D32;
  background-color: rgba(46, 125, 50, 0.1);
}

.sector-overview-change.negative {
  color: #D32F2F;
  background-color: rgba(211, 47, 47, 0.1);
}

.sector-overview-volume-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 16px;
  color: #1a1a1a;
  margin-top: 8px;
}

.sector-overview-description {
  margin-top: 32px;
  background: #ffffff;
  border-radius: 12px;
  padding: 32px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border: 1px solid #f0f0f0;
}

.sector-overview-description-title {
  font-size: 24px;
  color: #1a1a1a;
  font-weight: 600;
  margin-bottom: 20px;
}

.sector-overview-description-content {
  font-size: 16px;
  line-height: 1.8;
  color: #333333;
  letter-spacing: 0.2px;
}

.sector-overview-description-toggle {
  margin-top: 12px;
  background: none;
  border: none;
  color: #1a73e8;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0;
}

.sector-overview-description-toggle:hover {
  text-decoration: underline;
}

.sector-overview-nav-premium {
  font-size: 13px;
  color: #666666;
  margin-top: 4px;
}

@media (max-width: 768px) {
  .sector-overview-container {
    padding: 16px;
  }
  
  .sector-overview-metrics-grid {
    grid-template-columns: 1fr;
  }
  
  .sector-overview-metric-card {
    padding: 20px;
  }
}

.sector_etf_overview-container {
  padding: 32px;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.06);
}

.sector_etf_overview-header {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 32px;
}

.sector_etf_overview-header h1 {
  font-size: 28px;
  color: #1a1a1a;
  margin: 0;
}

.sector_etf_overview-sector-tag {
  background: #e8f0fe;
  color: #1a73e8;
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 500;
}

.sector_etf_overview-metrics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 24px;
  margin-bottom: 40px;
}

.sector_etf_overview-metric-card {
  background: #ffffff;
  border-radius: 16px;
  padding: 24px;
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sector_etf_overview-metric-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
}

.sector_etf_overview-metric-label {
  font-size: 14px;
  color: #666666;
  margin-bottom: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.sector_etf_overview-metric-value {
  font-size: 20px;
  color: #1a1a1a;
  font-weight: 600;
}

/* 其他样式与StockOverview类似，但使用sector_etf_overview前缀 */

.sector-overview-chart-section {
  margin-top: 32px;
  background: #ffffff;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border: 1px solid #f0f0f0;
}

.sector-overview-chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.sector-overview-chart-title {
  font-size: 24px;
  color: #1a1a1a;
  font-weight: 600;
  margin: 0;
}

.sector-overview-time-range {
  display: flex;
  gap: 8px;
}

.sector-overview-time-button {
  padding: 6px 12px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  background: #ffffff;
  color: #666666;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.sector-overview-time-button:hover {
  background: #f5f5f5;
}

.sector-overview-time-button.active {
  background: #1a73e8;
  color: #ffffff;
  border-color: #1a73e8;
}

.sector-overview-chart {
  width: 100%;
  height: 400px;
  position: relative;
}

/* 专门针对 Tooltip 内容的样式 */
.sector-overview-metric-label .tooltip-content {
  text-transform: none;  /* 防止继承全大写样式 */
  font-weight: normal;   /* 使用正常字重 */
  letter-spacing: normal;  /* 使用正常字间距 */
  line-height: 1.5;      /* 适当的行高 */
  font-size: 14px;       /* 合适的字体大小 */
}

/* 保持原有卡片标签的样式不变 */
.sector-overview-metric-label {
  text-transform: uppercase;  /* 标签保持大写 */
  font-weight: 500;
  letter-spacing: 0.5px;
}

.sector-overview-score-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}

.sector-overview-score-details {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.sector-overview-score-value {
  font-size: 24px;
  font-weight: 600;
  color: #1a1a1a;
  line-height: 1.2;
}

.sector-overview-score-label {
  font-size: 14px;
  color: #666666;
}

.sector-overview-grade {
  padding: 4px 8px;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 600;
  min-width: 32px;
  text-align: center;
}

/* Heat grades */
.sector-overview-grade.heat-a {
  background-color: rgba(27, 94, 32, 0.1);
  color: #1B5E20;
}

.sector-overview-grade.heat-b {
  background-color: rgba(46, 125, 50, 0.1);
  color: #2E7D32;
}

.sector-overview-grade.heat-c {
  background-color: rgba(251, 192, 45, 0.1);
  color: #F9A825;
}

.sector-overview-grade.heat-d {
  background-color: rgba(211, 47, 47, 0.1);
  color: #D32F2F;
}

.sector-overview-grade.heat-e {
  background-color: rgba(183, 28, 28, 0.1);
  color: #B71C1C;
}

/* Risk grades */
.sector-overview-grade.risk-a {
  background-color: rgba(46, 125, 50, 0.1);
  color: #2E7D32;
}

.sector-overview-grade.risk-b {
  background-color: rgba(56, 142, 60, 0.1);
  color: #388E3C;
}

.sector-overview-grade.risk-c {
  background-color: rgba(251, 192, 45, 0.1);
  color: #F9A825;
}

.sector-overview-grade.risk-d {
  background-color: rgba(211, 47, 47, 0.1);
  color: #D32F2F;
}

.sector-overview-grade.risk-e {
  background-color: rgba(183, 28, 28, 0.1);
  color: #B71C1C;
}

@media (max-width: 768px) {
  .sector-overview-score-value {
    font-size: 20px;
  }
  
  .sector-overview-score-label {
    font-size: 12px;
  }
  
  .sector-overview-grade {
    font-size: 14px;
    padding: 3px 6px;
  }
}

/* Heat Score Styles */
.sector-overview-heat-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 80px;
}

.sector-overview-heat-circle {
  position: relative;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sector-overview-heat-value {
  font-size: 24px;
  font-weight: 600;
  z-index: 2;
}

.sector-overview-heat-rings {
  position: absolute;
  width: 100%;
  height: 100%;
}

.heat-ring {
  position: absolute;
  border-radius: 50%;
  border: 2px solid;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  animation: pulse 2s infinite;
}

.heat-ring:nth-child(2) {
  animation-delay: 0.5s;
}

.heat-ring:nth-child(3) {
  animation-delay: 1s;
}

/* Heat colors */
.heat-a, .heat-ring.heat-a { color: #1B5E20; }
.heat-b, .heat-ring.heat-b { color: #2E7D32; }
.heat-c, .heat-ring.heat-c { color: #F9A825; }
.heat-d, .heat-ring.heat-d { color: #D32F2F; }
.heat-e, .heat-ring.heat-e { color: #B71C1C; }

/* Risk Rating Styles */
.sector-overview-risk-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 0;
}

.sector-overview-risk-meter {
  width: 100%;
  position: relative;
  height: 48px;
  background: #f5f5f5;
  border-radius: 24px;
  overflow: hidden;
}

.sector-overview-risk-level {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  transition: width 0.6s ease-in-out;
}

.sector-overview-risk-label {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  font-weight: 600;
  color: #1a1a1a;
  z-index: 2;
  white-space: nowrap;
}

/* Risk colors with gradients */
.sector-overview-risk-level.risk-a {
  background: linear-gradient(90deg, rgba(46, 125, 50, 0.1), rgba(46, 125, 50, 0.3));
}

.sector-overview-risk-level.risk-b {
  background: linear-gradient(90deg, rgba(56, 142, 60, 0.1), rgba(56, 142, 60, 0.3));
}

.sector-overview-risk-level.risk-c {
  background: linear-gradient(90deg, rgba(251, 192, 45, 0.1), rgba(251, 192, 45, 0.3));
}

.sector-overview-risk-level.risk-d {
  background: linear-gradient(90deg, rgba(211, 47, 47, 0.1), rgba(211, 47, 47, 0.3));
}

.sector-overview-risk-level.risk-e {
  background: linear-gradient(90deg, rgba(183, 28, 28, 0.1), rgba(183, 28, 28, 0.3));
}

/* Add subtle animation */
@keyframes pulse-bg {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.8;
  }
}

.sector-overview-risk-level {
  animation: pulse-bg 2s infinite;
}

@media (max-width: 768px) {
  .sector-overview-heat-circle {
    width: 60px;
    height: 60px;
  }

  .sector-overview-heat-value {
    font-size: 20px;
  }

  .sector-overview-risk-meter {
    height: 36px;
  }

  .sector-overview-risk-label {
    font-size: 14px;
  }
} 