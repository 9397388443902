.etf-screener-container {
  padding: 20px;
  width: 100%;
  overflow-x: hidden;
}

.etf-screener-title {
  font-size: 28px;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid #eee;
}

/* 表格样式 */
.etf-screener-table-wrapper {
  width: 100%;
  overflow-x: auto;
  border-radius: 8px;
  background: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.etf-screener-table {
  width: 100%;
  border-collapse: collapse;
  background: white;
}

.etf-screener-table th {
  background: #f8f9fa;
  font-weight: 500;
  color: #5f6368;
  border-bottom: 1px solid #e0e0e0;
}

.etf-screener-table td {
  border-bottom: 1px solid #f0f0f0;
}

.etf-screener-table tr:hover {
  background-color: #f8f9fa;
}

/* 列宽度设置 */
.etf-screener-table th,
.etf-screener-table td {
  padding: 12px 16px;
}

/* Ticker 列宽度 */
.etf-screener-table th:nth-child(1),
.etf-screener-table td:nth-child(1) {
  width: 80px;
  min-width: 80px;
}

/* Name 列宽度 */
.etf-screener-table th:nth-child(2),
.etf-screener-table td:nth-child(2) {
  width: 240px;
  min-width: 240px;
}

/* Price 列宽度 */
.etf-screener-table th:nth-child(3),
.etf-screener-table td:nth-child(3) {
  width: 120px;
  min-width: 120px;
  text-align: right;
}

/* Expense Ratio 列宽度 */
.etf-screener-table th:nth-child(4),
.etf-screener-table td:nth-child(4) {
  width: 100px;
  min-width: 100px;
  text-align: right;
}

/* Market Cap 列宽度 */
.etf-screener-table th:nth-child(5),
.etf-screener-table td:nth-child(5) {
  width: 100px;
  min-width: 100px;
  text-align: right;
}

/* 指标列宽度 (Correlation, Premium, Risk, Recommendation) */
.etf-screener-table th:nth-child(n+6),
.etf-screener-table td:nth-child(n+6) {
  width: 140px;
  min-width: 140px;
  text-align: center;
}

.etf-screener-table th[align="right"],
.etf-screener-table td[align="right"] {
  text-align: right;
}

.etf-screener-table th[align="center"],
.etf-screener-table td[align="center"] {
  text-align: center;
}

.etf-screener-ticker {
  color: #1a73e8;
  cursor: pointer;
  font-weight: 500;
}

.etf-screener-ticker:hover {
  text-decoration: underline;
}

.etf-screener-price {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 2px;
  width: 100%;
}

.etf-screener-price-value {
  font-weight: 500;
}

.etf-screener-return {
  font-size: 12px;
}

.etf-screener-return.positive {
  color: #34a853;
}

.etf-screener-return.negative {
  color: #ea4335;
}

/* ETF类型标签样式 */
.etf-type-tag {
  padding: 4px 12px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
}

.etf-type-tag.spot {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.etf-type-tag.futures {
  background-color: #e3f2fd;
  color: #1976d2;
}

.etf-type-tag.equity {
  background-color: #fff3e0;
  color: #f57c00;
}

/* 评级样式 */
.etf-screener-grade {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
}

.etf-screener-grade-bar {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.etf-screener-grade-label {
  color: white;
  font-weight: 600;
  font-size: 14px;
}

.etf-screener-grade-description {
  font-size: 14px;
  color: #666;
}

/* 筛选器样式 */
.etf-screener-filter-section {
  margin: 0;
  padding: 24px;
  border-radius: 12px;
}

.etf-screener-filter-controls {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 32px;
}

.etf-screener-filter-group {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.etf-screener-filter-header {
  font-size: 15px;
  font-weight: 600;
  color: #202124;
  padding-bottom: 8px;
  border-bottom: 2px solid #f1f3f4;
}

.etf-screener-checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.etf-screener-checkbox-label {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 4px 0;
  cursor: pointer;
  font-size: 14px;
  color: #5f6368;
  transition: all 0.2s ease;
}

.etf-screener-checkbox-label:hover {
  color: #1a73e8;
  background: #f8f9fa;
  border-radius: 4px;
  padding: 4px 8px;
  margin: 0 -8px;
}

/* 复选框样式优化 */
.etf-screener-checkbox-label input[type="checkbox"] {
  width: 18px;
  height: 18px;
  border: 2px solid #dadce0;
  border-radius: 3px;
  appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease;
}

.etf-screener-checkbox-label input[type="checkbox"]:checked {
  background-color: #1a73e8;
  border-color: #1a73e8;
}

.etf-screener-checkbox-label input[type="checkbox"]:checked::after {
  content: '';
  position: absolute;
  left: 5px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* 筛选标签样式优化 */
.etf-screener-filter-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin: 16px 0;
}

.filter-tag {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 6px 12px;
  background: #f1f3f4;
  border-radius: 16px;
  font-size: 13px;
  color: #202124;
  transition: all 0.2s ease;
}

.filter-tag:hover {
  background: #e8eaed;
}

.filter-tag-type {
  font-weight: 500;
  color: #5f6368;
}

.filter-tag-value {
  color: #1a73e8;
}

.filter-tag-remove {
  border: none;
  background: none;
  color: #5f6368;
  font-size: 18px;
  line-height: 1;
  padding: 0 0 0 4px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.filter-tag-remove:hover {
  color: #ea4335;
}

/* 响应式布局调整 */
@media (max-width: 768px) {
  .etf-screener-filter-controls {
    grid-template-columns: 1fr;
    gap: 24px;
  }
}

/* 滚动条容器样式 */
.etf-screener-scroll-container {
  position: relative;
  margin-top: 16px;
}

/* 顶部滚动条样式 */
.etf-screener-top-scroll-wrapper {
  width: 100%;
  overflow-x: auto;
  margin-bottom: 4px;
  border-radius: 4px;
  background: #f8f9fa;
}

/* 隐藏顶部滚动条的内容 */
.etf-screener-scroll-content {
  height: 1px;
  visibility: hidden;
}

/* 自定义滚动条样式 */
.etf-screener-top-scroll-wrapper::-webkit-scrollbar,
.etf-screener-table-wrapper::-webkit-scrollbar {
  height: 8px;
}

.etf-screener-top-scroll-wrapper::-webkit-scrollbar-track,
.etf-screener-table-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.etf-screener-top-scroll-wrapper::-webkit-scrollbar-thumb,
.etf-screener-table-wrapper::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 4px;
}

.etf-screener-top-scroll-wrapper::-webkit-scrollbar-thumb:hover,
.etf-screener-table-wrapper::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

/* 响应式布局 */
@media (max-width: 768px) {
  .etf-screener-container {
    padding: 16px;
  }
  
  .etf-screener-title {
    font-size: 24px;
  }
} 

/* 通用指标样式 */
.etf-screener-indicator {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

/* 渐变条样式 (Correlation_BTC 和 Recommendation) */
.etf-screener-indicator-bar {
  position: relative;
  width: 100px;
  height: 4px;
  border-radius: 2px;
  background: #f0f0f0;
  overflow: hidden;
}

.etf-screener-indicator-pointer {
  position: absolute;
  top: -3px;
  width: 2px;
  height: 10px;
  background: #1a1a1a;
  transform: translateX(-50%);
}

/* 水平条形图样式 (Premium 和 Risk) */
.etf-screener-bars-container {
  display: flex;
  flex-direction: row;
  gap: 2px;
  width: 100px;
  height: 4px;
}

.etf-screener-bar-wrapper {
  flex: 1;
  height: 100%;
}

.etf-screener-bar {
  width: 100%;
  height: 100%;
  background: #e0e0e0;
}

.etf-screener-bar-wrapper.active .etf-screener-bar {
  background: currentColor;
}

/* Premium 颜色 */
.etf-screener-bar.premium-A { color: #34a853; }
.etf-screener-bar.premium-B { color: #93c47d; }
.etf-screener-bar.premium-C { color: #fbbc04; }
.etf-screener-bar.premium-D { color: #ea8235; }
.etf-screener-bar.premium-E { color: #ea4335; }

/* Risk 颜色 */
.etf-screener-bar.risk-A { color: #34a853; }
.etf-screener-bar.risk-B { color: #93c47d; }
.etf-screener-bar.risk-C { color: #fbbc04; }
.etf-screener-bar.risk-D { color: #ea8235; }
.etf-screener-bar.risk-E { color: #ea4335; }

/* 标签样式 */
.etf-screener-indicator-label {
  font-size: 12px;
  color: #5f6368;
  white-space: nowrap;
}

/* 调整表格单元格样式以适应指示器 */
.etf-screener-table td {
  padding: 8px 16px;
  vertical-align: middle;
}

/* 确保指示器列有足够宽度 */
.etf-screener-table th[data-type="indicator"],
.etf-screener-table td[data-type="indicator"] {
  min-width: 120px;
}

/* 指标值和级别的样式 */
.etf-screener-metric {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 2px;
  width: 100%;
}

.etf-screener-metric-value {
  font-weight: 500;
  color: #1a1a1a;
}

.etf-screener-metric-rank {
  font-size: 11px;
  padding: 1px 6px;
  border-radius: 4px;
  font-weight: 500;
}

/* Expense Ratio 级别颜色 */
.etf-screener-metric-rank.low {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.etf-screener-metric-rank.medium {
  background-color: #fff3e0;
  color: #f57c00;
}

.etf-screener-metric-rank.high {
  background-color: #fbe9e7;
  color: #d32f2f;
}

/* Market Cap 级别颜色 */
.etf-screener-metric-rank.large {
  background-color: #e3f2fd;
  color: #1976d2;
}

.etf-screener-metric-rank.medium {
  background-color: #fff3e0;
  color: #f57c00;
}

.etf-screener-metric-rank.small {
  background-color: #f3e5f5;
  color: #7b1fa2;
}

/* 调整表格单元格垂直对齐 */
.etf-screener-table td {
  vertical-align: middle;
}

/* 排序指示器样式 */
.etf-screener-sort-indicators {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 16px 0;
  flex-wrap: wrap;
}

.sort-indicators-label {
  color: #5f6368;
  font-size: 14px;
}

.sort-indicator {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  background: #f1f3f4;
  border-radius: 16px;
  font-size: 13px;
}

.sort-indicator-text {
  display: flex;
  align-items: center;
  gap: 4px;
}

.sort-indicator-order {
  font-size: 11px;
  color: #5f6368;
}

.sort-indicator-direction {
  color: #1a73e8;
  font-weight: bold;
}

.sort-indicator-remove {
  border: none;
  background: none;
  color: #5f6368;
  cursor: pointer;
  padding: 0 4px;
  font-size: 16px;
  line-height: 1;
}

.sort-indicator-remove:hover {
  color: #ea4335;
}

/* 表头排序样式 */
.sortable-header {
  cursor: pointer;
  user-select: none;
}

.etf-screener-th-content {
  display: flex;
  align-items: center;
  gap: 4px;
}

.sort-arrows {
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-left: 4px;
}

.sort-arrow {
  font-size: 8px;
  color: #bdc1c6;
  line-height: 1;
}

.sorted-ascending .sort-arrow.up,
.sorted-descending .sort-arrow.down {
  color: #1a73e8;
}

.sortable-header:hover .sort-arrow {
  color: #5f6368;
}

/* 添加指针样式和悬停效果 */
.etf-screener-ticker-cell {
  cursor: pointer;
  color: #1a73e8;
  transition: color 0.2s ease;
}

.etf-screener-ticker-cell:hover {
  color: #174ea6;
  text-decoration: underline;
} 