.sector-holdings-container {
  padding: 24px 32px;
  background: #ffffff;
  overflow: visible;
}

.sector-holdings-metrics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 24px;
  margin-bottom: 32px;
  overflow: visible;
}

.sector-holdings-metric-card {
  background: #ffffff;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border: 1px solid #f0f0f0;
  transition: all 0.3s ease;
  overflow: visible;
}

.sector-holdings-metric-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.sector-holdings-metric-label {
  font-size: 14px;
  color: #666666;
  text-transform: uppercase;
  margin-bottom: 12px;
  letter-spacing: 0.5px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
}

.sector-holdings-metric-value {
  font-size: 24px;
  color: #1a1a1a;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;
}

.sector-holdings-premium {
  font-size: 24px;
  padding: 4px 8px;
  border-radius: 6px;
  font-weight: 600;
}

.sector-holdings-premium.positive {
  color: #2E7D32;
  background-color: rgba(46, 125, 50, 0.1);
}

.sector-holdings-premium.negative {
  color: #D32F2F;
  background-color: rgba(211, 47, 47, 0.1);
}

@media (max-width: 768px) {
  .sector-holdings-container {
    padding: 16px;
  }
  
  .sector-holdings-metrics-grid {
    grid-template-columns: 1fr;
  }
  
  .sector-holdings-metric-card {
    padding: 20px;
  }
  
  .sector-holdings-metric-value {
    font-size: 20px;
  }
  
  .sector-holdings-premium {
    font-size: 20px;
  }
}

.sector-holdings-chart {
  margin-top: 40px;
  padding: 24px;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.06);
}

.sector-holdings-chart-title {
  font-size: 20px;
  font-weight: 600;
  color: #333;
  margin-bottom: 24px;
}

.sector-holdings-content {
  display: flex;
  gap: 24px;
}

.sector-holdings-chart-container {
  flex: 1;
  min-width: 0;
}

.sector-holdings-table {
  flex: 1;
  min-width: 0;
  max-height: 400px;
  overflow-y: auto;
}

.sector-holdings-table table {
  width: 100%;
  border-collapse: collapse;
}

.sector-holdings-table th,
.sector-holdings-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.sector-holdings-table th {
  font-weight: 500;
  color: #666;
  background: #f8f9fa;
  position: sticky;
  top: 0;
  z-index: 1;
}

.sector-holdings-table td {
  color: #333;
}

.sector-holdings-table tbody tr:hover {
  background-color: #f8f9fa;
}

.sector-holdings-chart-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  background: #f8f9fa;
  border-radius: 8px;
  color: #666;
  font-size: 14px;
}

.sector-holdings-chart-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 400px;
  background: #f8f9fa;
  border-radius: 8px;
}

.sector-holdings-chart-empty-icon {
  font-size: 48px;
  color: #ccc;
  margin-bottom: 16px;
}

.sector-holdings-chart-empty-text {
  font-size: 16px;
  color: #666;
  margin: 0;
}

/* 为第一行的 tooltip 添加特殊处理 */
.static-tooltip {
  position: relative;
  display: inline-block;
}

.static-tooltip .tooltip-content {
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 8px;
  z-index: 1000;
}

.static-tooltip .tooltip-content::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 6px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.85) transparent transparent transparent;
}

.tooltip-wrapper {
  position: relative;
  display: inline-flex;
  align-items: center;
}

/* 确保 tooltip 内容显示在上层 */
.tooltip-wrapper .tooltip-content {
  position: absolute;
  z-index: 10000;
  white-space: normal;
  width: max-content;
  max-width: 300px;
  text-transform: none;
  font-weight: normal;
  letter-spacing: normal;
}

/* 确保 tooltip 内容的文本样式不受父元素影响 */
.sector-holdings-metric-label .tooltip-content {
  text-transform: none;
  font-weight: normal;
  letter-spacing: normal;
}

/* 保持标签文本的原有样式 */
.sector-holdings-metric-label {
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.5px;
}

/* 调整第一行的 tooltip 位置 */
.sector-holdings-metrics-grid .sector-holdings-metric-card:nth-child(-n+3) .tooltip-content {
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 8px;
}

/* 调整第一行的 tooltip 箭头 */
.sector-holdings-metrics-grid .sector-holdings-metric-card:nth-child(-n+3) .tooltip-content::after {
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
} 