.etf-vp-holdings-chart {
  margin-top: 40px;
  padding: 24px;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.06);
}

.etf-vp-holdings-chart-title {
  font-size: 20px;
  font-weight: 600;
  color: #333;
  margin-bottom: 24px;
}

.etf-vp-holdings-content {
  display: flex;
  gap: 24px;
}

.etf-vp-holdings-chart-container {
  flex: 1;
  min-width: 0;
}

.etf-vp-holdings-table {
  flex: 1;
  min-width: 0;
  max-height: 400px;
  overflow-y: auto;
}

.etf-vp-holdings-table table {
  width: 100%;
  border-collapse: collapse;
}

.etf-vp-holdings-table th,
.etf-vp-holdings-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.etf-vp-holdings-table th {
  font-weight: 500;
  color: #666;
  background: #f8f9fa;
  position: sticky;
  top: 0;
  z-index: 1;
}

.etf-vp-holdings-table td {
  color: #333;
}

.etf-vp-holdings-table tbody tr:hover {
  background-color: #f8f9fa;
}

.etf-vp-holdings-chart-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  background: #f8f9fa;
  border-radius: 8px;
  color: #666;
  font-size: 14px;
}

.etf-vp-holdings-chart-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 400px;
  background: #f8f9fa;
  border-radius: 8px;
}

.etf-vp-holdings-chart-empty-icon {
  font-size: 48px;
  color: #ccc;
  margin-bottom: 16px;
}

.etf-vp-holdings-chart-empty-text {
  font-size: 16px;
  color: #666;
  margin: 0;
} 