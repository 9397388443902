.app-header {
  padding: 0.5rem 2rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: rgba(17, 17, 17, 0.95); /* 更深的背景色，带透明度 */
  backdrop-filter: blur(10px); /* 毛玻璃效果 */
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1400px;
  margin: 0 auto;
  height: 3rem;
}

.left-section, .right-section {
  display: flex;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 600;
  color: #ffffff;
  text-decoration: none;
  margin-right: 2.8rem;
  transition: all 0.3s ease;
  font-family: 'Clash Display', sans-serif;
  letter-spacing: -0.02em;
}

.logo i {
  margin-right: 8px;
  font-size: 22px;
  background: linear-gradient(45deg, #4a90e2, #357abd);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.logo:hover {
  color: #B388FF;
}

.nav-links {
  display: flex;
  gap: 1.2rem;
  align-items: center;
  height: 100%;
}

.nav-links a, .dropdown-trigger {
  font-size: 0.95rem;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.9);
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  letter-spacing: 0.01em;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
}

.nav-links a:hover, .dropdown-trigger:hover {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.12);
  transform: translateY(-1px);
  text-shadow: 0 0 20px rgba(255, 255, 255, 0.2);
}

/* 登录按钮样式 */
.login-btn {
  background: linear-gradient(135deg, #4a90e2 0%, #357abd 100%);
  color: white;
  padding: 0.5rem 1.2rem;
  border-radius: 6px;
  font-weight: 600;
  letter-spacing: 0.03em;
  border: none;
  transition: all 0.3s ease;
  text-decoration: none;
  font-size: 0.9rem;
  box-shadow: 0 4px 15px rgba(74, 144, 226, 0.3);
}

.login-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(74, 144, 226, 0.4);
  background: linear-gradient(135deg, #357abd 0%, #4a90e2 100%);
}

/* 下拉菜单样式优化 */
.dropdown-menu {
  background-color: rgba(25, 25, 25, 0.98);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  padding: 0.4rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  min-width: 220px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  margin-top: 0.3rem;
}

.dropdown-menu li a {
  font-size: 0.92rem;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.85);
  padding: 0.7rem 1.2rem;
  letter-spacing: 0.01em;
}

.dropdown-menu li a:hover {
  background: rgba(74, 144, 226, 0.15);
  color: #4a90e2;
  transform: translateX(4px);
}

/* 用户信息样式 */
.user-profile-link {
  background: rgba(255, 255, 255, 0.1);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  font-weight: 500;
  margin-right: 0.8rem;
  transition: all 0.3s ease;
}

.user-profile-link:hover {
  background: rgba(255, 255, 255, 0.15);
  transform: translateY(-1px);
}

.logout-btn {
  background: rgba(255, 255, 255, 0.1);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  font-weight: 500;
  transition: all 0.3s ease;
  cursor: pointer;
}

.logout-btn:hover {
  background: rgba(255, 255, 255, 0.15);
  border-color: rgba(255, 255, 255, 0.3);
  transform: translateY(-1px);
}

/* 下拉箭头动画优化 */
.dropdown-trigger .arrow {
  font-size: 9px;
  margin-left: 6px;
  transition: transform 0.3s ease;
  opacity: 0.8;
}

.dropdown:hover .dropdown-trigger .arrow {
  transform: rotate(180deg);
  opacity: 1;
}

/* 动画优化 */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.dropdown-menu {
  animation: fadeIn 0.2s ease-out;
}

.language-selector {
  position: relative;
  margin-right: 1rem;
}

.language-selector select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid #ffffff;
  border-radius: 4px;
  padding: 0.5rem 2rem 0.5rem 1rem;
  color: #ffffff;
  font-weight: 500;
  cursor: pointer;
  width: 120px; /* 增加宽度 */
}

.language-selector::after {
  content: '\25BC';
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #ffffff;
  pointer-events: none;
}

.language-selector select:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
}

/* 调整下拉菜单样式 */
.language-selector select option {
  background-color: #4a90e2;
  color: #ffffff;
  padding: 0.5rem;
}

.language-selector select,
.login-btn,
.logout-btn {
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
}

.language-selector select:hover,
.login-btn:hover,
.logout-btn:hover {
  background-color: rgba(255, 255, 255, 0.2);
  border-color: rgba(255, 255, 255, 0.5);
}

.language-select {
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ddd;
  background-color: white;
  cursor: pointer;
}

.language-select:hover {
  border-color: #4a90e2;
}

.language-select:focus {
  outline: none;
  border-color: #4a90e2;
  box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
}

/* 下拉菜单容器 */
.dropdown {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
}

/* 下拉菜单默认隐藏 */
.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 0.5rem;
  background-color: rgba(25, 25, 25, 0.98);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  padding: 0.5rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  min-width: 220px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  z-index: 1000;
}

/* 添加一个隐形的安全区域连接触发器和菜单 */
.dropdown::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 20px; /* 在触发器和菜单之间创建安全区域 */
  background: transparent;
}

/* 下拉触发器样式 */
.dropdown-trigger {
  cursor: pointer;
  padding: 0.5rem 1rem;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  align-items: center;
  height: 100%;
  transition: all 0.3s ease;
  font-size: 15px; /* 与导航栏其他项目保持一致 */
}

.dropdown-trigger:hover {
  color: #4a90e2; /* 与其他导航项目悬停颜色保持一致 */
}

/* 小箭头样式 */
.dropdown-menu::before {
  content: '';
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 10px;
  height: 10px;
  background-color: rgba(25, 25, 25, 0.98); /* 与菜单背景色保持一致 */
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

/* 显示下拉菜单的条件也包括悬停在安全区域上 */
.dropdown:hover .dropdown-menu,
.dropdown-menu:hover {
  display: block;
  animation: fadeIn 0.2s ease;
}

/* 添加淡入动画 */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(-50%) translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
}

/* 调整触发器箭头样式 */
.dropdown-trigger .arrow {
  margin-left: 6px;
  font-size: 10px;
  transition: transform 0.2s ease;
}

.dropdown:hover .dropdown-trigger .arrow {
  transform: rotate(180deg);
}

/* 下拉菜单项样式 */
.dropdown-menu li {
  list-style: none;
}

.dropdown-menu li a {
  display: block;
  padding: 0.8rem 1.2rem;
  color: rgba(255, 255, 255, 0.85);
  text-decoration: none;
  transition: all 0.2s ease;
  border-radius: 6px;
}

.dropdown-menu li a:hover {
  background: rgba(74, 144, 226, 0.15);
  color: #4a90e2;
  transform: translateX(4px);
}
