.etf-detail-container {
  padding: 32px;
  background: #fff;
}

.etf-detail-header {
  margin-bottom: 32px;
}

.etf-detail-title {
  font-size: 32px;
  color: #1a1a1a;
  font-weight: 600;
  margin: 0;
  text-align: left;
  line-height: 1.2;
}

.etf-detail-tab-navigation {
  display: flex;
  gap: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 8px;
}

.etf-detail-tab-button {
  padding: 8px 16px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 16px;
  color: #666;
  position: relative;
}

.etf-detail-tab-button:hover {
  color: #1a73e8;
}

.etf-detail-tab-active {
  color: #1a73e8;
  font-weight: 500;
}

.etf-detail-tab-active::after {
  content: '';
  position: absolute;
  bottom: -9px;
  left: 0;
  width: 100%;
  height: 2px;
  background: #1a73e8;
} 