.marketcap-bciAhistory-comparison {
  width: 100%;
  margin-top: 24px;
}

.marketcap-bciAhistory-container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 24px;
  min-height: 600px;
  background: #fff;
  border-radius: 12px;
  border: 1px solid #f0f0f0;
  padding: 24px;
}

/* 左侧表格部分 */
.marketcap-bciAhistory-table-section {
  background: #fff;
  border-radius: 8px;
  border: 1px solid #f0f0f0;
  display: flex;
  flex-direction: column;
  height: 600px;
}

.marketcap-bciAhistory-subtitle {
  color: #666;
  font-size: 14px;
  margin: 4px 0;
  padding: 0 20px;
}

.marketcap-bciAhistory-timestamp {
  color: #666;
  font-size: 14px;
  padding: 0 20px;
  margin-bottom: 12px;
}

.marketcap-bciAhistory-table-wrapper {
  flex: 1;
  overflow-y: auto;
  padding: 0 20px;
}

.marketcap-bciAhistory-table {
  width: 100%;
  border-collapse: collapse;
}

.marketcap-bciAhistory-table th,
.marketcap-bciAhistory-table td {
  padding: 12px 16px;
  text-align: left;
  border-bottom: 1px solid #f0f0f0;
}

.marketcap-bciAhistory-table th:last-child,
.marketcap-bciAhistory-table td:last-child {
  text-align: right;
  font-family: monospace;
  color: #1a73e8;
}

/* 右侧图表部分 */
.marketcap-bciAhistory-chart-wrapper {
  background: #fff;
  border-radius: 8px;
  border: 1px solid #f0f0f0;
  height: 600px;
  position: relative;
  padding: 20px;
}

.marketcap-bciAhistory-chart {
  height: calc(100% - 60px);
  margin-top: 60px;
}

/* 时间选择器样式 */
.marketcap-bciAhistory-range-selector {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  gap: 4px;
  background: #fff;
  padding: 4px;
  border-radius: 4px;
  z-index: 10;
}

.marketcap-bciAhistory-range-btn {
  padding: 6px 16px;
  border: 1px solid #e0e0e0;
  background: #fff;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  color: #666;
  min-width: 45px;
  text-align: center;
  transition: all 0.2s;
}

.marketcap-bciAhistory-range-btn:hover {
  background: #f5f5f5;
}

.marketcap-bciAhistory-range-btn.active {
  background: #1a73e8;
  color: white;
  border-color: #1a73e8;
}

/* 滚动条样式 */
.marketcap-bciAhistory-table-wrapper::-webkit-scrollbar {
  width: 6px;
}

.marketcap-bciAhistory-table-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.marketcap-bciAhistory-table-wrapper::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.marketcap-bciAhistory-table-wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media (max-width: 1024px) {
  .marketcap-bciAhistory-container {
    grid-template-columns: 1fr;
  }
}