.etfdetails-overview-container {
  padding: 24px;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.06);
}

.etfdetails-overview-metrics {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 24px;
}

.etfdetails-overview-metric-card {
  background: #f8f9fa;
  border-radius: 12px;
  padding: 20px;
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.etfdetails-overview-metric-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

.etfdetails-overview-metric-title {
  font-size: 18px;
  color: #1a1a1a;
  margin-bottom: 20px;
  font-weight: 600;
  letter-spacing: 0.2px;
}

.etfdetails-overview-metric-value {
  font-size: 18px;
  color: #1a1a1a;
  font-weight: 600;
}

.etfdetails-overview-metric-subvalue {
  font-size: 16px;
  color: #666;
  margin-left: 4px;
}

.etfdetails-overview-metric-value.positive {
  color: #34a853;
}

.etfdetails-overview-metric-value.negative {
  color: #ea4335;
}

.etfdetails-overview-introduction {
  margin-top: 32px;
  padding: 32px;
  background: #f8f9fa;
  border-radius: 12px;
  transition: all 0.3s ease;
}

.etfdetails-overview-introduction:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.etfdetails-overview-introduction-title {
  font-size: 20px;
  font-weight: 600;
  color: #333;
  margin-bottom: 20px;
}

.etfdetails-overview-introduction-text {
  font-size: 16px;
  line-height: 1.7;
  color: #444;
  font-weight: 400;
}

.etfdetails-overview-introduction-source {
  display: block;
  margin-top: 12px;
  font-size: 13px;
  color: #888;
  font-style: italic;
}

.etfdetails-overview-history-chart {
  background: #fff;
  border-radius: 12px;
  padding: 24px;
  margin-top: 24px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.08);
}

.etfdetails-overview-chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.etfdetails-overview-chart-header h3 {
  color: #1a1a1a;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}

.etfdetails-overview-time-range-selector {
  display: flex;
  gap: 8px;
  background: #f8f9fa;
  padding: 4px;
  border-radius: 8px;
}

.etfdetails-overview-range-button {
  padding: 6px 12px;
  border: none;
  background: none;
  border-radius: 6px;
  color: #666;
  cursor: pointer;
  font-size: 13px;
  transition: all 0.2s;
}

.etfdetails-overview-range-button:hover {
  background: rgba(26, 115, 232, 0.1);
  color: #1a73e8;
}

.etfdetails-overview-range-button-active {
  background: #1a73e8;
  color: white;
}

.etfdetails-overview-error {
  padding: 24px;
  text-align: center;
  color: #d32f2f;
  background: #ffebee;
  border-radius: 8px;
  margin: 24px;
}

.etfdetails-overview-no-data {
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  font-size: 16px;
  background: #f8f9fa;
  border-radius: 8px;
}

.etfdetails-overview-netinflow-chart {
  background: #fff;
  border-radius: 12px;
  padding: 24px;
  margin-top: 24px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.08);
}

.etfdetails-overview-rating {
  margin-top: 8px;
  width: 100%;
}

.etfdetails-overview-rating-bar-wrapper {
  width: 100%;
}

.etfdetails-overview-rating-bar {
  width: 100%;
  height: 4px;
  border-radius: 2px;
  position: relative;
  margin-bottom: 4px;
}

.etfdetails-overview-rating-marker {
  position: absolute;
  top: 50%;
  width: 12px;
  height: 12px;
  background: #fff;
  border: 2px solid #1a1a1a;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.etfdetails-overview-rating-labels {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  padding: 0 6px;
}

.etfdetails-overview-rating-label {
  font-size: 11px;
  color: #666;
  flex: 1;
  text-align: center;
  white-space: nowrap;
  opacity: 0.6;
}

.etfdetails-overview-rating-label.active {
  color: #333;
  font-weight: 500;
  opacity: 1;
}

.etfdetails-overview-loading {
  color: #666;
  font-size: 14px;
  margin-top: 8px;
}

.etfdetails-overview-ratings-section {
  margin-bottom: 32px;
}

.etfdetails-overview-ratings-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
}

.etfdetails-overview-metrics-section {
  margin-top: 24px;
}

.etfdetails-overview-metrics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
  margin-top: 24px;
}

.etfdetails-overview-data-card {
  background: #fff;
  border-radius: 8px;
  padding: 16px 20px;
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.etfdetails-overview-data-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.etfdetails-overview-data-label {
  font-size: 18px;
  color: #1a1a1a;
  margin-bottom: 16px;
  font-weight: 400;
  letter-spacing: 0.2px;
}

.etfdetails-overview-data-value {
  font-size: 18px;
  color: #1a1a1a;
  font-weight: 600;
  line-height: 1.4;
}

.etfdetails-overview-price-change {
  display: inline;
  font-weight: 500;
}

.etfdetails-overview-price-change.positive {
  color: #34a853;
}

.etfdetails-overview-price-change.negative {
  color: #ea4335;
}

/* 基本信息样式 */
.etfdetails-overview-basic-info {
  display: flex;
  gap: 32px;
  margin: 32px 0;
  padding: 20px;
  background: #fff;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.etfdetails-overview-basic-item {
  display: flex;
  align-items: center;
  gap: 12px;
}

.etfdetails-overview-basic-label {
  font-size: 16px;
  color: #1a1a1a;
  font-weight: 600;
}

.etfdetails-overview-basic-value {
  font-size: 16px;
  color: #666;
}

.etfdetails-overview-basic-tag {
  padding: 4px 12px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
}

/* ETF类型标签样式 */
.etfdetails-overview-basic-tag.spot {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.etfdetails-overview-basic-tag.futures {
  background-color: #e3f2fd;
  color: #1976d2;
}

.etfdetails-overview-basic-tag.equity {
  background-color: #fff3e0;
  color: #f57c00;
}

.etfdetails-overview-basic-tag.leverage {
  background-color: #fce4ec;
  color: #c2185b;
}

/* 响应式调整 */
@media (max-width: 768px) {
  .etfdetails-overview-metrics-grid {
    grid-template-columns: 1fr;
    gap: 12px;
  }
  
  .etfdetails-overview-data-label {
    font-size: 16px;
  }
  
  .etfdetails-overview-data-value {
    font-size: 16px;
  }

  .etfdetails-overview-basic-info {
    flex-direction: column;
    gap: 16px;
  }

  .etfdetails-overview-basic-item {
    justify-content: space-between;
  }
} 

.etfdetails-overview-volume-wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.etfdetails-overview-volume-wrapper div {
  font-size: 14px;
  line-height: 1.4;
} 