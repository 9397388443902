.fear-greed-meter {
  width: 380px;
  min-width: 380px;
  margin: 0;
  padding: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.meter {
  width: 100%;
  height: auto;
  margin-left: -20px;
  margin-bottom: 20px;
}

.meter-label {
  font-size: 12px;
  fill: #666;
  text-anchor: middle;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
}

.meter-value {
  font-size: 28px !important;
  fill: #333 !important;
  text-anchor: middle;
  font-weight: 600 !important;
  transform: translateY(-10px);
}

.meter-status {
  font-size: 20px;
  fill: #666;
  text-anchor: middle;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  font-weight: 400;
}

/* 调整容器布局 */
.btc-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 32px;
  margin-bottom: 20px;
  padding: 24px;
}

.fear-greed-index {
  width: 100%;
  text-align: center;
  padding: 4px 0;
  margin-top: 0;
  font-size: 18px;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.fear-greed-value {
  color: #FB8C00;
  font-size: 24px;
  font-weight: 600;
}

/* 调整工具提示图标在Fear & Greed Index中的位置 */
.fear-greed-index .tooltip-icon {
  margin-left: 4px;
  width: 16px;
  height: 16px;
  font-size: 12px;
}

.fear-greed-meters-container {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: flex-start;
  margin: 20px 0;
}

.meter-title {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #333;
  margin-bottom: 10px;
}

.fear-greed-meter {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}
