.stock-bci-balance-container {
  background: #fff;
  border-radius: 12px;
  padding: 24px;
  margin-top: 24px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.stock-bci-balance-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.stock-bci-balance-title {
  font-size: 20px;
  color: #1a73e8;
  margin: 0;
  font-weight: 500;
}

.stock-bci-balance-range-selector {
  display: flex;
  gap: 8px;
  background: #f8f9fa;
  padding: 4px;
  border-radius: 8px;
}

.stock-bci-balance-range-button {
  padding: 6px 12px;
  border: none;
  background: none;
  border-radius: 6px;
  color: #666;
  cursor: pointer;
  font-size: 13px;
  transition: all 0.2s;
}

.stock-bci-balance-range-button:hover {
  background: rgba(26, 115, 232, 0.1);
  color: #1a73e8;
}

.stock-bci-balance-range-button.active {
  background: #1a73e8;
  color: white;
}

.stock-bci-balance-chart {
  height: 400px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stock-bci-balance-chart .no-data-message {
  color: #666;
  font-size: 14px;
  text-align: center;
} 