.user-profile-page {
  max-width: 1200px;
  margin: 5rem auto;
  padding: 0 2.5rem;
}

.profile-grid {
  display: grid;
  grid-template-columns: 380px 1fr;
  gap: 2.5rem;
  min-height: 420px;
}

/* 统一卡片基础样式 */
.profile-card {
  background: linear-gradient(145deg, #ffffff 0%, #f8f9fa 100%);
  border-radius: 24px;
  padding: 3rem;
  position: relative;
  box-shadow: 0 12px 36px rgba(0, 0, 0, 0.06);
  overflow: hidden;
  transition: all 0.3s ease;
  height: 100%;
}

.profile-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  background: linear-gradient(90deg, #2196f3, #64b5f6);
}

.profile-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 18px 45px rgba(0, 0, 0, 0.08);
}

/* 用户信息卡片 */
.user-info-card {
  composes: profile-card;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 3rem 2rem;
}

.user-avatar-wrapper {
  margin-bottom: 2rem;
  position: relative;
  padding: 0.5rem;
  border-radius: 50%;
  background: linear-gradient(135deg, rgba(33, 150, 243, 0.1), rgba(100, 181, 246, 0.1));
}

.user-avatar {
  width: 150px;
  height: 150px;
  background: linear-gradient(135deg, #e3f2fd 0%, #bbdefb 100%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 64px;
  color: #1976d2;
  border: 4px solid rgba(25, 118, 210, 0.15);
  position: relative;
  overflow: hidden;
}

.user-name {
  font-size: 2rem;
  color: #1a237e;
  margin: 1.5rem 0 1rem;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.user-email {
  font-size: 1.1rem;
  color: #546e7a;
  opacity: 0.9;
}

/* 订阅信息卡片 */
.subscription-card {
  composes: profile-card;
  display: flex;
  flex-direction: column;
}

.subscription-header {
  margin-bottom: 3rem;
}

.profile-section-title {
  font-size: 1.75rem;
  color: #1a237e;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.profile-section-title::before {
  content: '';
  display: block;
  width: 5px;
  height: 28px;
  background: linear-gradient(to bottom, #1976d2, #2196f3);
  border-radius: 3px;
}

.status-badge {
  display: inline-block;
  padding: 0.6rem 1.5rem;
  border-radius: 24px;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 2rem;
}

.status-badge.active {
  background: linear-gradient(135deg, #e8f5e9 0%, #c8e6c9 100%);
  color: #2e7d32;
  box-shadow: 0 3px 12px rgba(46, 125, 50, 0.15);
}

.info-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.info-row:last-child {
  border-bottom: none;
}

.info-label {
  color: #546e7a;
  font-size: 1.1rem;
}

.info-value {
  color: #263238;
  font-size: 1.1rem;
  font-weight: 500;
}

.info-value.highlight {
  color: #1976d2;
  background: linear-gradient(120deg, #e3f2fd 0%, #bbdefb 100%);
  padding: 0.6rem 1.2rem;
  border-radius: 20px;
  box-shadow: 0 2px 8px rgba(25, 118, 210, 0.12);
}

/* 添加装饰元素 */
.card-decorator {
  position: absolute;
  width: 200px;
  height: 200px;
  background: linear-gradient(135deg, rgba(33, 150, 243, 0.05), rgba(100, 181, 246, 0.05));
  border-radius: 50%;
  top: -100px;
  right: -100px;
  z-index: 0;
}

@keyframes shine {
  0% {
    transform: translateX(-100%) rotate(45deg);
  }
  100% {
    transform: translateX(100%) rotate(45deg);
  }
}

/* 响应式设计 */
@media (max-width: 768px) {
  .profile-grid {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  
  .user-avatar {
    width: 120px;
    height: 120px;
    font-size: 48px;
  }
  
  .profile-section-title {
    font-size: 1.5rem;
  }
}