.qa-section {
  padding: 24px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  margin: 20px 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.qa-section::-webkit-scrollbar {
  width: 8px;
}

.qa-section::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.qa-section::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 4px;
}

.qa-section::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

.qa-section h2 {
  font-size: 36px;
  color: #1a1a1a;
  font-weight: 400;
  margin: -24px 0 20px 0;
  line-height: 1;
  display: flex;
  align-items: flex-end;
  padding-left: 0;
  border-bottom: none;
  position: sticky;
  top: 0;
  background: #fff;
  padding: 24px 0;
  z-index: 1;
}

.qa-list {
  flex: 1;
  overflow-y: auto;
  padding-right: 16px;
}

.qa-item {
  margin-bottom: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
}

.qa-item:hover {
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.qa-question {
  padding: 20px 24px;
  background: #f8f9fa;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: #1a1a1a;
  transition: all 0.3s ease;
}

.qa-question:hover {
  background: #f0f7ff;
}

.qa-question.expanded {
  background: #f0f7ff;
  border-bottom: 1px solid #e0e0e0;
}

.qa-toggle {
  font-size: 24px;
  color: #1a73e8;
  font-weight: 300;
}

.qa-answer {
  padding: 24px;
  line-height: 1.6;
  color: #333;
  background: #fff;
  font-size: 15px;
  height: auto;
  overflow: visible;
}

/* 强调文本样式 */
.qa-answer strong {
  color: #1a73e8;
  font-weight: 500;
}

.qa-question strong {
  color: #1a73e8;
  font-weight: 500;
} 