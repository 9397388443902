.etf-nav-premium-chart {
  margin-top: 40px;
  padding: 24px;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.06);
}

.etf-nav-premium-chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.etf-nav-premium-chart-time-range {
  display: flex;
  gap: 8px;
  background: #f5f5f5;
  padding: 4px;
  border-radius: 8px;
}

.etf-nav-premium-chart-range-btn {
  padding: 6px 12px;
  border: none;
  background: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  color: #666;
  transition: all 0.2s;
}

.etf-nav-premium-chart-range-btn:hover {
  background: rgba(26, 115, 232, 0.1);
  color: #1a73e8;
}

.etf-nav-premium-chart-range-btn.active {
  background: #fff;
  color: #1a73e8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.etf-nav-premium-chart-loading {
  text-align: center;
  padding: 40px;
  color: #666;
  font-size: 14px;
}

.etf-nav-premium-chart-title {
  font-size: 20px;
  font-weight: 600;
  color: #1a1a1a;
}

.etf-nav-premium-chart-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 400px;
  background: #f8f9fa;
  border-radius: 8px;
}

.etf-nav-premium-chart-empty-icon {
  font-size: 48px;
  color: #ccc;
  margin-bottom: 16px;
}

.etf-nav-premium-chart-empty-text {
  font-size: 16px;
  color: #666;
  margin: 0;
}

.etf-nav-premium-chart-range-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
} 