.top-etfs-section {
  background: #fff;
  border-radius: 16px;
  padding: 24px;
  margin: 24px 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}

.top-etfs-header {
  margin-bottom: 24px;
}

.top-etfs-header h2 {
  font-size: 32px;
  font-weight: 500;
  color: #1a1a1a;
  margin-bottom: 16px;
}

.top-etfs-description {
  color: #333;
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
}

/* Explore button styles */
.top-etfs-explore-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px 32px;
  background: #1a73e8;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.2s;
  margin-top: 24px;
}

.top-etfs-explore-button:hover {
  background: #1557b0;
}

.top-etfs-explore-title {
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 8px;
  color: white;
}

.top-etfs-explore-description {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.9);
  text-align: center;
}

.highlight {
  color: #1976d2;
  font-weight: 500;
} 