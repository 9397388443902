.longterm-recommendation-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.longterm-recommendation-section {
  background: #fff;
  border-radius: 24px;
  padding: 32px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.06);
}

.longterm-recommendation-section-title {
  color: #1a1a1a;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid #eef0f2;
}

.longterm-recommendation-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 48px;
}

.longterm-recommendation-meter-container {
  flex: 0 0 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.06);
}

.longterm-recommendation-meter-container:hover {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  transform: translateY(-2px);
}

.longterm-recommendation-meter {
  width: 380px;
}

.longterm-recommendation-meter-label {
  font-size: 28px;
  font-weight: 600;
  color: #1a1a1a;
  margin-top: 16px;
  text-align: center;
}

.longterm-recommendation-ratings {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  background: #f8f9fa;
  border-radius: 16px;
  min-width: 420px;
}

.longterm-recommendation-rating-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  padding: 12px 16px;
  background: #fff;
  border-radius: 12px;
  transition: all 0.3s ease;
}

.longterm-recommendation-rating-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.06);
}

.longterm-recommendation-rating-label-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.longterm-recommendation-rating-label {
  color: #666;
  font-size: 14px;
  font-weight: 500;
}

.longterm-recommendation-rating-container {
  display: flex;
  align-items: center;
  gap: 16px;
}

.longterm-recommendation-rating-track {
  width: 200px;
  height: 8px;
  background: #eef0f2;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}

.longterm-recommendation-rating-progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: linear-gradient(to right, #ef5350 0%, #ff9800 50%, #4caf50 100%);
  border-radius: 4px;
  transition: width 0.3s ease;
}

.longterm-recommendation-rating-value {
  font-size: 20px;
  font-weight: 600;
  color: #1a1a1a;
  min-width: 54px;
  text-align: right;
}

.longterm-recommendation-indicators-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.longterm-recommendation-indicator-card {
  background: #fff;
  padding: 24px;
  border-radius: 16px;
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.longterm-recommendation-indicator-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
}

.longterm-recommendation-indicator-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.longterm-recommendation-indicator-label {
  color: #666;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.longterm-recommendation-indicator-value {
  color: #1a1a1a;
  font-size: 24px;
  font-weight: 600;
  font-variant-numeric: tabular-nums;
}

.longterm-recommendation-indicator-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.longterm-recommendation-indicator-status {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #9e9e9e;
}

.status-high .longterm-recommendation-indicator-status {
  background-color: #f44336;
}

.status-low .longterm-recommendation-indicator-status {
  background-color: #4caf50;
}

.status-positive .longterm-recommendation-indicator-status {
  background-color: #4caf50;
}

.status-negative .longterm-recommendation-indicator-status {
  background-color: #f44336;
}

.longterm-recommendation-indicator-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 3px;
  background: linear-gradient(to right, #4caf50, #fdd835, #f44336);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.longterm-recommendation-indicator-card:hover::before {
  opacity: 1;
}

.longterm-recommendation-rating-grade {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 600;
  font-size: 12px;
}

@media (max-width: 1200px) {
  .longterm-recommendation-header {
    flex-direction: column;
    gap: 32px;
  }
  
  .longterm-recommendation-meter-container,
  .longterm-recommendation-ratings {
    width: 100%;
    min-width: unset;
  }
  
  .longterm-recommendation-indicators-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .longterm-recommendation-section {
    padding: 20px;
  }
  
  .longterm-recommendation-indicators-grid {
    grid-template-columns: 1fr;
  }
  
  .longterm-recommendation-meter {
    width: 100%;
  }
} 