/* 共享样式 */
body {
  font-family: 'Poppins', sans-serif;
  background-color: #ffffff;
  color: #333333;
}

.page-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px 20px;
}

.hero-section {
  background-color: #f8f9fa;
  padding: 60px 0;
  text-align: center;
  margin-bottom: 40px;
}

.hero-title {
  font-size: 2.5em;
  color: #333333;
  margin-bottom: 20px;
}

.hero-subtitle {
  font-size: 1.2em;
  color: #666666;
  margin-bottom: 30px;
}

.content-section {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 30px;
  margin-bottom: 30px;
}

.section-title {
  font-size: 1.8em;
  color: #333333;
  margin-bottom: 20px;
}

.button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #4a90e2;
  color: #ffffff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #3a7bd5;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .hero-title {
    font-size: 2em;
  }

  .hero-subtitle {
    font-size: 1em;
  }

  .section-title {
    font-size: 1.5em;
  }
}
