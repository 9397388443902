.longterm-overview {
  padding: 24px;
  max-width: 1600px;
  margin: 0 auto;
  width: 100%;
}

.longterm-overview-header {
  margin-bottom: 32px;
}

.longterm-overview-header h1 {
  font-size: 28px;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 12px;
}

.longterm-overview-description {
  font-size: 16px;
  color: #666;
  line-height: 1.5;
}

.longterm-overview-section {
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  padding: 28px;
  margin-bottom: 36px;
  transition: all 0.3s ease;
}

.longterm-section-title {
  font-size: 22px;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 24px;
  padding-bottom: 14px;
  border-bottom: 1px solid #f0f0f0;
}

/* Top Stocks Grid */
.longterm-top-stocks-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 20px;
}

.longterm-stock-card {
  background: #f8f9fa;
  border-radius: 8px;
  padding: 16px;
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.longterm-stock-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

.longterm-stock-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.longterm-stock-ticker {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

.longterm-recommendation-badge {
  font-size: 12px;
  font-weight: 600;
  padding: 4px 8px;
  border-radius: 4px;
  color: white;
}

.grade-a {
  background-color: #4CAF50;
}

.grade-b {
  background-color: #8BC34A;
}

.grade-c {
  background-color: #FFC107;
}

.grade-d {
  background-color: #FF9800;
}

.grade-f {
  background-color: #F44336;
}

.longterm-stock-details {
  font-size: 14px;
}

.longterm-stock-name {
  font-weight: 500;
  margin-bottom: 4px;
}

.longterm-stock-sector {
  color: #666;
  margin-bottom: 8px;
}

.longterm-stock-price {
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;
}

.longterm-stock-change {
  font-size: 12px;
  padding: 2px 6px;
  border-radius: 4px;
}

.positive {
  color: #4CAF50;
}

.negative {
  color: #F44336;
}

/* Sector Table */
.longtermoverview-sector-table-container {
  overflow-x: auto;
}

.longtermoverview-sector-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.longtermoverview-sector-table th,
.longtermoverview-sector-table td {
  padding: 12px 16px;
  text-align: left;
  border-bottom: 1px solid #f0f0f0;
}

/* 修复Return列对齐问题 */
.longtermoverview-sector-table th:last-child,
.longtermoverview-sector-table td:last-child {
  text-align: right;
  padding-right: 24px;
}

.longtermoverview-sector-table th {
  font-weight: 600;
  color: #333;
  background-color: #f8f9fa;
}

.longtermoverview-sector-table tr:hover {
  background-color: #f8f9fa;
}

.longtermoverview-sector-symbol {
  font-weight: 600;
  color: #333;
}

.longtermoverview-sector-return {
  font-weight: 600;
  text-align: right; /* 确保所有return值右对齐 */
}

.longtermoverview-sector-return.positive {
  color: #4CAF50;
}

.longtermoverview-sector-return.negative {
  color: #F44336;
}

/* Market Insights */
.longterm-insights-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 24px;
}

.longterm-insight-card {
  background: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.longterm-insight-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

.longterm-insight-icon {
  font-size: 28px;
  margin-bottom: 16px;
}

.longterm-insight-card h3 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 12px;
}

.longterm-insight-card p {
  color: #666;
  line-height: 1.5;
}

/* Loading and Error States */
.longterm-overview-loading,
.longterm-overview-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px;
  text-align: center;
}

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #1a73e8;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 16px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error-icon {
  font-size: 32px;
  margin-bottom: 16px;
}

.longterm-no-data {
  color: #666;
  text-align: center;
  padding: 24px;
  font-style: italic;
}

/* Responsive Design */
@media (max-width: 768px) {
  .longterm-top-stocks-grid {
    grid-template-columns: 1fr;
  }
  
  .longterm-insights-container {
    grid-template-columns: 1fr;
  }
}

/* Market Snapshot Grid - Improved Layout */
.longtermoverview-market-snapshot-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1.5fr;
  gap: 20px;
  width: 100%;
  margin-bottom: 32px;
}

.longtermoverview-market-index-card {
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  cursor: pointer;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

/* Style for index cards */
.longtermoverview-market-index-card.index-card {
  grid-column: span 1;
}

/* Style for fear & greed card */
.longtermoverview-market-index-card.fear-greed-card {
  grid-column: span 1.5;
}

.longtermoverview-market-index-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
}

.longtermoverview-market-index-name {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin-bottom: 12px;
  text-align: center;
}

.longtermoverview-market-index-value {
  font-size: 28px;
  font-weight: 700;
  color: #1a1a1a;
  margin-bottom: 12px;
  text-align: center;
}

.longtermoverview-market-index-change {
  font-size: 16px;
  font-weight: 500;
  padding: 6px 12px;
  border-radius: 20px;
  text-align: center;
}

.longtermoverview-market-index-change.positive {
  color: #2E7D32;
  background-color: rgba(46, 125, 50, 0.1);
}

.longtermoverview-market-index-change.negative {
  color: #D32F2F;
  background-color: rgba(211, 47, 47, 0.1);
}

/* Fear & Greed Meter Container - Enhanced */
.longtermoverview-fear-greed-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.longtermoverview-fear-greed-card {
  grid-column: span 1;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 240px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
}

/* Override some styles from the original FearGreedMeter component */
.longtermoverview-fear-greed-card .fear-greed-meter {
  transform: scale(1);
  max-width: 100% !important;
  height: auto !important;
  overflow: hidden;
}

.longtermoverview-fear-greed-card .meter-title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 4px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: #555;
}

.longtermoverview-fear-greed-card .fear-greed-index {
  font-size: 14px;
  margin-top: -2px;
  font-weight: 600;
}

.longtermoverview-fear-greed-card .fear-greed-value {
  font-weight: 700;
  font-size: 16px;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .longtermoverview-market-snapshot-grid {
    grid-template-columns: 1fr 1fr 1fr 1.5fr;
    gap: 16px;
  }
}

@media (max-width: 992px) {
  .longtermoverview-market-snapshot-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .longtermoverview-market-index-card.fear-greed-card {
    grid-column: span 2;
  }
  
  .longtermoverview-fear-greed-card .fear-greed-meter {
    transform: scale(0.95);
  }
}

@media (max-width: 768px) {
  .longterm-overview-section {
    padding: 20px;
  }
  
  .longtermoverview-market-index-name {
    font-size: 16px;
  }
  
  .longtermoverview-market-index-value {
    font-size: 22px;
  }
  
  .longtermoverview-market-index-change {
    font-size: 14px;
    padding: 4px 10px;
  }
  
  .longtermoverview-fear-greed-card .fear-greed-meter {
    transform: scale(0.9);
  }
}

@media (max-width: 480px) {
  .longtermoverview-market-snapshot-grid {
    grid-template-columns: 1fr;
  }
  
  .longtermoverview-market-index-card.index-card,
  .longtermoverview-market-index-card.fear-greed-card {
    grid-column: span 1;
  }
  
  .longtermoverview-market-index-card {
    padding: 16px;
  }
}

/* Enhanced Market Insight Section */
.longtermoverview-market-insight-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  margin-bottom: 32px;
}

.longtermoverview-market-insight-card {
  background: linear-gradient(to bottom, #ffffff, #f8f9fa);
  border-radius: 16px;
  padding: 28px;
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  position: relative;
  overflow: hidden;
}

.longtermoverview-market-insight-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
}

.longtermoverview-market-insight-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(to right, #4285f4, #34a853, #fbbc05, #ea4335);
}

.longtermoverview-market-insight-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
}

.longtermoverview-market-insight-cycle {
  font-size: 22px;
  font-weight: 700;
  color: #333;
  margin: 0 0 16px 0;
  text-transform: uppercase;
  letter-spacing: 0.8px;
}

.longtermoverview-market-insight-stage {
  font-size: 18px;
  font-weight: 500;
  color: #555;
  margin-bottom: 24px;
  padding: 0 12px;
  text-align: center;
}

/* Fancy rank indicator */
.longtermoverview-market-insight-rank {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 800;
  color: white;
  position: relative;
  margin: 0 auto;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border: 3px solid white;
}

.longtermoverview-market-insight-rank::after {
  content: '';
  position: absolute;
  top: -3px;
  left: -3px;
  right: -3px;
  bottom: -3px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.5);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Enhanced rank colors with gradients */
.longtermoverview-rank-a {
  background: linear-gradient(135deg, #57BB8A, #4CAF50);
}

.longtermoverview-rank-b {
  background: linear-gradient(135deg, #A8D58D, #8BC34A);
}

.longtermoverview-rank-c {
  background: linear-gradient(135deg, #FFD54F, #FFC107);
}

.longtermoverview-rank-d {
  background: linear-gradient(135deg, #FFA726, #FF9800);
}

.longtermoverview-rank-e {
  background: linear-gradient(135deg, #EF5350, #F44336);
}

/* Market Analysis Text */
.longtermoverview-market-analysis {
  margin-top: 32px;
  background: #f8f9fa;
  border-radius: 16px;
  padding: 28px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.longtermoverview-market-analysis ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.longtermoverview-analysis-item {
  position: relative;
  padding-left: 28px;
  margin-bottom: 24px;
  font-size: 17px;
  line-height: 1.8;
  color: #333;
}

.longtermoverview-analysis-item:last-child {
  margin-bottom: 0;
}

.longtermoverview-analysis-item:before {
  content: "•";
  position: absolute;
  left: 0;
  color: #1a73e8;
  font-weight: bold;
  font-size: 20px;
}

.longtermoverview-analysis-item strong {
  font-weight: 700;
  color: #1a1a1a;
  font-size: 19px;
  margin-right: 8px;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .longtermoverview-market-insight-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .longtermoverview-market-insight-grid {
    grid-template-columns: 1fr;
  }
  
  .longtermoverview-market-insight-cycle {
    font-size: 20px;
  }
  
  .longtermoverview-market-insight-stage {
    font-size: 16px;
  }
  
  .longtermoverview-analysis-item {
    font-size: 16px;
    line-height: 1.7;
  }
  
  .longtermoverview-analysis-item strong {
    font-size: 18px;
  }
}

/* Sector Spotlight Section */
.longtermoverview-timeframe-selector {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  background: #f8f9fa;
  border-radius: 12px;
  padding: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
}

.longtermoverview-timeframe-label {
  font-size: 16px;
  font-weight: 500;
  color: #555;
  margin-right: 16px;
}

.longtermoverview-timeframe-buttons {
  display: flex;
  gap: 8px;
}

.longtermoverview-timeframe-button {
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  color: #555;
  cursor: pointer;
  transition: all 0.2s ease;
}

.longtermoverview-timeframe-button:hover {
  background: #f0f0f0;
}

.longtermoverview-timeframe-button.active {
  background: #1a73e8;
  color: white;
  border-color: #1a73e8;
}

.longtermoverview-sector-table-container {
  margin-bottom: 32px;
}

.longtermoverview-sector-table-title {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin-bottom: 16px;
}

.longtermoverview-sector-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background: #fff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
}

.longtermoverview-sector-table th {
  background: #f8f9fa;
  padding: 16px;
  text-align: left;
  font-size: 15px;
  font-weight: 600;
  color: #555;
  border-bottom: 1px solid #e0e0e0;
}

.longtermoverview-sector-table td {
  padding: 16px;
  font-size: 15px;
  border-bottom: 1px solid #f0f0f0;
}

.longtermoverview-sector-table tr:last-child td {
  border-bottom: none;
}

.longtermoverview-sector-symbol {
  font-weight: 600;
  color: #333;
}

.longtermoverview-sector-return {
  font-weight: 600;
  text-align: right;
}

.longtermoverview-sector-return.positive {
  color: #2E7D32;
}

.longtermoverview-sector-return.negative {
  color: #D32F2F;
}

.longtermoverview-no-data {
  text-align: center;
  color: #888;
  font-style: italic;
  padding: 24px;
}

.longtermoverview-sector-analysis {
  background: #f8f9fa;
  border-radius: 16px;
  padding: 24px;
  margin-top: 32px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid rgba(0, 0, 0, 0.05);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .longtermoverview-timeframe-selector {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .longtermoverview-timeframe-label {
    margin-bottom: 12px;
  }
  
  .longtermoverview-timeframe-buttons {
    width: 100%;
    justify-content: space-between;
  }
  
  .longtermoverview-sector-table th,
  .longtermoverview-sector-table td {
    padding: 12px;
    font-size: 14px;
  }
}

/* Sector Spotlight Two-Column Layout */
.longtermoverview-sector-spotlight-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  margin-top: 24px;
}

.longtermoverview-sector-tables {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.longtermoverview-sector-table-container {
  background: white;
  border-radius: 16px;
  padding: 24px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

.longtermoverview-sector-table-title {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin-bottom: 20px;
  padding-bottom: 12px;
  border-bottom: 1px solid #f0f0f0;
}

.longtermoverview-sector-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.longtermoverview-sector-table th {
  text-align: left;
  padding: 12px 16px;
  font-size: 14px;
  font-weight: 600;
  color: #666;
  background-color: #f8f9fa;
  border-bottom: 1px solid #e0e0e0;
}

.longtermoverview-sector-table td {
  padding: 16px;
  font-size: 15px;
  border-bottom: 1px solid #f0f0f0;
}

.longtermoverview-sector-row {
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.longtermoverview-sector-row:hover {
  background-color: rgba(0, 0, 0, 0.02);
}

.longtermoverview-sector-symbol {
  font-weight: 700;
  color: #1a73e8;
}

.longtermoverview-sector-name {
  color: #555;
}

.longtermoverview-sector-return {
  font-weight: 600;
  text-align: right;
}

.longtermoverview-sector-return.positive {
  color: #2E7D32;
}

.longtermoverview-sector-return.negative {
  color: #D32F2F;
}

.longtermoverview-sector-analysis-container {
  background: white;
  border-radius: 16px;
  padding: 28px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.longtermoverview-sector-categories {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.longtermoverview-sector-category {
  padding: 20px;
  border-radius: 12px;
  background: #f8f9fa;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
}

.longtermoverview-sector-category.potential {
  border-left: 4px solid #34a853;
}

.longtermoverview-sector-category.risk {
  border-left: 4px solid #ea4335;
}

.longtermoverview-category-title {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin-bottom: 16px;
}

.longtermoverview-sector-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.longtermoverview-sector-tag {
  padding: 10px 18px;
  border-radius: 24px;
  font-size: 15px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.longtermoverview-sector-tag:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.longtermoverview-sector-tag.potential {
  background-color: rgba(52, 168, 83, 0.12);
  color: #34a853;
}

.longtermoverview-sector-tag.risk {
  background-color: rgba(234, 67, 53, 0.12);
  color: #ea4335;
}

.longtermoverview-sector-analysis {
  background: #f8f9fa;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
}

.longtermoverview-sector-analysis ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.longtermoverview-analysis-item {
  position: relative;
  padding-left: 24px;
  margin-bottom: 16px;
  font-size: 15px;
  line-height: 1.6;
  color: #444;
}

.longtermoverview-analysis-item:last-child {
  margin-bottom: 0;
}

.longtermoverview-analysis-item::before {
  content: "•";
  position: absolute;
  left: 0;
  color: #1a73e8;
  font-size: 20px;
  line-height: 1;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .longtermoverview-sector-spotlight-grid {
    grid-template-columns: 1fr;
  }
  
  .longtermoverview-sector-tables {
    order: 1;
  }
  
  .longtermoverview-sector-analysis-container {
    order: 2;
  }
}

/* Earnings Radar Section */
.longterm-earnings-radar {
  margin-top: 32px;
  margin-bottom: 48px;
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  padding: 28px;
  border: 1px solid rgba(0, 0, 0, 0.08);
}

.earnings-radar-title {
  font-size: 22px;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 24px;
  padding-bottom: 14px;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.earnings-radar-view-all {
  font-size: 14px;
  color: #1a73e8;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 4px;
  transition: color 0.2s ease;
}

.earnings-radar-view-all:hover {
  color: #174ea6;
  text-decoration: underline;
}

.earnings-radar-table-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  background: white;
  border-radius: 12px;
}

.earnings-radar-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.earnings-radar-table th {
  background: linear-gradient(145deg, #f8f9fa 0%, #ffffff 100%);
  font-weight: 600;
  color: #1a1a1a;
  padding: 16px;
  text-align: left;
  white-space: nowrap;
  border-bottom: 2px solid #e0e0e0;
}

.earnings-radar-table td {
  padding: 12px 16px;
  border-bottom: 1px solid #f0f0f0;
  white-space: nowrap;
  text-align: left;
}

.earnings-radar-table tr:hover {
  background: linear-gradient(90deg, rgba(248, 249, 250, 0.5) 0%, rgba(255, 255, 255, 0.8) 100%);
  transition: all 0.3s ease;
}

.earnings-radar-date-header td {
  background: #f1f3f4;
  font-weight: 600;
  padding: 16px 24px !important;
  font-size: 16px;
  border-top: 1px solid #e8eaed;
  letter-spacing: 0.3px;
}

.earnings-radar-ticker-link {
  color: #1a73e8;
  cursor: pointer;
  font-weight: 500;
  transition: color 0.2s ease;
}

.earnings-radar-ticker-link:hover {
  color: #174ea6;
  text-decoration: underline;
}

.earnings-radar-loading,
.earnings-radar-error,
.earnings-radar-no-data {
  padding: 40px;
  text-align: center;
  font-size: 16px;
  color: #5f6368;
  background: white;
  border-radius: 8px;
}

.earnings-radar-error {
  color: #dc3545;
}

/* 响应式设计 */
@media screen and (max-width: 768px) {
  .longterm-earnings-radar {
    padding: 16px;
  }
  
  .earnings-radar-title {
    font-size: 20px;
    margin-bottom: 16px;
  }
  
  .earnings-radar-table td,
  .earnings-radar-table th {
    padding: 12px;
  }
}

/* Strategy Edge Section */
.longterm-strategy-edge {
  margin-top: 32px;
  margin-bottom: 48px;
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  padding: 28px;
  border: 1px solid rgba(0, 0, 0, 0.08);
}

.strategy-edge-title {
  font-size: 22px;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 24px;
  padding-bottom: 14px;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.strategy-edge-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}

.strategy-edge-chart-container {
  position: relative;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  padding: 20px;
  display: flex;
  flex-direction: column;
  min-height: 350px;
  overflow: hidden;
}

.strategy-edge-chart-range-selector {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  gap: 4px;
  background: #fff;
  padding: 4px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.strategy-edge-chart-range-btn {
  padding: 4px 12px;
  border: 1px solid #e0e0e0;
  background: white;
  border-radius: 4px;
  cursor: pointer;
  font-size: 13px;
  color: #666;
  transition: all 0.2s ease;
}

.strategy-edge-chart-range-btn:hover {
  background: #f5f5f5;
}

.strategy-edge-chart-range-btn.active {
  background: #1a73e8;
  color: white;
  border-color: #1a73e8;
}

.strategy-edge-chart-loading,
.strategy-edge-chart-error {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #5f6368;
  font-size: 16px;
  text-align: center;
}

.strategy-edge-chart-error {
  color: #dc3545;
}

.strategy-edge-data {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.strategy-edge-returns {
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  overflow: hidden;
}

.strategy-edge-returns-title {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  padding: 16px 20px;
  background: #f8f9fa;
  border-bottom: 1px solid #f0f0f0;
}

.strategy-edge-returns-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.strategy-edge-returns-table th,
.strategy-edge-returns-table td {
  padding: 12px 20px;
  text-align: left;
  border-bottom: 1px solid #f0f0f0;
}

.strategy-edge-returns-table th:last-child,
.strategy-edge-returns-table td:last-child {
  text-align: right;
}

.strategy-edge-returns-table th {
  font-weight: 600;
  color: #5f6368;
  font-size: 14px;
}

.strategy-edge-returns-value {
  font-weight: 600;
  color: #1a1a1a;
}

.strategy-edge-returns-value.positive {
  color: #34a853;
}

.strategy-edge-reviews {
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  overflow: hidden;
}

.strategy-edge-reviews-title {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  padding: 16px 20px;
  background: #f8f9fa;
  border-bottom: 1px solid #f0f0f0;
}

.strategy-edge-review-item {
  padding: 16px 20px;
  border-bottom: 1px solid #f0f0f0;
}

.strategy-edge-review-item:last-child {
  border-bottom: none;
}

.strategy-edge-review-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.strategy-edge-review-strategy {
  font-weight: 600;
  color: #1a1a1a;
  font-size: 16px;
}

.strategy-edge-review-rating {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  font-weight: 600;
  font-size: 14px;
}

.strategy-edge-review-rating.A {
  background-color: rgba(52, 168, 83, 0.12);
  color: #34a853;
}

.strategy-edge-review-rating.B {
  background-color: rgba(26, 115, 232, 0.12);
  color: #1a73e8;
}

.strategy-edge-review-rating.C {
  background-color: rgba(251, 188, 5, 0.12);
  color: #fbbc05;
}

.strategy-edge-review-rating.D {
  background-color: rgba(234, 67, 53, 0.12);
  color: #ea4335;
}

.strategy-edge-review-text {
  color: #5f6368;
  font-size: 14px;
  line-height: 1.5;
}

/* Responsive adjustments */
@media (max-width: 992px) {
  .strategy-edge-content {
    grid-template-columns: 1fr;
  }
  
  .strategy-edge-chart-container {
    order: 1;
  }
  
  .strategy-edge-data {
    order: 2;
  }
}

@media (max-width: 768px) {
  .longterm-strategy-edge {
    padding: 20px;
  }
  
  .strategy-edge-title {
    font-size: 20px;
  }
}

/* 为 Strategy Edge 部分创建自定义信息窗口样式 */
.longtermoverview-strategy-edge-info-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #f0f0f0;
  color: #666;
  font-size: 12px;
  text-align: center;
  line-height: 16px;
  cursor: help;
  margin-left: 6px;
  border: 1px solid #ddd;
  position: relative;
}

.longtermoverview-strategy-edge-info-icon:after {
  content: "?";
}

.longtermoverview-strategy-edge-info-popup {
  display: none;
  position: absolute;
  background: rgba(0, 0, 0, 0.85);
  color: #fff;
  padding: 12px 16px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  font-size: 14px;
  line-height: 1.6;
  width: 280px;
  z-index: 2000;
  white-space: normal;
  word-wrap: break-word;
}

.longtermoverview-strategy-edge-info-icon:hover + .longtermoverview-strategy-edge-info-popup {
  display: block;
}

/* Market Snapshot 部分的自定义信息图标和弹窗 */
.longtermoverview-market-index-info-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #f0f0f0;
  color: #666;
  font-size: 12px;
  text-align: center;
  line-height: 16px;
  cursor: help;
  margin-left: 6px;
  border: 1px solid #ddd;
  position: relative;
}

.longtermoverview-market-index-info-icon:after {
  content: "?";
}

.longtermoverview-market-index-info-popup {
  display: none;
  position: absolute;
  background: rgba(0, 0, 0, 0.85);
  color: #fff;
  padding: 12px 16px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  font-size: 14px;
  line-height: 1.6;
  width: 280px;
  z-index: 2000;
  white-space: normal;
  word-wrap: break-word;
  left: 0;
  top: 30px;
}

.longtermoverview-market-index-info-icon:hover + .longtermoverview-market-index-info-popup {
  display: block;
}

/* 确保 Market Snapshot 卡片有正确的定位 */
.longtermoverview-market-index-card {
  position: relative;
}

.longtermoverview-market-index-name {
  position: relative;
}

/* 添加箭头 */
.longtermoverview-market-index-info-popup:after {
  content: '';
  position: absolute;
  border-width: 8px;
  border-style: solid;
  top: -16px;
  left: 10px;
  border-color: transparent transparent rgba(0, 0, 0, 0.85) transparent;
}

/* 市场指数卡片的增强样式 - 保持原有高度和样式 */
.longtermoverview-market-index-card {
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  cursor: pointer;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

/* 卡片描述文字 - 改进样式 */
.longtermoverview-market-index-description {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(245, 247, 250, 0.95);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.longtermoverview-market-index-description p {
  margin: 0;
  padding: 20px;
  font-size: 15px;
  line-height: 1.6;
  color: #333;
  text-align: center;
  font-weight: 400;
  max-width: 90%;
}

/* 悬停效果 - 平滑显示描述文字 */
.longtermoverview-market-index-card:hover .longtermoverview-market-index-description {
  opacity: 1;
}

/* 确保其他元素样式保持不变 */
.longtermoverview-market-index-name {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin-bottom: 12px;
  text-align: center;
}

.longtermoverview-market-index-value {
  font-size: 28px;
  font-weight: 700;
  color: #1a1a1a;
  margin-bottom: 12px;
  text-align: center;
}

.longtermoverview-market-index-change {
  font-size: 16px;
  font-weight: 500;
  padding: 6px 12px;
  border-radius: 20px;
  text-align: center;
}

.longtermoverview-market-index-change.positive {
  color: #2E7D32;
  background-color: rgba(46, 125, 50, 0.1);
}

.longtermoverview-market-index-change.negative {
  color: #D32F2F;
  background-color: rgba(211, 47, 47, 0.1);
}

/* Highlighted calendar link */
.earnings-radar-calendar-link-highlight {
  color: #1a73e8;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.2s ease;
  text-decoration: underline;
  padding: 2px 6px;
  border-radius: 4px;
  background-color: rgba(26, 115, 232, 0.08);
  margin-left: 4px;
}

.earnings-radar-calendar-link-highlight:hover {
  color: #0d47a1;
  background-color: rgba(26, 115, 232, 0.15);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

/* 优化 Strategy Edge 图表在小屏幕上的显示 */
@media screen and (max-width: 768px) {
  .strategy-edge-chart-container {
    height: 300px !important; /* 在小屏幕上减小图表高度 */
    margin-bottom: 20px;
    padding-bottom: 30px !important; /* 增加底部内边距，为x轴标签留出空间 */
  }
  
  .strategy-edge-content {
    grid-template-columns: 1fr !important; /* 在小屏幕上改为单列布局 */
  }
  
  .strategy-edge-chart-range-selector {
    top: 10px;
    right: 10px;
  }
  
  .strategy-edge-chart-range-btn {
    padding: 3px 8px;
    font-size: 11px;
  }
}

@media screen and (max-width: 576px) {
  .strategy-edge-chart-container {
    height: 250px !important; /* 在超小屏幕上进一步减小图表高度 */
    padding-bottom: 40px !important; /* 在超小屏幕上增加更多底部空间 */
  }
}

/* Updated Q&A Section Styles */
.longterm-qa-section {
  margin-bottom: 50px;
}

.longterm-qa-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.longterm-qa-item {
  background-color: #f9f9f9;
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.longterm-qa-item:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

.longterm-qa-question {
  display: flex;
  padding: 20px;
  background-color: #f5f7fa;
  border: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  align-items: center;
  transition: all 0.2s ease;
}

.longterm-qa-question:hover {
  background-color: #edf2fa;
}

.longterm-qa-toggle {
  margin-left: auto;
  font-size: 20px;
  color: #1a73e8;
  font-weight: 500;
  transition: transform 0.3s ease;
}

.longterm-qa-item.expanded .longterm-qa-toggle {
  transform: rotate(45deg);
}

.longterm-qa-answer {
  display: flex;
  padding: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease;
  background-color: white;
}

.longterm-qa-item.expanded .longterm-qa-answer {
  padding: 20px;
  max-height: 1000px;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.longterm-qa-answer-content {
  flex: 1;
}

.longterm-qa-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  font-weight: 700;
  font-size: 16px;
  margin-right: 16px;
  flex-shrink: 0;
}

.longterm-qa-question .longterm-qa-icon {
  background-color: #1a73e8;
  color: white;
}

.longterm-qa-answer .longterm-qa-icon {
  background-color: #34a853;
  color: white;
}

.longterm-qa-question h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: #333;
  line-height: 1.4;
  flex: 1;
}

.longterm-qa-answer p {
  margin: 0 0 12px 0;
  font-size: 15px;
  line-height: 1.6;
  color: #555;
}

.longterm-qa-answer p:last-child {
  margin-bottom: 0;
}

.longterm-qa-list {
  margin: 8px 0 12px 0;
  padding-left: 20px;
  list-style-type: disc;
}

.longterm-qa-list li {
  margin-bottom: 8px;
  line-height: 1.5;
  color: #555;
  font-size: 15px;
}

.longterm-qa-list li:last-child {
  margin-bottom: 0;
}

.longterm-qa-answer strong {
  color: #1a73e8;
  font-weight: 600;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .longterm-qa-question,
  .longterm-qa-item.expanded .longterm-qa-answer {
    padding: 16px;
  }
  
  .longterm-qa-question h3 {
    font-size: 15px;
  }
  
  .longterm-qa-answer p,
  .longterm-qa-list li {
    font-size: 14px;
  }
  
  .longterm-qa-icon {
    width: 28px;
    height: 28px;
    font-size: 14px;
    margin-right: 12px;
  }
} 