.reset-password-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 2rem;
    background-color: #f5f7fa;
  }
  
  .reset-password-form {
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
  }
  
  .reset-password-form h2 {
    text-align: center;
    color: #4a90e2;
    margin-bottom: 1.5rem;
  }
  
  .loading-message,
  .error-message {
    text-align: center;
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .loading-message {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  
  .error-message a {
    color: #4a90e2;
    text-decoration: none;
  }
  
  .error-message a:hover {
    text-decoration: underline;
  }