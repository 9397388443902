.marketcap-topassetscomparison {
  margin: 0;
  background: transparent;
  padding: 0;
  border-radius: 0;
}

.marketcap-topassetscomparison-container {
  background: #fff;
  padding: 24px;
  border-radius: 12px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 24px;
  min-height: 600px;
}

.marketcap-topassetscomparison-table-section {
  background: #fff;
  border-radius: 8px;
  border: 1px solid #f0f0f0;
  display: flex;
  flex-direction: column;
  height: 600px;
  overflow: hidden;
}

.marketcap-topassetscomparison-table-container {
  flex: 1;
  overflow-y: auto;
  margin-bottom: 12px;
}

.marketcap-topassetscomparison-table-container::-webkit-scrollbar {
  width: 6px;
}

.marketcap-topassetscomparison-table-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.marketcap-topassetscomparison-table-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.marketcap-topassetscomparison-table-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.marketcap-topassetscomparison-table {
  width: 100%;
  border-collapse: collapse;
}

.marketcap-topassetscomparison-table th,
.marketcap-topassetscomparison-table td {
  padding: 12px 16px;
  text-align: left;
  border-bottom: 1px solid #f0f0f0;
}

.marketcap-topassetscomparison-table th {
  background: #fafafa;
  font-weight: 500;
  color: #666;
  font-size: 16px;
  padding: 14px 16px;
}

.marketcap-topassetscomparison-table td {
  font-size: 16px;
  color: #333;
  padding: 14px 16px;
}

.marketcap-topassetscomparison-table tr:hover {
  background-color: #f8f9fa;
}

.marketcap-topassetscomparison-market-cap-value {
  font-family: monospace;
  color: #1a73e8;
  text-align: right;
}

.marketcap-topassetscomparison-show-more-btn {
  width: 100%;
  padding: 8px;
  background: #f5f5f5;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  cursor: pointer;
  color: #666;
  font-size: 14px;
  transition: all 0.3s;
  margin-top: auto;
}

.marketcap-topassetscomparison-show-more-btn:hover {
  background: #e0e0e0;
}

.marketcap-topassetscomparison-chart-section {
  height: 600px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.marketcap-topassetscomparison-chart-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
  background: transparent;
  padding: 8px 16px;
}

.marketcap-topassetscomparison-range-selector {
  display: flex;
  gap: 4px;
  background: #fff;
  padding: 4px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.marketcap-topassetscomparison-range-btn {
  padding: 4px 12px;
  border: 1px solid #e0e0e0;
  background: white;
  border-radius: 4px;
  cursor: pointer;
  font-size: 13px;
  color: #666;
  transition: all 0.2s ease;
}

.marketcap-topassetscomparison-range-btn:hover {
  background: #f5f5f5;
}

.marketcap-topassetscomparison-range-btn.active {
  background: #1a73e8;
  color: white;
  border-color: #1a73e8;
}

.marketcap-topassetscomparison-ellipsis-row {
  text-align: center;
  color: #666;
  background: #fafafa;
  cursor: pointer;
}

.marketcap-topassetscomparison-ellipsis-row:hover {
  background: #f0f0f0;
}

.title-section {
  margin-bottom: 20px;
  padding: 20px;
  border-bottom: 1px solid #f0f0f0;
}

.title-section h3 {
  font-size: 24px;
  color: #1a1a1a;
  margin: 0 0 8px 0;
  font-weight: 500;
}

.marketcap-topassetscomparison-table th:last-child,
.marketcap-topassetscomparison-table td:last-child {
  text-align: right;
  padding-right: 16px;
  font-family: monospace;
  color: #1a73e8;
} 