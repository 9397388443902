.market-cap-component {
  max-width: 1800px;
  margin: 0 auto;
  padding: 24px;
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

/* 为每个主要部分添加独立的容器样式 */
.market-overview-section,
.market-insights-section,
.top-assets-section {
  width: 100%;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.08);
}

/* 复用 Overview 的头部样式 */
.overview-btc-header {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  padding: 0;
}

.overview-btc-title {
  display: flex;
  align-items: center;
  gap: 16px;
}

.overview-btc-logo {
  width: 42px;
  height: 42px;
}

.overview-btc-title h1 {
  font-size: 42px;
  color: #1a1a1a;
  font-weight: normal;
  margin: 0;
}

/* 市值数据卡片样式 */
.market-metrics-grid {
  background: #fff;
  border-radius: 12px;
  padding: 32px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.08);
}

/* 行样式 */
.metrics-row {
  display: grid;
  grid-template-columns: 240px repeat(5, 1fr);
  gap: 24px;
  margin-bottom: 24px;
  align-items: center;
}

/* 标签列样式 */
.metric-label {
  font-size: 20px;
  color: #1a73e8;
  font-weight: normal;
}

/* 数值列样式 */
.metric-value {
  text-align: center;
  font-size: 28px;
  color: #1a1a1a;
  font-weight: normal;
  padding: 20px;
  background: #f8f9fa;
  border-radius: 8px;
  transition: all 0.2s;
}

.metric-value:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

/* 类别名称样式 */
.metric-value.category {
  color: #1a1a1a;
  font-weight: normal;
}

/* 市值数字样式 */
.metric-value.market-value {
  color: #1a73e8;
  font-weight: normal;
}

/* 百分比样式 */
.metric-value.percentage {
  color: #2E7D32;
  font-weight: normal;
}

.metric-value.percentage.dash {
  color: #666;
}

/* 响应式调整 */
@media (max-width: 768px) {
  .metrics-row {
    grid-template-columns: 1fr;
    gap: 12px;
  }

  .metric-label {
    text-align: left;
  }

  .metric-value {
    text-align: right;
  }
}

/* Market Insights Cards */
.market-insights-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  margin-top: 40px;
}

.insight-card {
  background: #f8f9fa;
  border-radius: 12px;
  padding: 24px;
  transition: transform 0.2s, box-shadow 0.2s;
}

.insight-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

.insight-card h3 {
  color: #1a73e8;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 16px;
}

.insight-card p {
  color: #1a1a1a;
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .market-insights-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .market-insights-grid {
    grid-template-columns: 1fr;
  }
}

/* 新的布局容器 */
.market-insights-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  margin-top: 40px;
}

/* 饼图容器 */
.market-chart {
  background: #fff;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.08);
  display: flex;
  align-items: center;
  justify-content: center;
}

.pie-chart-container {
  height: 450px;
  width: 100%;
  max-width: 600px;
}

/* 洞察卡片列表 */
.market-insights-list {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.insight-card {
  background: #f8f9fa;
  border-radius: 12px;
  padding: 24px;
  transition: transform 0.2s, box-shadow 0.2s;
}

.insight-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

/* 响应式调整 */
@media (max-width: 1024px) {
  .market-insights-container {
    grid-template-columns: 1fr;
  }
  
  .pie-chart-container {
    height: 300px;
  }
}

@media (max-width: 768px) {
  .market-insights-container {
    grid-template-columns: 1fr;
  }
  
  .pie-chart-container {
    height: 250px;
  }
}

.market-cap-page {
  padding: 24px 32px;
  background: #f8f9fa;
}

.market-overview-section {
  background: #fff;
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 24px;
}

.market-insights-section {
  background: #fff;
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 24px;
}

.top-assets-section {
  position: relative;
  margin-bottom: 24px;
}

.locked-content {
  position: relative;
  cursor: pointer;
}

.blurred-content {
  filter: blur(6px);
  pointer-events: none;
}

.locked-content-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
  border-radius: 12px;
}

.lock-icon {
  font-size: 48px;
  margin-bottom: 16px;
}

.locked-content-overlay h3 {
  font-size: 24px;
  color: #1a1a1a;
  margin-bottom: 8px;
}

.locked-content-overlay p {
  color: #666;
  margin-bottom: 24px;
  text-align: center;
  max-width: 80%;
}

.upgrade-button {
  padding: 12px 24px;
  background: #1a73e8;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.upgrade-button:hover {
  background: #1557b0;
}

.bcia-section {
  position: relative;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.08);
  padding: 24px;
  margin-bottom: 24px;
}