.etf-bci-container {
  padding: 32px;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.06);
}

.etf-bci-header {
  margin-bottom: 24px;
}

.etf-bci-type {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  font-size: 18px;
  font-weight: 600;
  color: #1a73e8;
  padding: 10px 20px;
  background: linear-gradient(135deg, #f0f7ff 0%, #e6f0ff 100%);
  border-radius: 12px;
  letter-spacing: 0.3px;
  box-shadow: 0 2px 8px rgba(26, 115, 232, 0.1);
}

.etf-bci-type-icon {
  font-size: 16px;
}

.etf-bci-rating-section {
  margin: 32px 0;
  padding: 0 12px;
}

.etf-bci-rating {
  max-width: 600px;
}

.etf-bci-rating-bar-wrapper {
  width: 100%;
}

.etf-bci-rating-bar {
  width: 100%;
  height: 6px;
  background: linear-gradient(to right,
    #ef5350 0%,
    #ff9800 25%,
    #4caf50 50%,
    #2e7d32 100%
  );
  border-radius: 8px;
  position: relative;
  margin-bottom: 12px;
}

.etf-bci-rating-marker {
  position: absolute;
  top: 50%;
  width: 20px;
  height: 20px;
  background: #fff;
  border: 3px solid #1a1a1a;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.etf-bci-rating-labels {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #666;
  font-weight: 500;
}

.etf-bci-metrics-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  margin-top: 8px;
}

.etf-bci-metric-card {
  background: linear-gradient(145deg, #ffffff 0%, #f8f9fa 100%);
  padding: 24px;
  border-radius: 16px;
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.etf-bci-metric-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
  border-color: rgba(26, 115, 232, 0.1);
}

.etf-bci-metric-header {
  margin-bottom: 16px;
}

.etf-bci-metric-label {
  font-size: 18px;
  color: #666;
  font-weight: 500;
  letter-spacing: 0.2px;
}

.etf-bci-metric-value {
  font-size: 28px;
  color: #1a1a1a;
  font-weight: 600;
  font-variant-numeric: tabular-nums;
  letter-spacing: -0.5px;
}

.etf-bci-loading {
  text-align: center;
  padding: 48px;
  color: #666;
  font-size: 16px;
}

@media (max-width: 1200px) {
  .etf-bci-metrics-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .etf-bci-rating {
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .etf-bci-metrics-grid {
    grid-template-columns: 1fr;
  }
  
  .etf-bci-container {
    padding: 24px;
  }
  
  .etf-bci-rating-section {
    margin: 24px 0;
    padding: 0;
  }
} 