.stock-screener-container {
  padding: 20px;
  width: 100%;
  overflow-x: hidden;
}

.stock-screener-title {
  font-size: 32px;
  color: #1a1a1a;
  font-weight: 500;
  margin: 32px 0 24px;
}

/* 表格包装器样式 */
.stock-screener-table-wrapper {
  width: 100%;
  overflow-x: auto;
  margin-top: 8px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  background: white;
}

/* 表格基础样式 */
.stock-screener-table {
  width: auto;  /* 改为 auto，让表格宽度由内容决定 */
  min-width: 100%;  /* 至少和容器一样宽 */
  table-layout: fixed;  /* 使用固定布局 */
  border-collapse: separate;
  border-spacing: 0;
  white-space: nowrap;
}

/* 设置每列的固定宽度 */
.stock-screener-table th,
.stock-screener-table td {
  padding: 12px 16px;
  width: 150px;  /* 默认列宽 */
}

/* Ticker 列宽度 */
.stock-screener-table th:first-child,
.stock-screener-table td:first-child {
  width: 100px;
  min-width: 100px;
}

/* Name 列宽度 */
.stock-screener-table th:nth-child(2),
.stock-screener-table td:nth-child(2) {
  width: 200px;
  min-width: 200px;
}

/* Market Cap 列宽度 */
.stock-screener-table th:nth-child(4),
.stock-screener-table td:nth-child(4) {
  width: 130px;
  min-width: 130px;
}

/* Recent Performance 列宽度 */
.stock-screener-table th:nth-child(3),
.stock-screener-table td:nth-child(3) {
  width: 130px;
  min-width: 130px;
}

/* 其他指标列宽度 (BCI, VP, Risk, Sentiment, Recommendation) */
.stock-screener-table th:nth-child(n+5),
.stock-screener-table td:nth-child(n+5) {
  width: 180px;
  min-width: 180px;
}

/* 表头和单元格样式 */
.stock-screener-table th,
.stock-screener-table td {
  padding: 12px 16px;
  min-width: 120px;
  border-bottom: 1px solid #eee;
  vertical-align: middle;
  text-align: center;
}

/* 表头样式 */
.stock-screener-table th {
  background: #f8f9fa;
  font-weight: 500;
  color: #1a1a1a;
  cursor: pointer;
  user-select: none;
  position: relative;
}

/* 表头内容样式 */
.stock-screener-th-content {
  display: flex;
  align-items: center;
  gap: 4px;
  min-height: 24px;
}

/* 问号图标样式 */
.stock-screener-th-content .tooltip-icon {
  margin-left: 4px;
  color: #666;
  font-size: 14px;
}

/* 排序指示器样式 */
.stock-screener-sort-indicator {
  margin-left: 4px;
  color: #1a73e8;
}

/* 对齐方式 */
.stock-screener-align-left {
  text-align: left !important;
}

.stock-screener-align-center {
  text-align: center !important;
}

.stock-screener-align-right {
  text-align: right !important;
}

/* 数值显示 */
.stock-screener-positive {
  color: #388E3C;
}

.stock-screener-negative {
  color: #D32F2F;
}

/* 等级显示 */
.stock-screener-grade-container {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  padding: 4px 0;
}

/* 水平指示器 */
.stock-screener-horizontal-indicator {
  position: relative;
  height: 6px;
  width: 60px;
  background: linear-gradient(to right, 
    #D32F2F,
    #F57C00,
    #FDD835,
    #388E3C,
    #2E7D32
  );
  border-radius: 3px;
  margin: 4px 0;
}

.stock-screener-marker {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 6px solid #333;
  transform: translateX(-50%);
  top: -7px;
}

/* 堆叠条形图 */
.stock-screener-stacked-bars {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  gap: 2px;
  height: 40px;
  justify-content: flex-end;
}

.stock-screener-bar {
  width: 24px;
  height: 4px;
  border-radius: 2px;
  transition: background-color 0.3s ease;
}

.stock-screener-grade-description {
  font-size: 14px;
  color: #333;
  margin-top: 2px;
  font-weight: 400;
  white-space: nowrap;
}

/* 筛选器样式 */
.stock-screener-filter-section {
  background: white;
  padding: 20px;
  border-radius: 8px;
  margin: 20px 0;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.stock-screener-filter-controls {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.stock-screener-filter-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px;
  border-radius: 8px;
  transition: all 0.2s ease;
}

.stock-screener-filter-group:hover {
  background: rgba(26, 115, 232, 0.04);
  box-shadow: 0 0 0 1px rgba(26, 115, 232, 0.2);
}

.stock-screener-filter-header {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #666;
  font-size: 14px;
  font-weight: 500;
  color: #1a73e8;
  font-weight: 500;
  margin-bottom: 8px;
}

.stock-screener-filter-input {
  padding: 8px 12px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
}

.stock-screener-active-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid #e0e0e0;
}

.stock-screener-filter-tag {
  display: inline-flex;
  align-items: center;
  background: #f0f7ff;
  border: 1px solid #e0e0e0;
  border-radius: 16px;
  padding: 4px 12px;
  font-size: 14px;
  color: #333;
}

.stock-screener-remove-tag {
  background: none;
  border: none;
  color: #666;
  margin-left: 8px;
  cursor: pointer;
  padding: 0 4px;
  font-size: 16px;
  line-height: 1;
}

.stock-screener-remove-tag:hover {
  color: #d32f2f;
}

/* 数字格式化 */
.stock-screener-number {
  font-variant-numeric: tabular-nums;
  font-feature-settings: "tnum";
}

/* 表头内容对齐 */
.stock-screener-th-content {
  display: flex;
  align-items: center;
  gap: 4px;
  min-height: 24px;
}

.stock-screener-align-left .stock-screener-th-content {
  justify-content: flex-start;
}

.stock-screener-align-right .stock-screener-th-content {
  justify-content: flex-end;
}

.stock-screener-align-center .stock-screener-th-content {
  justify-content: center;
}

.stock-screener-info-icon {
  color: #666;
  font-size: 12px;
  width: 16px;
  height: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  border-radius: 50%;
  margin-left: 4px;
  cursor: help;
}

.stock-screener-info-icon:hover {
  background: #f5f5f5;
  border-color: #999;
}

.stock-screener-question-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  color: #666;
  cursor: help;
}

.stock-screener-checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.stock-screener-checkbox-label {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.stock-screener-checkbox-label:hover {
  background-color: rgba(26, 115, 232, 0.08);
}

.stock-screener-checkbox-label input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid #757575;
  border-radius: 4px;
  margin: 0;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
}

.stock-screener-checkbox-label input[type="checkbox"]:checked {
  background-color: #1a73e8;
  border-color: #1a73e8;
}

/* 复选框对号样式 */
.stock-screener-checkbox-label input[type="checkbox"]:checked::after {
  content: '';
  position: absolute;
  left: 5px;
  top: 2px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* 筛选标签容器 */
.screener-filter-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid #eee;
}

/* 单个筛选标签 */
.screener-tag {
  display: inline-flex;
  align-items: center;
  background: #f8f9fa;
  border: 1px solid #dadce0;
  padding: 6px 12px;
  border-radius: 16px;
  transition: all 0.2s ease;
  font-size: 13px;
  color: #1a73e8;
  box-shadow: 0 1px 2px rgba(0,0,0,0.05);
}

.screener-tag:hover {
  background: #f1f3f4;
  border-color: #1a73e8;
}

/* 删除按钮 */
.screener-remove-tag {
  color: #5f6368;
  background: none;
  border: none;
  padding: 2px 6px;
  margin-left: 4px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease;
}

.screener-remove-tag:hover {
  background-color: rgba(26, 115, 232, 0.08);
  color: #1a73e8;
}

/* 标签组之间的分隔 */
.screener-tag + .screener-tag {
  margin-left: 4px;
}

/* 标签内容样式 */
.screener-tag-content {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #1a73e8;
}

/* 标签类别名称 */
.screener-tag-category {
  font-weight: 500;
  color: #1a73e8;
}

/* 标签值 */
.screener-tag-value {
  color: #5f6368;
}

/* 股票链接样式 */
.stock-link {
  background: none;
  border: none;
  padding: 0;
  color: #1a73e8;
  cursor: pointer;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.2s ease;
  display: inline-flex;
  align-items: center;
  gap: 4px;
}

.stock-link-arrow {
  font-size: 12px;
  opacity: 0.7;
  transition: transform 0.2s ease;
}

.stock-link:hover .stock-link-arrow {
  transform: translate(2px, -2px);
}

.stock-link:hover {
  color: #1557b0;
  text-decoration: underline;
}

/* 确保第一列左对齐 */
.stock-screener-table td:first-child {
  text-align: left;
}

/* 添加鼠标悬停效果 */
.stock-screener-table tr:hover {
  background-color: #f8f9fa;
}

/* 添加交互反馈 */
.stock-link:active {
  color: #0d47a1;
}

/* 保持按钮文本对齐 */
.stock-link {
  text-align: left;
  width: 100%;
  display: block;
}

/* 筛选器标题样式 */
.filter-section-header {
  margin-bottom: 20px;
  padding-bottom: 16px;
  border-bottom: 1px solid #eee;
}

.filter-section-title {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 18px;
  font-weight: 500;
  color: #1a1a1a;
}

.filter-icon {
  color: #1a73e8;
}

/* 修改滚动条容器样式 */
.stock-screener-scroll-container {
  position: relative;
  margin-top: 16px;
}

/* 顶部滚动区域 */
.stock-screener-top-scroll-wrapper {
  width: 100%;
  overflow-x: auto;
  margin-bottom: 4px;
  border-radius: 4px;
  background: #f8f9fa;
}

/* 滚动条占位内容 */
.stock-screener-scroll-content {
  height: 1px;
  visibility: hidden;
}

/* 统一的滚动条样式 */
.stock-screener-top-scroll-wrapper,
.stock-screener-table-wrapper {
  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
  
  /* Chrome, Safari */
  &::-webkit-scrollbar {
    height: 12px;  /* 增加滚动条高度 */
  }
  
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 6px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: #888;  /* 更深的颜色 */
    border-radius: 6px;
    border: 2px solid #f1f1f1;  /* 添加边框使滚动条更显眼 */
    
    &:hover {
      background: #666;  /* 悬浮时更深的颜色 */
    }
  }
}
 