.faq-page {
  background: linear-gradient(135deg, #ffffff 0%, #f8f9fa 100%);
  min-height: calc(100vh - 80px);
  color: #333;
  font-family: 'Poppins', sans-serif;
  padding: 40px 20px;
}

.faq-content-wrapper {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
}

.faq-layout {
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 40px;
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  position: relative;
}

.faq-layout::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(90deg, #4a90e2, #67b246, #4a90e2);
  background-size: 200% 100%;
  animation: faqGradient 15s ease infinite;
}

/* 左侧分类样式 */
.faq-categories {
  background: linear-gradient(135deg, #f8f9fa 0%, #ffffff 100%);
  padding: 20px 0;
  border-right: 1px solid rgba(74, 144, 226, 0.1);
}

.faq-category-item {
  padding: 15px 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #333;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  font-size: 1.15em;
}

.faq-category-item:hover {
  background: rgba(74, 144, 226, 0.05);
  color: #4a90e2;
}

.faq-category-item.active {
  background: #ffffff;
  color: #4a90e2;
  border-left: 4px solid #4a90e2;
  font-weight: 600;
  font-size: 1.2em;
}

/* 右侧内容样式 */
.faq-main-content {
  padding: 30px;
}

.faq-search-container {
  margin-bottom: 30px;
}

.faq-search-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.faq-search-icon {
  position: absolute;
  left: 20px;
  color: #4a90e2;
  font-size: 1.2em;
}

.faq-search-input {
  width: 100%;
  padding: 15px 20px 15px 50px;
  border: 2px solid rgba(74, 144, 226, 0.1);
  border-radius: 10px;
  font-size: 1.1em;
  transition: all 0.3s ease;
  background: #ffffff;
}

.faq-search-input:focus {
  border-color: #4a90e2;
  outline: none;
  box-shadow: 0 0 15px rgba(74, 144, 226, 0.1);
}

.faq-search-input::placeholder {
  color: #999;
}

.faq-questions-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.faq-question-item {
  background: #ffffff;
  padding: 20px 25px;
  border-radius: 12px;
  border: 1px solid rgba(74, 144, 226, 0.1);
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.faq-question-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq-question-item.expanded {
  background: #f8f9fa;
}

.faq-answer {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid rgba(74, 144, 226, 0.1);
  color: #666;
  line-height: 1.6;
  font-size: 1.05em;
}

.faq-answer p {
  margin: 0 0 15px 0;
  line-height: 1.6;
}

.faq-answer p:last-child {
  margin-bottom: 0;
}

.faq-answer ul {
  margin: 15px 0;
  padding: 0;
  list-style: none;
}

.faq-answer ul li {
  margin-bottom: 12px;
  padding-left: 24px;
  position: relative;
  line-height: 1.6;
}

.faq-answer ul li::before {
  content: '•';
  position: absolute;
  left: 8px;
  color: #4a90e2;
}

.faq-answer ul li .highlight {
  color: #4a90e2;
  font-weight: 500;
}

.faq-answer .highlight-item {
  color: #4a90e2;
  margin-bottom: 12px;
  padding-left: 24px;
  position: relative;
  line-height: 1.5;
}

.faq-answer .highlight-item::before {
  content: '•';
  position: absolute;
  left: 8px;
  color: #4a90e2;
}

.faq-answer .highlight {
  color: #4a90e2;
  font-weight: 500;
}

.faq-answer .link {
  color: #ff4757;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
  border-bottom: 1px solid transparent;
}

.faq-answer .link:hover {
  color: #ff6b81;
  border-bottom-color: #ff6b81;
}

.faq-arrow-icon {
  color: #4a90e2;
  font-size: 1.5em;
  font-weight: 300;
  transition: transform 0.3s ease;
  min-width: 24px;
  text-align: center;
}

.faq-question-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

.faq-question-item h3 {
  font-size: 1.2em;
  color: #2c3e50;
  font-weight: 600;
  line-height: 1.4;
}

@keyframes faqGradient {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

/* 响应式设计 */
@media (max-width: 1440px) {
  .faq-content-wrapper {
    max-width: 1200px;
  }
}

@media (max-width: 768px) {
  .faq-layout {
    grid-template-columns: 1fr;
  }

  .faq-categories {
    border-right: none;
    border-bottom: 1px solid rgba(74, 144, 226, 0.1);
  }

  .faq-category-item {
    padding: 12px 20px;
    font-size: 1.1em;
  }

  .faq-main-content {
    padding: 20px;
  }

  .faq-content-wrapper {
    padding: 0 15px;
  }
}

/* 添加无搜索结果样式 */
.faq-no-results {
  text-align: center;
  padding: 30px;
  color: #666;
  font-size: 1.1em;
  background: #f8f9fa;
  border-radius: 12px;
  border: 1px solid rgba(74, 144, 226, 0.1);
}

/* 全局搜索框样式 */
.faq-search-container.global {
  margin: 0 0 30px 0;
  padding: 20px;
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

.global .faq-search-input {
  font-size: 1.2em;
  padding: 20px 20px 20px 60px;
}

.global .faq-search-icon {
  left: 25px;
  font-size: 1.4em;
}

/* 响应式调整 */
@media (max-width: 768px) {
  .faq-search-container.global {
    margin: 0 0 20px 0;
    padding: 15px;
  }
  
  .global .faq-search-input {
    font-size: 1.1em;
    padding: 15px 15px 15px 50px;
  }
}
