.risk-metrics-container {
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.risk-section {
  background: #fff;
  border-radius: 16px;
  padding: 32px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.risk-section h2 {
  color: #1a1a1a;
  font-size: 28px;
  margin-bottom: 32px;
  font-weight: 600;
}

.risk-content {
  display: flex;
  gap: 48px;
}

.risk-chart-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 180px;
  padding: 16px;
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.risk-level-label {
  font-size: 20px;
  font-weight: 500;
  color: #1a1a1a;
  margin-top: 16px;
  text-align: center;
  padding: 8px 16px;
  border-radius: 20px;
  background: #f8f9fa;
}

.risk-metrics {
  flex: 1;
}

.metrics-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin-top: 16px;
}

.risk-metric-item {
  padding: 20px 24px;
  background: #f8f9fa;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease;
  position: relative;
  z-index: 1;
}

.risk-metric-item:hover {
  background: #f0f2f5;
  transform: translateY(-2px);
  z-index: 2;
}

.risk-metric-label {
  color: #4a4a4a;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
}

.risk-metric-info {
  color: #1a73e8;
  cursor: help;
  font-size: 14px;
}

.risk-metric-value {
  font-size: 20px;
  font-weight: 600;
  color: #1a1a1a;
}

/* 响应式设计 */
@media (max-width: 1200px) {
  .metrics-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}

@media (max-width: 768px) {
  .risk-content {
    flex-direction: column;
    align-items: center;
  }
  
  .risk-metrics {
    width: 100%;
  }
  
  .metrics-grid {
    grid-template-columns: 1fr;
  }
  
  .risk-section {
    padding: 24px;
  }
  
  .risk-metrics-container {
    padding: 16px;
  }
} 

/* 添加新的标题样式 */
.risk-section-title {
  display: inline-block;
  margin-bottom: 32px;
  position: relative;
}

.risk-section-text {
  font-size: 24px;
  font-weight: 600;
  color: #1a1a1a;
  padding: 8px 16px;
  background: #f8f9fa;
  border-radius: 8px;
  display: inline-block;
}

/* 添加新的样式来处理 tooltip 在 risk-metric-item 中的显示 */
.risk-metric-label .tooltip-container {
  position: static;
}

.risk-metric-label .tooltip-content {
  z-index: 1000;
}

.risk-metric-label .tooltip-content::after {
  z-index: 1001;
}

/* 添加小字标注的样式 */
.risk-metric-period {
  font-size: 12px;
  color: #666;
  margin-left: 4px;
  font-weight: normal;
}

.risk-metrics-note {
  color: #666;
  font-size: 14px;
  margin-bottom: 16px;
} 