.register-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 2rem;
  background-color: #f5f7fa;
}

.register-form {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.register-form h2 {
  text-align: center;
  color: #4a90e2;
  margin-bottom: 1.5rem;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #333;
}

.form-group input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.register-button {
  width: 100%;
  padding: 0.75rem;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: relative;
  overflow: hidden;
}

.register-button:disabled {
  background-color: #89b8ee;
  cursor: not-allowed;
}

.button-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.spinner {
  width: 20px;
  height: 20px;
  border: 2px solid #ffffff;
  border-top: 2px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* 添加按钮点击效果 */
.register-button:not(:disabled):active {
  transform: scale(0.98);
}

/* 添加按钮悬停效果 */
.register-button:not(:disabled):hover {
  background-color: #357abd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* 加载状态下的按钮样式 */
.register-button.loading {
  background-color: #89b8ee;
}

/* Google 登录按钮加载状态 */
.google-login-button.loading {
  background-color: #f5f5f5;
  color: #666;
}

.google-login-button .spinner {
  border-color: #666;
  border-top-color: transparent;
}

.register-button:hover {
  background-color: #3a7bc8;
}

.message {
  margin-bottom: 1rem;
  padding: 0.5rem;
  border-radius: 4px;
  text-align: center;
}

.message.error {
  color: #f44336;
  background-color: #ffebee;
}

.message.success {
  color: #4caf50;
  background-color: #e8f5e9;
}

.toggle-text {
  text-align: center;
  margin-top: 1rem;
}

.toggle-text a {
  color: #4a90e2;
  text-decoration: none;
}

.toggle-text a:hover {
  text-decoration: underline;
}
