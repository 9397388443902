.longterm-profitability-container {
  padding: 40px;
  background: #ffffff;
  border-radius: 24px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);
}

.longterm-profitability-header {
  margin-bottom: 48px;
  text-align: center;
}

.longterm-profitability-title {
  font-size: 32px;
  font-weight: 600;
  color: #1a1a1a;
  margin: 0 0 12px 0;
}

.longterm-profitability-subtitle {
  font-size: 16px;
  color: #64748b;
}

.longterm-profitability-content {
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.longterm-profitability-main {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}

.longterm-profitability-circle-wrapper {
  position: relative;
  width: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 装饰性背景圆环 */
.longterm-profitability-decoration-ring {
  position: absolute;
  width: 110%;
  height: 110%;
  border-radius: 50%;
  border: 1px solid rgba(203, 213, 225, 0.3);
  animation: rotate 60s linear infinite;
}

.longterm-profitability-decoration-ring::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 50%;
  border: 2px dashed rgba(203, 213, 225, 0.2);
}

/* 主进度圆环 */
.longterm-profitability-progress {
  position: relative;
  width: 100%;
  height: 100%;
  transform: rotate(-90deg);
  filter: drop-shadow(0 4px 12px rgba(0, 0, 0, 0.1));
}

.longterm-profitability-progress circle {
  fill: none;
  stroke-width: 12;
  stroke-linecap: round;
  transition: stroke-dasharray 1.2s ease;
}

.longterm-profitability-progress-bg {
  stroke: #f1f5f9;
}

.longterm-profitability-progress-bar {
  stroke-dasharray: 0, 339.292;
  transition: stroke-dasharray 1.2s ease;
}

/* 中心内容 */
.longterm-profitability-circle-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 80%;
}

.longterm-profitability-status {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 8px;
  transition: all 0.3s ease;
}

/* 装饰点 */
.longterm-profitability-decoration-dots {
  position: absolute;
  width: 100%;
  height: 100%;
  animation: rotate-reverse 30s linear infinite;
}

.longterm-profitability-dot {
  position: absolute;
  width: 4px;
  height: 4px;
  background: rgba(203, 213, 225, 0.4);
  border-radius: 50%;
  left: 50%;
  top: 50%;
}

/* 动画 */
@keyframes rotate {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@keyframes rotate-reverse {
  from { transform: rotate(360deg); }
  to { transform: rotate(0deg); }
}

@keyframes progressIn {
  from { stroke-dasharray: 0, 339.292; }
}

.longterm-profitability-progress-bar {
  animation: progressIn 1.2s ease forwards;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .longterm-profitability-container {
    padding: 24px;
  }

  .longterm-profitability-circle-wrapper {
    width: 260px;
    height: 260px;
  }

  .longterm-profitability-status {
    font-size: 28px;
  }
} 