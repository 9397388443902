.page-layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main-content-area {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: transparent;
  padding-top: 60px; /* 调整这个值以匹配 AppHeader 的高度 */
}

.home-page .main-content-area {
  padding-top: 0; /* 移除首页的顶部内边距 */
}

.page-content {
  flex-grow: 1;
}
