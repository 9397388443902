/* 产品页面基础布局 */
.products-container {
  display: flex;
  width: 100%;
  height: calc(100vh - 64px); /* 减去顶部导航栏高度 */
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

/* 侧边栏样式优化 */
.products-sidebar {
  min-width: 260px;
  width: 260px;
  height: 100%;
  position: fixed; /* 固定位置 */
  top: 64px; /* 顶部导航栏高度 */
  left: 0;
  overflow-y: auto;
  overflow-x: hidden;
  border-right: 1px solid #e0e0e0;
  background: #fff;
  padding: 24px 0; /* 增加顶部内边距 */
  z-index: 100;
  transition: transform 0.3s ease;
}

/* 主内容区域调整 */
.products-main-content {
  flex: 1;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px;
  margin-left: 260px; /* 为侧边栏留出空间 */
  width: calc(100% - 260px);
  transition: all 0.3s ease;
}

/* 折叠状态样式 */
.products-container.collapsed .products-sidebar {
  transform: translateX(-260px);
}

.products-container.collapsed .products-main-content {
  margin-left: 0;
  width: 100%;
}

/* 侧边栏导航样式 */
.sidebar-nav {
  padding: 0;
}

.nav-item-container {
  margin-bottom: 4px;
}

/* 侧边栏按钮基础样式 */
.sidebar-item {
  width: 100%;
  text-align: left;
  padding: 12px 20px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 15px;
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.2s ease;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
  letter-spacing: 0.3px;
  position: relative;
}

/* 激活状态 */
.sidebar-item.active {
  background-color: #f0f7ff;
  color: #1a73e8;
  font-weight: 500;
}

/* 悬停效果 */
.sidebar-item:hover {
  background-color: #f5f5f5;
  color: #1a73e8;
}

/* 分组标题样式 */
.sidebar-item.group {
  font-weight: 600;
  font-size: 16px;
  color: #333333;
  background-color: transparent;
}

/* 子项容器 */
.sub-items {
  position: relative;
  margin-left: 0;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  background-color: #fafafa;
}

/* 子项左侧装饰线 */
.sub-items::before {
  content: '';
  position: absolute;
  left: 28px;
  top: 0;
  bottom: 0;
  width: 2px;
  background: linear-gradient(to bottom, #e0e0e0 50%, transparent 0%);
  background-size: 2px 8px;
}

/* 子项样式 */
.sub-items .sidebar-item {
  padding: 10px 20px 10px 48px;
  font-size: 14px;
  color: #666666;
}

/* 子项悬停和激活状态 */
.sub-items .sidebar-item:hover,
.sub-items .sidebar-item.active {
  background-color: #f0f7ff;
  color: #1a73e8;
}

/* 展开/折图标 */
.expand-icon {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #f0f0f0;
  transition: all 0.3s ease;
  font-size: 12px;
  color: #666666;
}

.sidebar-item:hover .expand-icon {
  background-color: #e0e0e0;
}

.sidebar-item.group[aria-expanded="true"] .expand-icon {
  transform: rotate(180deg);
  background-color: #1a73e8;
  color: white;
}

/* 图标样式 */
.item-icon {
  margin-right: 12px;
  font-size: 18px;
  opacity: 0.9;
  width: 20px;
  text-align: center;
}

/* Overview 页面样式 */
.overview-content {
  max-width: 100%;
  overflow-x: hidden;
}

.btc-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 32px;
  margin-bottom: 12px;
  padding: 24px 32px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  position: relative;
}

.btc-title {
  display: flex;
  align-items: flex-end;
  margin-bottom: 12px;
}

.btc-title h1 {
  font-size: 36px;
  color: #1a1a1a;
  font-weight: 600;
  margin: 0 0 20px 0;
  line-height: 1;
}

.btc-icon {
  width: 32px;
  height: 32px;
}

.price-info, .market-cap, .mvrv-z, .seasonality {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.label {
  color: #666;
  font-size: 18px;
  min-width: 140px;
}

.value {
  font-size: 20px;
  font-weight: 500;
  color: #1a1a1a;
}

.change {
  font-size: 20px;
  margin-left: 12px;
  font-weight: 500;
}

.value-tag {
  margin-left: 8px;
  background: #388E3C;
  color: white;
  padding: 4px 12px;
  border-radius: 4px;
  font-size: 18px;
  font-weight: 500;
}

.highlight {
  color: #2E7D32;
}

.info-card {
  width: 100%;
  display: flex;
  align-items: flex-start;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  padding: 32px 40px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  position: relative;
}

.info-card::after {
  content: '→';
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  color: #1a73e8;
  opacity: 0.7;
  transition: all 0.3s ease;
}

.info-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

.info-card:hover::after {
  transform: translate(4px, -50%);
  opacity: 1;
}

.card-image-container {
  width: 200px;
  height: 120px;
  margin-right: 24px;
  flex-shrink: 0;
}

.card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.card-content {
  flex: 1;
}

.card-title {
  font-size: 24px;
  font-weight: 600;
  color: #333;
  margin: 0 0 16px 0;
}

.bullet-points {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px 32px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.bullet-point {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #666;
  line-height: 1.4;
}

.bullet-point:before {
  content: "•";
  color: #1a73e8;
  margin-right: 10px;
  font-size: 20px;
}


/* 调整表格单元格样式以适应新的指标示 */
.stocks-table td {
  padding: 16px 8px;
  vertical-align: middle;
}

.th-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #fff;
  border-radius: 12px;
  width: 90%;
  max-width: 800px;
  max-height: 90vh; /* 限制最大高度为视窗高度的90% */
  position: relative;
  display: flex;
  flex-direction: column;
}

.modal-qa {
  flex: 1;
  overflow-y: auto; /* 添加滚动条 */
  max-height: calc(90vh - 60px); /* 减去关闭按钮的高度 */
  padding: 20px;
}

.modal-close {
  position: absolute;
  right: 20px;
  top: 20px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
  z-index: 2;
}

.modal-close:hover {
  color: #333;
}

/* 自定义滚动条样式 */
.modal-qa::-webkit-scrollbar {
  width: 8px;
}

.modal-qa::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.modal-qa::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 4px;
}

.modal-qa::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

.positive {
  color: #388E3C;
}

.negative {
  color: #D32F2F;
}

.fear-greed-meter {
  width: 400px;
  min-width: 400px;
  margin: 0;
  padding: 0;
  gap: 12px;
}

.btc-info {
  flex: 1;
  max-width: calc(100% - 420px);
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.fear-greed-index {
  font-size: 16px;
  margin-top: 8px;
  color: #333;
}

.fear-greed-value {
  color: #FB8C00;
  font-weight: 500;
  margin-left: 8px;
}

/* 调整风险评估区域样式 */
.risk-assessment {
  margin: 16px 0 0 0;
  font-size: 18px;
  color: #666;
  line-height: 1.6;
}

.risk-assessment p {
  margin: 0;
  line-height: 1.5;
}

/* Daily Picks 标题样式 */
.daily-picks h2 {
  font-size: 32px;
  color: #1a1a1a;
  font-weight: 500;
  margin: 32px 0 24px;
}

/* 调整时速表容器样式 */
.fear-greed-meter-container {
  width: 400px;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 0;
}

/* 调整表格单元格显示 */
.stocks-table td {
  padding: 16px 8px;
  vertical-align: middle;
}

.th-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(4px);
}

.modal-content {
  width: 95vw;  /* 视窗宽度的95% */
  max-width: 1600px;
  height: 95vh;  /* 设置固定高度为视窗高度的95% */
  min-height: 800px;  /* 设置最小高度 */
  padding: 48px 64px;  /* 增加左右内边距 */
  background: #ffffff;
  border-radius: 12px;
  position: relative;
  overflow-y: auto;
  margin: 16px auto;
  display: flex;  /* 使用flex布局 */
  flex-direction: column;  /* 垂直方向排列 */
}

.modal-iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.modal-close {
  position: absolute;
  top: 16px;
  right: 16px;
  background: white;
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  z-index: 1;
  font-size: 20px;
}

.modal-close:hover {
  background: #f5f5f5;
}

/* 根列型整表内对齐 */
.align-left .th-content {
  justify-content: flex-start;
}

.align-right .th-content {
  justify-content: flex-end;
}

.align-center .th-content {
  justify-content: center;
}

.btc-logo {
    width: 42px;  /* 增加尺寸 */
    height: 42px; /* 增加尺寸 */
    margin-right: 12px;
    object-fit: contain;
    margin-bottom: 20px; /* 添加底部边距以与标题文字对齐 */
}

.btc-title {
    display: flex;
    align-items: flex-end; /* 为底部对齐 */
    margin-bottom: 12px;
}

.btc-title h1 {
    font-size: 36px;
    color: #1a1a1a;
    font-weight: 600;
    margin: 0 0 20px 0;
    line-height: 1; /* 添加行高控制确保更好的对齐 */
}

.pro-lock {
  margin-left: 8px;
  font-size: 12px;
  color: #666;
}

.sidebar-item:hover .pro-lock {
  color: #1a73e8;
}

/* 为付费功能添加视觉提示 */
.sidebar-item.pro-feature {
  position: relative;
}

.sidebar-item.pro-feature::after {
  display: none;
}

/* 特性标签统一样式 */
.feature-tag {
  font-size: 11px;
  padding: 1px 6px;
  border-radius: 3px;
  font-weight: 500;
  display: inline-block;
}

.login-tag {
  background-color: #F5F5F5;
  color: #757575;
  font-weight: normal;
}

.free-tag {
  background-color: #E8F5E9;
  color: #2E7D32;
}

/* 标签组样式 */
.tag-group {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  margin-left: auto;
}

.pro-tag {
  background-color: #E3F2FD;
  color: #1565C0;
}

/* 侧边栏项目样式调整 */
.sidebar-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
  transition: all 0.2s ease;
}

.sidebar-item .item-content {
  flex: 1;
  margin-right: 8px;
}

/* 股票列表的特殊样式 */
.stock-item {
  padding-left: 40px;
  font-size: 14px;
}

/* 确保展开图标和标签的位置正确 */
.sidebar-item .expand-icon {
  margin-left: 8px;
}

/* 添加卡片容器样式 */
.info-cards {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 20px 0;
  padding: 0;
  width: 100%;
}

.info-card {
  width: 100%;
  display: flex;
  align-items: flex-start;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  padding: 32px 40px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.card-image-container {
  width: 240px;
  height: 160px;
  margin-right: 32px;
  flex-shrink: 0;
}

.card-content {
  flex: 1;
  min-width: 0;
}

.card-title {
  font-size: 28px;
  margin-bottom: 20px;
}

.bullet-points {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px 48px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.bullet-point {
  font-size: 16px;
  line-height: 1.6;
  color: #666;
  white-space: normal;
  overflow: visible;
  text-overflow: clip;
  padding-right: 8px;
}

.bullet-point:before {
  content: "•";
  color: #1a73e8;
  margin-right: 10px;
  font-size: 20px;
}

/* 调整模态框中的 QA 部分样式 */
.modal-qa {
  padding: 32px;
  height: 100%;
  overflow-y: auto;
}

.modal-qa .qa-section {
  box-shadow: none;
  padding: 0;
  margin: 0;
  height: 100%;
}

/* 确保问答列表可以滚动 */
.qa-list {
  max-height: calc(90vh - 100px);
  overflow-y: auto;
  padding-right: 16px;
}

.modal-qa {
  padding: 32px;
  height: 100%;
  overflow-y: auto;
}

.modal-qa .qa-section {
  box-shadow: none;
  padding: 0;
  margin: 0;
  height: 100%;
}

.modal-qa .qa-section h2 {
  margin-bottom: 24px;
}

/* 确保问答列表可以滚动 */
.modal-qa .qa-list {
  max-height: calc(90vh - 120px);
  overflow-y: auto;
  padding-right: 16px;
}

/* 调整卡片样式 */
.info-cards {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 20px 0;
  padding: 0;
  width: 100%;
}

.info-card {
  width: 100%;
  display: flex;
  align-items: flex-start;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  padding: 32px 40px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

/* 预览表格样式 */
.preview-table-container {
  width: 100%;
  margin: 24px 0;
  overflow-x: auto;
}

.preview-table-container h2 {
  font-size: 24px;
  color: #1a1a1a;
  font-weight: 500;
  margin: 0 0 24px 0;
}

/* 表格基础样式 */
.preview-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background: white;
  border-radius: 8px;
  overflow: hidden;
}

/* 表头样式 */
.preview-table th {
  background: #f8f9fa;
  padding: 16px 8px;
  font-weight: 500;
  color: #5f6368;
  border-bottom: 1px solid #e0e0e0;
  white-space: nowrap;
}

/* 单元格样式 */
.preview-table td {
  padding: 16px 8px;
  border-bottom: 1px solid #e0e0e0;
  vertical-align: middle;
}

/* 表头内容布局 */
.th-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

/* 对齐样式 */
.preview-align-left {
  text-align: left;
}

.preview-align-right {
  text-align: right;
}

.preview-align-center {
  text-align: center;
}

/* 股票代码链接样式 */
.preview-stock-link {
  color: #1a73e8;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
}

.preview-stock-link:hover {
  color: #1557b0;
  text-decoration: underline;
}

/* 表格行悬停效果 */
.preview-table tbody tr:hover {
  background-color: #f8f9fa;
}

/* 等级指示器容器 */
.preview-grade-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

/* 水平指示器 */
.preview-horizontal-indicator {
  width: 100px;
  height: 4px;
  background: linear-gradient(
    to right,
    #D32F2F,
    #F57C00,
    #FDD835,
    #388E3C,
    #1B5E20
  );
  border-radius: 2px;
  position: relative;
}

/* 标记指示器 */
.preview-marker {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 6px solid #333;
  transform: translateX(-50%);
  top: -7px;
}

/* 堆叠条形图 */
.preview-stacked-bars {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  gap: 2px;
  height: 40px;
  justify-content: flex-end;
}

.preview-bar {
  width: 24px;
  height: 4px;
  border-radius: 2px;
  transition: background-color 0.3s ease;
}

/* 等级描述文本 */
.preview-grade-description {
  font-size: 14px;
  color: #333;
  margin-top: 2px;
  font-weight: 400;
  white-space: nowrap;
}

.show-more-button-container {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 32px;
  width: 100%;
}

.show-more-button {
  background-color: #1a73e8;
  color: #ffffff;
  padding: 16px 32px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: auto;
  min-width: 280px;
}

.show-more-button-title {
  font-size: 28px;  /* 与卡片标题一致 */
  font-weight: 500;
  line-height: 1.2;
}

.show-more-button-description {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
}

.show-more-button:hover {
  background-color: #1a73e8;
  color: #ffffff;
}

/* 调整卡片浮窗样式 */
.modal-content {
  width: 95vw;  /* 视窗宽度的95% */
  max-width: 1600px;
  height: 95vh;  /* 设置固定高度为视窗高度的95% */
  min-height: 800px;  /* 设置最小高度 */
  padding: 48px 64px;  /* 增加左右内边距 */
  background: #ffffff;
  border-radius: 12px;
  position: relative;
  overflow-y: auto;
  margin: 16px auto;
  display: flex;  /* 使用flex布局 */
  flex-direction: column;  /* 垂直方向排列 */
}

/* 调整标题样式 */
.modal-content h2 {
  font-size: 32px;
  margin-bottom: 32px;
  color: #1a1a1a;
  line-height: 1.3;
  flex-shrink: 0;  /* 防止标题被压缩 */
}

/* 调整内容区域样式 */
.modal-content .content-wrapper {
  flex: 1;  /* 占据剩余空间 */
  overflow-y: auto;  /* 内容过多时可滚动 */
  padding-right: 16px;  /* 为滚动条预留空间 */
}

/* 整段落样式 */
.modal-content p {
  font-size: 16px;
  line-height: 1.8;
  margin-bottom: 28px;
  color: #333;
  max-width: 1400px;
}

/* 调整图片容器样式 */
.modal-content .image-container {
  width: 100%;
  margin: 32px 0;
  text-align: center;
  flex-shrink: 0;  /* 防止图片被压缩 */
}

.modal-content img {
  max-width: 100%;
  max-height: 60vh;  /* 限制图片最大高度 */
  border-radius: 8px;
  object-fit: contain;
}

/* 增强模糊效果 */
.blur-content {
  filter: blur(8px);  /* 增加模糊程度 */
  opacity: 0.5;  /* 降低不透明度 */
  user-select: none;
  pointer-events: none;
  position: relative;
}

/* 添加遮罩层容器 */
.blur-cell-container {
  position: relative;
}

/* 遮罩层 */
.blur-cell-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);  /* 添加白色半透明遮罩 */
  backdrop-filter: blur(4px);  /* 额外的模糊效果 */
  pointer-events: none;
}

.seasonality-link {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  transition: all 0.2s ease;
  display: inline-flex;
  align-items: center;
}

.seasonality-link:hover {
  color: #1a73e8;
}

.seasonality-link:hover::after {
  content: ' #';
  color: #1a73e8;
  opacity: 0.7;
  font-size: 0.9em;
  margin-left: 2px;
}

/* 添加平滑滚动效果 */
html {
  scroll-behavior: smooth;
  scroll-padding-top: 80px; /* 考虑固定导航栏的高度 */
}

/* 确保数值显示正确 */
.seasonality-link .data-value {
  font-size: 20px;
  font-weight: 500;
}

/* 表格包装器样式 */
.preview-table-wrapper {
  position: relative;
  width: 100%;
  overflow-x: auto;
  margin: 16px 0;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  background: white;
  
  /* 自定义滚动条样式 */
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
  
  &::-webkit-scrollbar {
    height: 8px;
    display: block !important;
  }
  
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;
    
    &:hover {
      background-color: #a8a8a8;
    }
  }
}

/* 调整表格列宽 */
.preview-table th,
.preview-table td {
  padding: 12px 16px;
  width: auto;
  min-width: 120px;
}

/* 调整特定列的宽度 */
.preview-table th:first-child,
.preview-table td:first-child {
  min-width: 80px;
}

.preview-table th:nth-child(2),
.preview-table td:nth-child(2) {
  min-width: 180px;
}

/* 其他指标列的宽度 */
.preview-table th:nth-child(n+3),
.preview-table td:nth-child(n+3) {
  min-width: 140px;
}

/* 对齐样式 */
.preview-align-left { text-align: left; }
.preview-align-right { text-align: right; }
.preview-align-center { text-align: center; }

/* 正负值颜色 */
.preview-positive { color: #388E3C; }
.preview-negative { color: #D32F2F; }

/* 股票链接样式 */
.preview-stock-link {
  color: #1a73e8;
  cursor: pointer;
  font-weight: 500;
  text-decoration: none;
}

.preview-stock-link:hover {
  text-decoration: underline;
}

/* 模糊效果 */
.blur-content {
  filter: blur(4px);
  user-select: none;
  pointer-events: none;
}

/* 按钮容器样式 */
.explore-button-container {
  display: flex;
  justify-content: center;
  width: calc(100% - 64px);  /* 减去左右padding */
  margin: 24px auto 32px;    /* 使用auto实现水平居中 */
}

/* 主按钮样式调整 */
.explore-button {
  background-color: #1a73e8;
  border: none;
  border-radius: 12px;
  padding: 20px 32px;
  width: 100%;
  cursor: pointer;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
}

/* 按钮标题样式优化 */
.explore-button-title {
  font-size: 28px;
  font-weight: 500;
  /* font-style: italic; */
  line-height: 1.3;
  color: #ffffff;
  display: block;  /* 确保换行 */
  width: 100%;
  margin-bottom: 4px;
}

/* 按钮描述文字样式优化 */
.explore-button-description {
  font-size: 16px;
  font-weight: 400;
  /* font-style: italic; */
  color: rgba(255, 255, 255, 0.9);
  display: block;  /* 确保换行 */
  width: 100%;
}

/* 确保悬停状态保持样式 */
.explore-button:hover,
.explore-button:focus {
  background-color: #1a73e8;
  color: #ffffff;
  text-decoration: none;
}

/* 添加 Seasonality 的可点击样式 */
.seasonality {
  cursor: pointer;
  transition: all 0.3s ease;
}

.seasonality:hover {
  color: #1a73e8;
}

.seasonality:hover .label,
.seasonality:hover .value,
.seasonality:hover .value-tag {
  color: #1a73e8;
}

.seasonality:hover .value-tag {
  background-color: rgba(26, 115, 232, 0.1);
  color: #1a73e8;
}

/* 调整 Tooltip 容器的基础样式 */
.tooltip-container {
  position: relative;
  display: inline-flex;
  align-items: center;
  margin-left: 8px;
}

/* 调整 Tooltip 本身的样式 */
.tooltip {
  position: absolute;
  z-index: 10000;  /* 提高 z-index 以确保显示在最上层 */
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 12px 16px;
  border-radius: 6px;
  font-size: 14px;
  line-height: 1.4;
  width: max-content;
  max-width: 300px;
  bottom: calc(100% + 12px);
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 2px 8px rgba(0,0,0,0.15);
  pointer-events: none;
}

/* 添加小三角箭头 */
.tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border: 6px solid transparent;
  border-top-color: rgba(0, 0, 0, 0.8);
  z-index: 10000; /* 确保箭头也在最上层 */
}

/* 表格标题和描述样式 */
.preview-table-header {
  margin-bottom: 32px;  /* 增加底部间距 */
  width: 100%;  /* 确保容器占满宽度 */
}

.preview-table-header h2 {
  font-size: 32px;  /* 增大标题字号 */
  font-weight: 500;
  color: #1a1a1a;
  margin: 0 0 16px 0;  /* 增加标题和描述之间的间距 */
}

.preview-table-description {
  font-size: 16px;  /* 增大描述文字大小 */
  line-height: 1.6;
  color: #666666;
  margin: 0;
  max-width: 2000px;  /* 增加最大宽度 */
  width: 100%;  /* 确保在较小屏幕上也能占满宽度 */
  padding: 16px 0;
}

/* 风险评估文字颜色 */
.highlight-high-potential {
  color: #1B5E20;  /* 深绿色，与之前代码中使用的颜色保持一致 */
  font-weight: 500;
}

.highlight-moderate {
  color: #F57F17;  /* 深黄色，与之前代码中使用的颜色保持一致 */
  font-weight: 500;
}

.highlight-high-risk {
  color: #B71C1C;  /* 深红色，与之前代码中使用的颜色保持一致 */
  font-weight: 500;
}

/* 添加可点击项的基础样式 */
.clickable-stat {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  transition: all 0.2s ease;
  padding: 4px 8px;
  border-radius: 4px;
}

.clickable-stat:hover {
  background-color: rgba(26, 115, 232, 0.1);
  color: #1a73e8;
}

/* 添加或修改 SectionTitleBar 相关样式 */
.preview-table-container .section-title-bar {
  text-align: center;  /* 标题居中 */
  margin-bottom: 16px;  /* 保持适当的下边距 */
}

.preview-table-container .section-title-bar h2 {
  justify-content: center;  /* 确保标题内容居中 */
}

/* 高亮文本的基础样式 */
.preview-table-description .highlight {
  color: #1a73e8;
  font-weight: 500;
}

/* 可点击的高亮文本样式 */
.preview-table-description .clickable-stat {
  cursor: pointer;
  padding: 2px 4px;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.preview-table-description .clickable-stat:hover {
  background-color: rgba(26, 115, 232, 0.1);
  text-decoration: underline;
}

/* 调整描述文本的整体样式 */
.preview-table-description {
  font-size: 16px;
  line-height: 1.6;
  color: #666666;
  margin: 0;
  max-width: 2000px;
  width: 100%;
  padding: 16px 0;
}

.fear-greed-meters-container {
  display: flex;
  gap: 20px;
  flex-wrap: wrap; /* 允许在空间不足时换行 */
  max-width: 100%;
}

.fear-greed-meter {
  width: 380px;
  min-width: 380px;
  margin: 0;
  padding: 0;
  gap: 12px;
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

/* 修改滚动条样式 */
.products-sidebar::-webkit-scrollbar,
.products-main-content::-webkit-scrollbar {
  width: 6px;
}

.products-sidebar::-webkit-scrollbar-thumb,
.products-main-content::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 3px;
}

.products-sidebar::-webkit-scrollbar-track,
.products-main-content::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* 为 btc-info 区域内的 tooltip 添加特殊样式，提高选择器优先级 */
.btc-info .tooltip-container.tooltip-right .tooltip-content {
  z-index: 10000; /* 确保这里也使用相同的 z-index */
  left: 100% !important;  /* 使用 !important 确保覆盖其他样式 */
  transform: translateX(10px);
  margin-left: 0;
  top: 50%;  /* 垂直居中 */
  transform: translateY(-50%);  /* 确保垂直居中 */
}

/* 相应调整箭头位置 */
.btc-info .tooltip-container.tooltip-right .tooltip-content::after {
  left: -8px;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
  margin-left: 0;
}

/* 添加折叠按钮样式 */
.sidebar-toggle {
  position: fixed;
  left: 260px;
  top: 84px;
  width: 28px;
  height: 64px; /* 增加高度 */
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-left: none;
  border-radius: 0 8px 8px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 101;
  transition: all 0.3s ease;
  box-shadow: 2px 0 4px rgba(0, 0, 0, 0.05);
  color: #666;
  font-size: 18px; /* 增加字体大小 */
}

/* 悬停效果 */
.sidebar-toggle:hover {
  background: #f8f9fa;
  color: #1a73e8;
  box-shadow: 3px 0 8px rgba(0, 0, 0, 0.1);
}

/* 活跃状态 */
.sidebar-toggle:active {
  background: #f0f0f0;
}

/* 折叠状态下的按钮样式 */
.products-container.collapsed .sidebar-toggle {
  left: 0;
  /* 移除 transform: rotate(180deg); 因为我们直接改变了箭头字符 */
  border: 1px solid #e0e0e0;
  border-right: none;
  box-shadow: -2px 0 4px rgba(0, 0, 0, 0.05);
}

/* 折叠状态下的悬停效果 */
.products-container.collapsed .sidebar-toggle:hover {
  box-shadow: -3px 0 8px rgba(0, 0, 0, 0.1);
}

/* 去除按钮默认样式 */
.sidebar-toggle:focus {
  outline: none;
}

/* 添加平滑过渡效果 */
.sidebar-toggle {
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}