.etfdetails-rec-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.etfdetails-rec-section {
  background: #fff;
  border-radius: 24px;
  padding: 32px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.06);
}

.etfdetails-rec-section-title {
  color: #1a1a1a;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid #eef0f2;
}

.etfdetails-rec-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 48px;
}

.etfdetails-rec-meter-container {
  flex: 0 0 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  background: #f8f9fa;
  border-radius: 16px;
  transition: all 0.3s ease;
}

.etfdetails-rec-meter-container:hover {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  transform: translateY(-2px);
}

.etfdetails-rec-meter {
  width: 380px;
}

.etfdetails-rec-meter-label {
  font-size: 28px;
  font-weight: 600;
  color: #1a1a1a;
  margin-top: 16px;
  text-align: center;
}

.etfdetails-rec-ratings {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  background: #f8f9fa;
  border-radius: 16px;
  min-width: 420px;
}

.etfdetails-rec-rating-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  padding: 12px 16px;
  background: #fff;
  border-radius: 12px;
  transition: all 0.3s ease;
}

.etfdetails-rec-rating-item:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transform: translateY(-1px);
}

.etfdetails-rec-rating-label-wrapper {
  display: flex;
  align-items: center;
  min-width: 160px;
}

.etfdetails-rec-rating-label {
  font-size: 16px;
  color: #1a73e8;
  font-weight: 500;
}

.etfdetails-rec-rating-container {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 16px;
  max-width: 280px;
}

.etfdetails-rec-rating-track {
  flex: 1;
  height: 8px;
  background: #eef0f2;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}

.etfdetails-rec-rating-fill {
  height: 100%;
  border-radius: 4px;
  transition: width 0.3s ease;
  background: linear-gradient(90deg, 
    #D32F2F 0%,
    #FF5722 25%,
    #FDD835 50%,
    #4CAF50 75%,
    #2E7D32 100%
  );
}

.etfdetails-rec-rating-value {
  font-size: 20px;
  font-weight: 600;
  color: #1a1a1a;
  min-width: 54px;
  text-align: right;
}

.etfdetails-rec-indicators-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.etfdetails-rec-indicator-card {
  background: #f8f9fa;
  padding: 24px;
  border-radius: 16px;
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.etfdetails-rec-indicator-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
  background: #fff;
}

.etfdetails-rec-indicator-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.etfdetails-rec-indicator-label {
  color: #666;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.etfdetails-rec-indicator-value {
  color: #1a1a1a;
  font-size: 24px;
  font-weight: 600;
  font-variant-numeric: tabular-nums;
}

@media (max-width: 1200px) {
  .etfdetails-rec-header {
    flex-direction: column;
    gap: 32px;
  }
  
  .etfdetails-rec-meter-container,
  .etfdetails-rec-ratings {
    width: 100%;
    min-width: unset;
  }
  
  .etfdetails-rec-indicators-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .etfdetails-rec-section {
    padding: 20px;
  }
  
  .etfdetails-rec-indicators-grid {
    grid-template-columns: 1fr;
  }
  
  .etfdetails-rec-meter {
    width: 100%;
  }
} 