.forgot-password-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 2rem;
    background-color: #f5f7fa;
  }
  
  .forgot-password-form {
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
  }
  
  .forgot-password-form h2 {
    text-align: center;
    color: #4a90e2;
    margin-bottom: 1.5rem;
  }
  
  .message {
    padding: 1rem;
    border-radius: 4px;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .message.error {
    background-color: #ffebee;
    color: #f44336;
  }
  
  .message.success {
    background-color: #e8f5e9;
    color: #4caf50;
  }
  
  .submit-button {
    width: 100%;
    padding: 0.75rem;
    background-color: #4a90e2;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .submit-button:disabled {
    background-color: #89b8ee;
    cursor: not-allowed;
  }
  
  .submit-button:not(:disabled):hover {
    background-color: #357abd;
  }