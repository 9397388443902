.topbitcoinstocks-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 24px 32px;
}

.topbitcoinstocks-header {
  margin-bottom: 32px;
}

.topbitcoinstocks-category {
  display: inline-block;
  background: #e3f2fd;
  color: #1a73e8;
  padding: 4px 12px;
  border-radius: 4px;
  font-size: 18px;
  margin-bottom: 16px;
}

.topbitcoinstocks-meta {
  display: flex;
  gap: 16px;
  color: #666;
  font-size: 18px;
  margin-top: 12px;
}

.topbitcoinstocks-content {
  font-size: 22px;
  line-height: 1.7;
  color: #333;
}

.topbitcoinstocks-intro {
  margin-bottom: 40px;
}

.topbitcoinstocks-cover-image {
  width: 100%;
  max-width: 800px;
  height: auto;
  border-radius: 12px;
  margin: 32px auto;
  display: block;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.topbitcoinstocks-section {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
}

.topbitcoinstocks-section h2 {
  font-size: 42px;
  margin: 52px 0 32px;
  color: #1a1a1a;
}

.topbitcoinstocks-section h3 {
  font-size: 32px;
  margin: 40px 0 28px;
  color: #1a1a1a;
}

.topbitcoinstocks-section h4 {
  font-size: 28px;
  margin: 36px 0 24px;
  color: #1a1a1a;
}

.topbitcoinstocks-chart {
  width: 100%;
  max-width: 700px;
  height: auto;
  margin: 24px auto;
  display: block;
  border-radius: 8px;
  background-color: #fff;
  padding: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.topbitcoinstocks-link {
  color: #1a73e8;
  text-decoration: none;
  font-weight: 500;
  margin: 0 4px;
  display: inline-block;
}

.topbitcoinstocks-link:hover {
  text-decoration: underline;
}

.topbitcoinstocks-factors {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 32px 0;
}

.topbitcoinstocks-factor {
  background: #f8f9fa;
  padding: 24px;
  border-radius: 12px;
  border-left: 4px solid #1a73e8;
}

.topbitcoinstocks-factor h3 {
  color: #1a73e8;
  font-size: 20px;
  margin-bottom: 12px;
}

.topbitcoinstocks-highlight {
  background: #e3f2fd;
  padding: 24px;
  border-radius: 12px;
  margin: 32px 0;
}

.topbitcoinstocks-highlight p {
  color: #1a1a1a;
  font-size: 22px;
  line-height: 1.7;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.topbitcoinstocks-highlight strong {
  color: #1a73e8;
}

.topbitcoinstocks-benefits {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 32px;
  margin: 32px 0;
}

.topbitcoinstocks-benefit {
  background: #f8f9fa;
  padding: 24px;
  border-radius: 12px;
  border-left: 4px solid #1a73e8;
}

.topbitcoinstocks-benefit h3 {
  color: #1a73e8;
  font-size: 20px;
  margin-bottom: 16px;
}

.topbitcoinstocks-benefit ul {
  list-style: none;
  padding: 0;
}

.topbitcoinstocks-benefit li {
  margin-bottom: 12px;
}

.topbitcoinstocks-benefit strong {
  color: #1a73e8;
}

.topbitcoinstocks-category-section {
  margin: 32px 0;
}

.topbitcoinstocks-stock {
  background: #fff;
  padding: 24px;
  border-radius: 12px;
  margin: 24px 0;
  box-shadow: 0 2px 8px rgba(0,0,0,0.08);
}

.topbitcoinstocks-stock h4 {
  color: #1a73e8;
  font-size: 20px;
  margin-bottom: 16px;
}

.topbitcoinstocks-stock ul {
  list-style: none;
  padding: 0;
}

.topbitcoinstocks-stock li {
  margin-bottom: 12px;
  font-size: 22px;
  line-height: 1.7;
}

.topbitcoinstocks-stock strong {
  color: #1a1a1a;
}

.topbitcoinstocks-platform-features {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 32px;
  margin: 32px 0;
}

.topbitcoinstocks-feature {
  background: #f8f9fa;
  padding: 24px;
  border-radius: 12px;
  border-left: 4px solid #1a73e8;
}

.topbitcoinstocks-feature h3 {
  color: #1a73e8;
  margin-bottom: 16px;
}

.topbitcoinstocks-conclusion {
  max-width: 1200px;
  margin: 48px auto;
  padding: 32px;
  background: #f8f9fa;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.topbitcoinstocks-conclusion h2 {
  color: #1a73e8;
  margin-bottom: 24px;
}

.topbitcoinstocks-highlight-text {
  font-size: 18px;
  color: #1a73e8;
  margin: 24px 0;
}

.topbitcoinstocks-action-points,
.topbitcoinstocks-explore-more {
  margin: 24px 0;
}

.topbitcoinstocks-action-points ul,
.topbitcoinstocks-explore-more ul {
  list-style: none;
  padding-left: 0;
}

.topbitcoinstocks-action-points li,
.topbitcoinstocks-explore-more li {
  margin: 12px 0;
  padding-left: 24px;
  position: relative;
}

.topbitcoinstocks-action-points li:before,
.topbitcoinstocks-explore-more li:before {
  content: "•";
  color: #1a73e8;
  position: absolute;
  left: 0;
}

.topbitcoinstocks-final-cta {
  color: #1a73e8;
  font-size: 18px;
  text-align: center;
  margin-top: 32px;
}

.topbitcoinstocks-header h1 {
  color: #1a1a1a;
  font-size: 56px;
  line-height: 1.4;
  text-align: center;
  margin-bottom: 28px;
}

p a {
  margin: 0 4px;
}

.topbitcoinstocks-image {
  width: 100%;
  max-width: 1200px;
  height: auto;
  margin: 24px auto;
  display: block;
  border-radius: 8px;
  object-fit: cover;
}

.topbitcoinstocks-image-container {
  margin: 32px 0;
  text-align: center;
}

.topbitcoinstocks-image-caption {
  font-size: 20px;
  color: #666;
  margin-top: 16px;
  text-align: center;
}

@media (max-width: 768px) {
  .topbitcoinstocks-cover-image,
  .topbitcoinstocks-chart,
  .topbitcoinstocks-image {
    max-width: 100%;
    margin: 20px auto;
    padding: 8px;
  }
  
  .topbitcoinstocks-header h1 {
    font-size: 42px;
  }
  
  .topbitcoinstocks-content p,
  .topbitcoinstocks-highlight-box p,
  .topbitcoinstocks-stock ul li {
    font-size: 20px;
  }
  
  .topbitcoinstocks-section h2 {
    font-size: 36px;
  }
  
  .topbitcoinstocks-section h3 {
    font-size: 30px;
  }
  
  .topbitcoinstocks-section h4 {
    font-size: 26px;
  }
}

/* 左右布局容器 */
.topbitcoinstocks-flex-container {
  display: flex;
  align-items: center;
  gap: 40px;
  margin: 32px 0;
  padding: 20px;
}

/* 文字部分 */
.topbitcoinstocks-content {
  flex: 1;
  min-width: 300px;
}

/* 图片部分 */
.topbitcoinstocks-media {
  flex: 1;
  min-width: 300px;
  max-width: 600px;
}

/* 响应式设计 */
@media (max-width: 968px) {
  .topbitcoinstocks-flex-container {
    flex-direction: column;
    gap: 24px;
  }
  
  .topbitcoinstocks-content,
  .topbitcoinstocks-media {
    width: 100%;
  }
}

/* 特定的文字框样式 */
.topbitcoinstocks-highlight-box {
  flex: 1;
  min-width: 300px;
  padding: 24px 32px;
  background: #f8f9fa;
  border-radius: 12px;
  border-left: 4px solid #1a73e8;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.topbitcoinstocks-highlight-box p {
  color: #1a1a1a;
  font-size: 20px;
  line-height: 1.7;
  margin: 0;
}

.topbitcoinstocks-highlight-box a {
  color: #1a73e8;
  text-decoration: none;
  font-weight: 500;
  margin: 0 4px;
  transition: color 0.2s ease;
}

.topbitcoinstocks-highlight-box a:hover {
  color: #1557b0;
  text-decoration: underline;
}

.topbitcoinstocks-seasonality-chart {
  width: 100%;
  max-width: 1200px;
  height: auto;
  margin: 24px auto;
  display: block;
  border-radius: 8px;
  object-fit: contain;
}