.mvrv-z-value-container {
  padding: 24px;
  background: #fff;
  border-radius: 8px;
}

.mvrv-z-value-content {
  display: flex;
  gap: 24px;
  align-items: flex-start;
}

.mvrv-z-value-latest-card {
  flex: 0 0 400px;
  background: #fff;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.08);
}

.mvrv-z-value-header {
  margin-bottom: 24px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 16px;
}

.mvrv-z-value-title {
  display: flex;
  align-items: center;
  gap: 16px;
}

.mvrv-z-value-title-text {
  display: flex;
  align-items: baseline;
  gap: 8px;
}

.mvrv-z-value-title h1 {
  font-size: 42px;
  color: #1a1a1a;
  font-weight: normal;
  margin: 0;
}

.mvrv-z-value-symbol {
  font-size: 42px;
  color: #1a1a1a;
  font-weight: normal;
}

.mvrv-z-value-btc-logo {
  width: 42px;
  height: 42px;
}

.mvrv-z-value-data {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.mvrv-z-value-metric {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
}

.mvrv-z-value-metric-label {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: #666;
}

.mvrv-z-value-metric-value {
  font-size: 28px;
  font-weight: 500;
}

.mvrv-z-value-date {
  color: #666;
  font-size: 14px;
  text-align: right;
}

.mvrv-z-value-stats-container {
  flex: 1;
  background: #fff;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.08);
}

.mvrv-z-value-stats-header {
  display: flex;
  gap: 8px;
  margin-bottom: 24px;
  background: #f5f5f5;
  padding: 4px;
  border-radius: 8px;
  display: inline-flex;
}

.mvrv-z-value-range-button {
  padding: 8px 16px;
  border: none;
  background: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  color: #666;
  transition: all 0.2s;
}

.mvrv-z-value-range-button:hover {
  background: rgba(26, 115, 232, 0.1);
  color: #1a73e8;
}

.mvrv-z-value-range-button.active {
  background: #1a73e8;
  color: #fff;
}

.mvrv-z-value-stats-table {
  width: 100%;
  border-collapse: collapse;
}

.mvrv-z-value-stats-table th,
.mvrv-z-value-stats-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

.mvrv-z-value-stats-table th {
  font-weight: 500;
  color: #666;
}

.mvrv-z-value-loading,
.mvrv-z-value-error {
  text-align: center;
  padding: 48px;
  color: #666;
}

.mvrv-z-value-chart {
  background: #fff;
  border-radius: 12px;
  padding: 24px;
  margin-top: 24px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.08);
}

.mvrv-z-value-chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.mvrv-z-value-time-range {
  display: flex;
  gap: 8px;
  background: #f5f5f5;
  padding: 4px;
  border-radius: 8px;
  margin: 20px 0;
}

.mvrv-z-value-range-button {
  padding: 8px 16px;
  border: none;
  background: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  color: #666;
  transition: all 0.2s;
}

.mvrv-z-value-range-button:hover {
  background: rgba(26, 115, 232, 0.1);
  color: #1a73e8;
}

.mvrv-z-value-range-button.active {
  background: #1a73e8;
  color: #fff;
}

.mvrv-z-value-explanation {
  background: #fff;
  border-radius: 12px;
  padding: 40px;
  margin-top: 24px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.08);
}

.mvrv-z-value-explanation-section {
  margin-bottom: 40px;
}

.mvrv-z-value-explanation-section:last-child {
  margin-bottom: 0;
}

.mvrv-z-value-explanation h2 {
  font-size: 24px;
  color: #1a1a1a;
  margin: 0 0 20px 0;
  font-weight: 500;
}

.mvrv-z-value-explanation p {
  font-size: 18px;
  color: #333;
  line-height: 1.7;
  margin: 0 0 20px 0;
}

.mvrv-z-value-explanation strong {
  color: #1a1a1a;
  font-weight: 500;
}

.mvrv-z-value-formula {
  background: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
  font-family: monospace;
  font-size: 18px;
  color: #1a1a1a;
  margin: 20px 0;
}

.mvrv-z-value-explanation ul {
  list-style: none;
  padding: 0;
  margin: 20px 0;
}

.mvrv-z-value-explanation li {
  font-size: 18px;
  color: #333;
  line-height: 1.7;
  margin-bottom: 16px;
  padding-left: 24px;
  position: relative;
}

.mvrv-z-value-explanation li:before {
  content: "•";
  position: absolute;
  left: 0;
  color: #1a73e8;
  font-size: 20px;
}

.mvrv-z-value-explanation li:last-child {
  margin-bottom: 0;
}

.mvrv-interpretation {
  margin: 20px 0;
  padding: 20px;
  background: #f8f9fa;
  border-radius: 8px;
}

.mvrv-interpretation h3 {
  margin-bottom: 16px;
  font-size: 20px;
  color: #333;
}

.mvrv-interpretation ul {
  list-style: none;
  padding: 0;
}

.mvrv-interpretation li {
  margin-bottom: 12px;
  font-size: 16px;
  line-height: 1.6;
}

.score-range {
  font-weight: 600;
  padding: 2px 8px;
  border-radius: 4px;
  margin-right: 8px;
}

.score-range.green {
  background-color: rgba(46, 125, 50, 0.1);
  color: #2E7D32;
}

.score-range.yellow {
  background-color: rgba(245, 127, 23, 0.1);
  color: #F57F17;
}

.score-range.red {
  background-color: rgba(211, 47, 47, 0.1);
  color: #D32F2F;
}

.mvrv-section {
  margin-bottom: 32px;
}

.mvrv-section h2 {
  font-size: 24px;
  color: #1a1a1a;
  margin-bottom: 16px;
}

.mvrv-section h3 {
  font-size: 20px;
  color: #333;
  margin: 20px 0 12px;
}

.mvrv-formula {
  background: #f5f5f5;
  padding: 16px;
  border-radius: 8px;
  font-family: monospace;
  margin: 16px 0;
  font-size: 16px;
}

.mvrv-points {
  list-style: none;
  padding: 0;
  margin: 16px 0;
}

.mvrv-points li {
  margin-bottom: 12px;
  line-height: 1.6;
  padding-left: 20px;
  position: relative;
}

.mvrv-points li:before {
  content: "•";
  position: absolute;
  left: 0;
  color: #1a73e8;
}

.highlight-red {
  color: #D32F2F;
}

.highlight-green {
  color: #2E7D32;
}

.mvrv-subsection {
  margin: 20px 0;
}

.mvrv-conclusion {
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  margin-top: 32px;
  padding: 16px;
  background: #f8f9fa;
  border-radius: 8px;
}

.mvrv-section strong {
  color: #1a73e8;
  font-weight: 500;
}

.score-value {
  font-weight: 500;
}

.score-value.red {
  color: #D32F2F;
}

.score-value.green {
  color: #2E7D32;
} 