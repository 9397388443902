.prime-picks-container {
    padding: 24px;
    background: #ffffff;
    border-radius: 16px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
    margin-bottom: 32px;
    border: 1px solid rgba(0, 0, 0, 0.08);
}

.prime-picks-header {
    margin-bottom: 32px;
    padding-bottom: 16px;
    border-bottom: 1px solid #f0f0f0;
}

.prime-picks-title {
    font-size: 28px;
    font-weight: 700;
    color: #1a1a1a;
    margin-bottom: 16px;
}

.prime-picks-description {
    font-size: 16px;
    line-height: 1.6;
    color: #5f6368;
}

.prime-picks-content {
    min-height: 400px;
}

.prime-picks-loading,
.prime-picks-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 400px;
    text-align: center;
    padding: 40px;
}

.loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top: 4px solid #1a73e8;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin-bottom: 16px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.error-icon {
    font-size: 32px;
    margin-bottom: 16px;
}

.prime-picks-coming-soon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 300px;
    text-align: center;
    padding: 40px;
    background-color: #f8f9fa;
    border-radius: 12px;
}

.coming-soon-icon {
    font-size: 48px;
    margin-bottom: 24px;
}

.prime-picks-coming-soon h2 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 16px;
    color: #1a73e8;
}

.prime-picks-coming-soon p {
    font-size: 16px;
    line-height: 1.6;
    color: #5f6368;
    max-width: 600px;
}

/* 响应式设计 */
@media screen and (max-width: 768px) {
    .prime-picks-container {
        padding: 16px;
    }

    .prime-picks-title {
        font-size: 24px;
    }

    .prime-picks-coming-soon {
        padding: 24px;
    }
}

/* 主容器样式 */
.primePicks-container {
    padding: 24px;
    background: #ffffff;
    border-radius: 16px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
    margin-bottom: 32px;
    border: 1px solid rgba(0, 0, 0, 0.08);
}

.primePicks-header {
    margin-bottom: 24px;
}

.primePicks-title {
    font-size: 28px;
    font-weight: 700;
    color: #1a1a1a;
    margin-bottom: 8px;
}

/* 策略概览部分 */
.primePicks-overview-section {
    margin-bottom: 40px;
    padding-bottom: 32px;
    border-bottom: 1px solid #f0f0f0;
}

.primePicks-section-title {
    font-size: 22px;
    font-weight: 600;
    color: #1a1a1a;
    margin-bottom: 24px;
    padding-bottom: 12px;
    border-bottom: 1px solid #f0f0f0;
}

.primePicks-overview-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
}

.primePicks-chart-container {
    background: #ffffff;
    border-radius: 12px;
    padding: 24px 16px 8px 16px;
    border: 1px solid #eaeaea;
    position: relative;
}

.primePicks-strategy-details {
    padding: 0 8px;
}

.primePicks-strategy-name {
    font-size: 22px;
    font-weight: 600;
    color: #202124;
    margin-bottom: 20px;
}

.primePicks-strategy-info-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.primePicks-strategy-info-item {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
    padding-left: 16px;
}

.primePicks-strategy-info-item::before {
    content: "•";
    color: #1a73e8;
    font-weight: bold;
    position: absolute;
    left: 0;
    top: 0;
}

.primePicks-info-title {
    font-weight: 600;
    color: #202124;
    margin-bottom: 8px;
    font-size: 16px;
    display: block;
}

.primePicks-info-text {
    color: #5f6368;
    line-height: 1.6;
    font-size: 15px;
    display: block;
}

.primePicks-features-list {
    list-style-type: none;
    padding: 0;
    margin: 8px 0 0 0;
}

.primePicks-feature-item {
    margin-bottom: 12px;
    position: relative;
    padding-left: 16px;
    color: #5f6368;
    line-height: 1.6;
    font-size: 15px;
}

.primePicks-feature-item::before {
    content: "•";
    color: #1a73e8;
    position: absolute;
    left: 0;
    top: 0;
}

/* 股票推荐部分 */
.primePicks-picks-section {
    min-height: 300px;
}

/* 加载和错误状态 */
.primePicks-loading,
.primePicks-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 400px;
    text-align: center;
    padding: 40px;
}

.primePicks-loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top: 4px solid #1a73e8;
    width: 40px;
    height: 40px;
    animation: primePicks-spin 1s linear infinite;
    margin-bottom: 16px;
}

@keyframes primePicks-spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.primePicks-error-icon {
    font-size: 32px;
    margin-bottom: 16px;
}

/* 即将推出部分 */
.primePicks-coming-soon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 250px;
    text-align: center;
    padding: 40px;
    background-color: #f8f9fa;
    border-radius: 12px;
    border: 1px dashed #dadce0;
}

.primePicks-coming-soon-icon {
    font-size: 48px;
    margin-bottom: 24px;
}

.primePicks-coming-soon h2 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 16px;
    color: #1a73e8;
}

.primePicks-coming-soon p {
    font-size: 16px;
    line-height: 1.6;
    color: #5f6368;
    max-width: 600px;
}

/* 响应式设计 */
@media screen and (max-width: 992px) {
    .primePicks-overview-content {
        grid-template-columns: 1fr;
        gap: 24px;
    }

    .primePicks-chart-container {
        order: 1;
    }

    .primePicks-strategy-details {
        order: 2;
    }
}

@media screen and (max-width: 768px) {
    .primePicks-container {
        padding: 16px;
    }

    .primePicks-title {
        font-size: 24px;
    }

    .primePicks-section-title {
        font-size: 20px;
    }

    .primePicks-strategy-name {
        font-size: 20px;
    }

    .primePicks-info-title {
        font-size: 15px;
    }

    .primePicks-info-text,
    .primePicks-feature-item {
        font-size: 14px;
    }

    .primePicks-coming-soon {
        padding: 24px;
    }

    .primePicks-coming-soon h2 {
        font-size: 20px;
    }

    .primePicks-coming-soon p {
        font-size: 14px;
    }
}

/* 优化时间范围选择器样式 */
.primePicks-chart-range-selector {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    position: static;
    top: auto;
    right: auto;
    z-index: 1;
    background: transparent;
    border-radius: 8px;
    padding: 0;
    box-shadow: none;
}

.primePicks-chart-range-btn {
    background: #f5f5f5;
    border: 1px solid #e0e0e0;
    padding: 6px 12px;
    margin: 0 4px;
    font-size: 13px;
    font-weight: 500;
    color: #5f6368;
    cursor: pointer;
    border-radius: 4px;
    transition: all 0.2s ease;
}

.primePicks-chart-range-btn:hover {
    background-color: #eef3fd;
    color: #1a73e8;
    border-color: #d2e3fc;
}

.primePicks-chart-range-btn.active {
    background-color: #1a73e8;
    color: white;
    border-color: #1a73e8;
}

/* 自定义 ECharts 数据缩放控件样式 */
:global(.echarts-datazoom) {
    height: 30px !important;
    background-color: #f8f9fa !important;
    border-radius: 15px !important;
    border: 1px solid #eaeaea !important;
}

:global(.echarts-datazoom-handle) {
    height: 22px !important;
    border-radius: 11px !important;
    background-color: #1a73e8 !important;
    border: none !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
}

:global(.echarts-datazoom-selected) {
    background-color: #d2e3fc !important;
}

/* 图表标题样式 */
.primePicks-chart-title {
    font-size: 18px;
    font-weight: 600;
    color: #202124;
    text-align: center;
    margin-bottom: 16px;
}

/* 图表包装器样式 */
.primePicks-chart-wrapper {
    display: flex;
    flex-direction: column;
}

/* 响应式调整 */
@media screen and (max-width: 768px) {
    .primePicks-chart-range-selector {
        flex-wrap: wrap;
    }

    .primePicks-chart-range-btn {
        padding: 5px 10px;
        margin: 2px;
        font-size: 12px;
    }

    .primePicks-chart-container {
        padding: 16px 12px 8px 12px;
    }

    .primePicks-chart-title {
        font-size: 16px;
        margin-bottom: 16px;
    }
}

/* Performance Metrics 部分样式 */
.primePicks-metrics-section {
    margin-top: 40px;
    margin-bottom: 40px;
}

.primePicks-metrics-range-selector {
    display: flex;
    margin-bottom: 20px;
}

.primePicks-metrics-range-btn {
    background: #f5f5f5;
    border: 1px solid #e0e0e0;
    padding: 6px 12px;
    margin-right: 8px;
    font-size: 13px;
    font-weight: 500;
    color: #5f6368;
    cursor: pointer;
    border-radius: 4px;
    transition: all 0.2s ease;
}

.primePicks-metrics-range-btn:hover {
    background-color: #eef3fd;
    color: #1a73e8;
    border-color: #d2e3fc;
}

.primePicks-metrics-range-btn.active {
    background-color: #1a73e8;
    color: white;
    border-color: #1a73e8;
}

.primePicks-metrics-table-container {
    overflow-x: auto;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.primePicks-metrics-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
}

.primePicks-metrics-table th,
.primePicks-metrics-table td {
    padding: 14px 16px;
    text-align: left;
    border-bottom: 1px solid #eee;
    font-size: 14px;
}

.primePicks-metrics-table th {
    background-color: #f8f9fa;
    font-weight: 600;
    color: #202124;
    white-space: nowrap;
}

.primePicks-metrics-table td {
    color: #5f6368;
}

.primePicks-metrics-loading,
.primePicks-metrics-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 200px;
    text-align: center;
    padding: 20px;
}

.primePicks-loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top: 4px solid #1a73e8;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
    margin-bottom: 16px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.primePicks-error-icon {
    font-size: 24px;
    margin-bottom: 12px;
}

/* 响应式调整 */
@media screen and (max-width: 768px) {
    .primePicks-metrics-table th,
    .primePicks-metrics-table td {
        padding: 10px 12px;
        font-size: 13px;
    }

    .primePicks-metrics-range-btn {
        padding: 5px 10px;
        font-size: 12px;
    }
}

/* 添加高亮行样式 */
.primePicks-metrics-row-highlight {
    background-color: #f0f7ff;
    font-weight: 500;
}
  
.primePicks-metrics-row-highlight td {
    color: #1a73e8;
    border-bottom: 1px solid #d2e3fc;
}
  
/* 调整表格样式以适应更多列 */
.primePicks-metrics-table th,
.primePicks-metrics-table td {
    padding: 12px 14px;
    font-size: 14px;
    white-space: nowrap;
}

/* 添加表格悬停效果 */
.primePicks-metrics-row:hover {
    background-color: #fafafa;
}

/* 添加表格无数据样式 */
.primePicks-no-data {
    text-align: center;
    padding: 24px;
    color: #5f6368;
    font-style: italic;
}
  
@media screen and (max-width: 1200px) {
    .primePicks-metrics-table-container {
        overflow-x: auto;
    }
}

/* Current Holdings 部分样式 - 美化版 */
.primePicks-holdings-section {
  margin-top: 40px;
  margin-bottom: 40px;
}

.primePicks-holdings-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

/* 美化搜索框 */
.primePicks-holdings-search {
  position: relative;
  width: 280px;
}

.primePicks-holdings-search input {
  padding: 10px 12px 10px 38px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 15px;
  width: 100%;
  color: #5f6368;
  transition: all 0.2s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.primePicks-holdings-search input:focus {
  outline: none;
  border-color: #1a73e8;
  box-shadow: 0 0 0 3px rgba(26, 115, 232, 0.15);
}

.primePicks-holdings-search-icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #5f6368;
  font-size: 16px;
}

/* 美化排序选择器 */
.primePicks-holdings-sort {
  position: relative;
  width: 200px;
}

.primePicks-holdings-sort select {
  padding: 10px 12px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 15px;
  color: #5f6368;
  background-color: white;
  cursor: pointer;
  appearance: none;
  padding-right: 36px;
  width: 100%;
  transition: all 0.2s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.primePicks-holdings-sort select:focus {
  outline: none;
  border-color: #1a73e8;
  box-shadow: 0 0 0 3px rgba(26, 115, 232, 0.15);
}

.primePicks-holdings-sort-icon {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #5f6368;
  font-size: 16px;
  pointer-events: none;
}

/* 美化表格容器 */
.primePicks-holdings-table-container {
  overflow-x: auto;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  max-height: 600px; /* 增加最大高度 */
  overflow-y: auto;
}

/* 美化表格 */
.primePicks-holdings-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  font-size: 15px; /* 增大字体 */
}

.primePicks-holdings-table thead {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #f8f9fa;
}

.primePicks-holdings-table th {
  padding: 16px 20px; /* 增加内边距 */
  text-align: left;
  font-weight: 600;
  color: #202124;
  background-color: #f8f9fa;
  border-bottom: 2px solid #e0e0e0;
  white-space: nowrap;
  font-size: 15px; /* 增大字体 */
}

.primePicks-holdings-table td {
  padding: 16px 20px; /* 增加内边距 */
  text-align: left;
  border-bottom: 1px solid #eee;
  font-size: 15px; /* 增大字体 */
  color: #5f6368;
}

/* 调整列对齐方式 */
.primePicks-holdings-table th:nth-child(1),
.primePicks-holdings-table td:nth-child(1) {
  text-align: left;
}

.primePicks-holdings-table th:nth-child(3),
.primePicks-holdings-table td:nth-child(3),
.primePicks-holdings-table th:nth-child(4),
.primePicks-holdings-table td:nth-child(4),
.primePicks-holdings-table th:nth-child(6),
.primePicks-holdings-table td:nth-child(6),
.primePicks-holdings-table th:nth-child(7),
.primePicks-holdings-table td:nth-child(7),
.primePicks-holdings-table th:nth-child(8),
.primePicks-holdings-table td:nth-child(8) {
  text-align: right; /* 数字类数据右对齐 */
}

.primePicks-holdings-ticker {
  font-weight: 600;
  color: #202124;
}

.primePicks-holdings-price {
  font-weight: 500;
  color: #202124;
}

.primePicks-holdings-market-cap {
  color: #1a73e8;
  font-weight: 500;
}

/* 表格行悬停效果 */
.primePicks-holdings-row:hover {
  background-color: #f5f9ff;
}

/* 表格行交替颜色 */
.primePicks-holdings-row:nth-child(even) {
  background-color: #fafafa;
}

.primePicks-holdings-row:nth-child(even):hover {
  background-color: #f5f9ff;
}

/* 加载和错误状态 */
.primePicks-holdings-loading,
.primePicks-holdings-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  text-align: center;
  padding: 20px;
}

.primePicks-holdings-no-data {
  text-align: center;
  padding: 24px;
  color: #5f6368;
  font-style: italic;
  font-size: 15px;
}

/* 响应式调整 */
@media screen and (max-width: 992px) {
  .primePicks-holdings-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
  
  .primePicks-holdings-search,
  .primePicks-holdings-sort {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .primePicks-holdings-table th,
  .primePicks-holdings-table td {
    padding: 12px 16px;
    font-size: 14px;
  }
}

/* 错误状态显示优化 */
.primePicks-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  text-align: center;
  padding: 40px;
  background-color: #fff8f8;
  border-radius: 12px;
  border: 1px solid #ffebeb;
}

.primePicks-error-icon {
  font-size: 48px;
  margin-bottom: 16px;
  color: #e53935;
}

.primePicks-error p {
  font-size: 16px;
  line-height: 1.6;
  color: #5f6368;
  max-width: 600px;
}

.primePicks-error-actions {
  margin-top: 24px;
}

.primePicks-error-retry {
  background-color: #1a73e8;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.primePicks-error-retry:hover {
  background-color: #1765cc;
}

/* 空数据状态显示 */
.primePicks-empty-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  text-align: center;
  padding: 40px;
  background-color: #f8f9fa;
  border-radius: 12px;
  border: 1px dashed #dadce0;
}

.primePicks-empty-data-icon {
  font-size: 48px;
  margin-bottom: 16px;
  color: #5f6368;
}

.primePicks-empty-data p {
  font-size: 16px;
  line-height: 1.6;
  color: #5f6368;
  max-width: 600px;
}

/* 数据加载中状态优化 */
.primePicks-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  text-align: center;
  padding: 40px;
}

.primePicks-loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #1a73e8;
  width: 40px;
  height: 40px;
  animation: primePicks-spin 1s linear infinite;
  margin-bottom: 16px;
}

.primePicks-loading span {
  color: #5f6368;
  font-size: 16px;
}

/* 表格内容为空时的样式 */
.primePicks-table-empty {
  padding: 40px;
  text-align: center;
  color: #5f6368;
  font-style: italic;
  background-color: #f8f9fa;
  border-radius: 8px;
}

/* Clickable ticker styling */
.primePicks-ticker-link {
  color: #1976d2;
  cursor: pointer;
  font-weight: 500;
}

.primePicks-ticker-link:hover {
  text-decoration: underline;
}

/* Price and return styling */
.primePicks-price-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
}

.primePicks-price-value {
  font-weight: 500;
}

.primePicks-return-value {
  font-size: 0.9em;
}

.primePicks-return-value.positive {
  color: #34a853;
}

.primePicks-return-value.negative {
  color: #ea4335;
}

/* EPS value styling */
.primePicks-eps-value.positive {
  color: #34a853;
}

.primePicks-eps-value.negative {
  color: #ea4335;
}

/* Sector link styling */
.primePicks-sector-link {
  color: #1976d2;
  cursor: pointer;
}

.primePicks-sector-link:hover {
  text-decoration: underline;
}

/* Holdings controls styling */
.primePicks-holdings-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.primePicks-search-container {
  position: relative;
  width: 300px;
}

.primePicks-search-input {
  width: 100%;
  padding: 10px 15px;
  padding-left: 40px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.primePicks-search-icon {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #666;
  font-style: normal;
}

.primePicks-sort-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.primePicks-sort-select {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  background-color: white;
}

/* Portfolio Distribution Section Styles */
.primePicks-distribution-section {
  margin-top: 40px;
  margin-bottom: 40px;
}

.primePicks-distribution-content {
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  padding: 24px;
}

.primePicks-distribution-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}

.primePicks-distribution-column {
  display: flex;
  flex-direction: column;
}

.primePicks-distribution-subtitle {
  font-size: 18px;
  font-weight: 600;
  color: #202124;
  margin-bottom: 16px;
  text-align: center;
}

.primePicks-distribution-chart {
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #eaeaea;
  padding: 16px;
  height: 400px;
}

.primePicks-distribution-loading,
.primePicks-distribution-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  text-align: center;
  padding: 40px;
}

/* Responsive adjustments */
@media screen and (max-width: 992px) {
  .primePicks-distribution-row {
    grid-template-columns: 1fr;
    gap: 24px;
  }
  
  .primePicks-distribution-chart {
    height: 350px;
  }
}