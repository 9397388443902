.sector-risk-container {
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background: #fff;
  overflow: visible;
}

.sector-risk-section {
  background: #fff;
  padding: 16px 0;
  border-bottom: 1px solid #eee;
}

.sector-risk-section:last-child {
  border-bottom: none;
  padding-bottom: 20px;
  overflow: visible;
}

.sector-risk-section h2 {
  color: #1a1a1a;
  font-size: 24px;
  margin-bottom: 24px;
  font-weight: 600;
}

.sector-risk-metrics-row {
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
}

.sector-risk-metrics-row span {
  color: #1a1a1a;
  font-size: 16px;
  font-weight: 500;
}

/* Risk Gauge styles */
.sector-risk-gauge {
  position: relative;
  width: 180px;
  margin: 0 auto;
}

.sector-risk-gauge-circle {
  position: relative;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  background: #f5f5f5;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.sector-risk-gauge-segments {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  transform: rotate(-90deg);
}

.sector-risk-gauge-segment {
  position: absolute;
  width: 50%;
  height: 50%;
  transform-origin: 100% 100%;
  transition: opacity 0.3s ease;
  opacity: 0.3;
}

.sector-risk-gauge-segment.active {
  opacity: 1;
}

.sector-risk-gauge-segment.segment-A {
  transform: rotate(0deg) skew(30deg);
  background-color: #2E7D32;
}

.sector-risk-gauge-segment.segment-B {
  transform: rotate(72deg) skew(30deg);
  background-color: #4CAF50;
}

.sector-risk-gauge-segment.segment-C {
  transform: rotate(144deg) skew(30deg);
  background-color: #FFA726;
}

.sector-risk-gauge-segment.segment-D {
  transform: rotate(216deg) skew(30deg);
  background-color: #EF5350;
}

.sector-risk-gauge-segment.segment-E {
  transform: rotate(288deg) skew(30deg);
  background-color: #B71C1C;
}

.sector-risk-gauge-value {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  margin-top: 16px;
}

.sector-risk-content {
  display: flex;
  gap: 48px;
  align-items: center;
}

.sector-risk-metrics {
  flex: 1;
}

.sector-risk-metrics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 24px;
}

.sector-risk-metric-card {
  background: #fff;
  padding: 24px;
  border-radius: 8px;
  border: 1px solid #eee;
  transition: all 0.3s ease;
}

.sector-risk-metric-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.06);
}

.sector-risk-metric-label {
  color: #666;
  font-size: 16px;
  margin-bottom: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
}

.sector-risk-metric-label .tooltip {
  margin-left: 4px;
}

.sector-risk-metric-value {
  color: #1a1a1a;
  font-size: 28px;
  font-weight: 600;
  font-variant-numeric: tabular-nums;
  line-height: 1.2;
}

.sector-risk-loading,
.sector-risk-error {
  padding: 24px;
  text-align: center;
  color: #666;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
}

.sector-risk-error {
  color: #d32f2f;
  background: #ffebee;
}

.sector-risk-circle-wrapper {
  position: relative;
  width: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto 40px;
}

/* 装饰性背景圆环 */
.sector-risk-decoration-ring {
  position: absolute;
  width: 110%;
  height: 110%;
  border-radius: 50%;
  border: 1px solid rgba(203, 213, 225, 0.3);
  animation: rotate 60s linear infinite;
}

.sector-risk-decoration-ring::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 50%;
  border: 2px dashed rgba(203, 213, 225, 0.2);
}

/* 主进度圆环 */
.sector-risk-progress {
  position: relative;
  width: 100%;
  height: 100%;
  transform: rotate(-90deg);
  filter: drop-shadow(0 4px 12px rgba(0, 0, 0, 0.1));
}

.sector-risk-progress circle {
  fill: none;
  stroke-width: 12;
  stroke-linecap: round;
  transition: stroke-dasharray 1.2s ease;
}

.sector-risk-progress-bg {
  stroke: #f1f5f9;
}

.sector-risk-progress-bar {
  stroke-dasharray: 0, 339.292;
  transition: stroke-dasharray 1.2s ease;
  animation: progressIn 1.2s ease forwards;
}

/* 中心内容 */
.sector-risk-circle-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 80%;
}

.sector-risk-status {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 8px;
  transition: all 0.3s ease;
}

/* 装饰点 */
.sector-risk-decoration-dots {
  position: absolute;
  width: 100%;
  height: 100%;
  animation: rotate-reverse 30s linear infinite;
}

.sector-risk-dot {
  position: absolute;
  width: 4px;
  height: 4px;
  background: rgba(203, 213, 225, 0.4);
  border-radius: 50%;
  left: 50%;
  top: 50%;
}

/* 动画 */
@keyframes rotate {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@keyframes rotate-reverse {
  from { transform: rotate(360deg); }
  to { transform: rotate(0deg); }
}

@keyframes progressIn {
  from { stroke-dasharray: 0, 339.292; }
}

/* 响应式调整 */
@media (max-width: 768px) {
  .sector-risk-container {
    padding: 16px;
  }

  .sector-risk-metrics-row {
    flex-direction: column;
    gap: 16px;
  }

  .sector-risk-section h2 {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .sector-risk-circle-wrapper {
    width: 260px;
    height: 260px;
  }

  .sector-risk-status {
    font-size: 28px;
  }

  .sector-risk-metrics-grid {
    grid-template-columns: 1fr;
    gap: 16px;
  }

  .sector-risk-metric-card {
    padding: 20px;
  }

  .sector-risk-metric-label {
    font-size: 14px;
    margin-bottom: 8px;
  }

  .sector-risk-metric-value {
    font-size: 24px;
  }
}

/* 为最后一行的 tooltip 添加特殊处理 */
.sector-risk-section:last-child .static-tooltip {
  position: relative;
  display: inline-block;
}

.sector-risk-section:last-child .static-tooltip .tooltip-content {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 8px;
  z-index: 1000;
  white-space: normal;
  width: max-content;
  max-width: 300px;
}

/* 调整箭头位置 */
.sector-risk-section:last-child .static-tooltip .tooltip-content::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 6px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.85) transparent transparent transparent;
}

/* 确保容器不会截断内容 */
.sector-risk-section:last-child {
  overflow: visible;
  padding-bottom: 20px;
}

.sector-risk-container {
  overflow: visible;
} 