.insights-article-card {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  text-decoration: none;
  height: 100%;
  border: 1px solid #eef0f2;
  position: relative;
}

.insights-article-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
}

.insights-article-image {
  position: relative;
  width: 100%;
  height: 240px;
  overflow: hidden;
  background: #f5f7f9;
}

.insights-article-image::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  background: linear-gradient(to top, rgba(0,0,0,0.2), transparent);
}

.insights-article-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.insights-article-card:hover .insights-article-image img {
  transform: scale(1.05);
}

.insights-article-content {
  padding: 28px;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

.insights-article-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.insights-article-category {
  color: #1a73e8;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  background: #e8f0fe;
  padding: 6px 12px;
  border-radius: 20px;
}

.insights-article-date {
  color: #666;
  font-size: 14px;
}

.insights-article-title {
  font-size: 24px;
  font-weight: 600;
  color: #1a1a1a;
  margin: 0 0 16px;
  line-height: 1.4;
}

.insights-article-excerpt {
  color: #4a5568;
  font-size: 16px;
  line-height: 1.6;
  margin: 0 0 24px;
  flex: 1;
}

.insights-article-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  border-top: 1px solid #eef0f2;
}

.insights-article-author {
  display: flex;
  align-items: center;
  gap: 12px;
}

.insights-article-author img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.insights-article-read-time {
  color: #718096;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 6px;
  background: #f8f9fa;
  padding: 6px 12px;
  border-radius: 20px;
}

.insights-article-read-time::before {
  content: "🕒";
  font-size: 14px;
}

@media (max-width: 768px) {
  .insights-article-image {
    height: 160px;
  }
  
  .insights-article-content {
    padding: 16px;
  }
  
  .insights-article-title {
    font-size: 18px;
  }
  
  .insights-article-excerpt {
    font-size: 14px;
  }
} 