.sector-detail-container {
  padding: 0;
  background: #ffffff;
  height: 100%;
}

.sector-detail-header {
  padding: 32px;
  border-bottom: 1px solid #e6e6e6;
  background: #ffffff;
}

.sector-detail-title-wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
}

.sector-detail-title {
  font-size: 36px;
  color: #1a1a1a;
  margin: 0;
  font-weight: 600;
  line-height: 1.2;
}

.sector-detail-tag {
  background: #e8f0fe;
  color: #1a73e8;
  padding: 6px 16px;
  border-radius: 16px;
  font-size: 15px;
  font-weight: 500;
  white-space: nowrap;
}

.sector-detail-tabs {
  display: flex;
  gap: 4px;
  padding: 0 32px;
  background: #ffffff;
  border-bottom: 1px solid #e6e6e6;
}

.sector-tab {
  padding: 16px 24px;
  border: none;
  background: none;
  color: #666;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  position: relative;
  transition: color 0.3s;
}

.sector-tab:hover {
  color: #1a73e8;
}

.sector-tab.active {
  color: #1a73e8;
}

.sector-tab.active::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  height: 2px;
  background: #1a73e8;
}

.sector-detail-content {
  padding: 24px 32px;
}

.sector-detail-loading,
.sector-detail-error,
.sector-detail-no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  font-size: 18px;
  color: #666666;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .sector-detail-header,
  .sector-detail-tabs,
  .sector-detail-content {
    padding: 16px;
  }

  .sector-detail-tabs {
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }

  .sector-tab {
    padding: 12px 16px;
    font-size: 14px;
  }
} 