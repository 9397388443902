.insightspage-container {
    display: flex;
    min-height: calc(100vh - 60px);
    margin-top: 60px;
    background-color: #f8f9fa;
    width: 100%;
  }
  
  /* 侧边栏样式 */
  .insightspage-sidebar {
    width: 320px;
    min-width: 320px;
    background-color: #ffffff;
    border-right: 1px solid #e0e0e0;
    height: calc(100vh - 60px);
    position: fixed;
    top: 60px;
    left: 0;
    overflow-y: auto;
    padding: 32px 0;
    box-shadow: 2px 0 8px rgba(0, 0, 0, 0.05);
  }
  
  .insightspage-sidebar-header {
    padding: 0 24px;
    margin-bottom: 24px;
  }
  
  .insightspage-sidebar-header h2 {
    font-size: 24px;
    color: #1a1a1a;
    margin: 0 0 8px 0;
  }
  
  .insightspage-sidebar-header p {
    color: #666;
    font-size: 14px;
    margin: 0;
  }
  
  .insightspage-nav {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 0 16px;
  }
  
  .insightspage-nav-item {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    padding: 16px;
    border: none;
    background: none;
    width: 100%;
    text-align: left;
    border-radius: 12px;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .insightspage-nav-icon {
    font-size: 24px;
    padding: 8px;
    background: #f5f5f5;
    border-radius: 8px;
    transition: all 0.2s ease;
  }
  
  .insightspage-nav-content {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  
  .insightspage-nav-label {
    font-size: 16px;
    font-weight: 500;
    color: #1a1a1a;
  }
  
  .insightspage-nav-description {
    font-size: 14px;
    color: #666;
    display: none;
  }
  
  .insightspage-nav-item:hover {
    background-color: #f5f5f5;
  }
  
  .insightspage-nav-item:hover .insightspage-nav-description {
    display: block;
  }
  
  .insightspage-nav-item.active {
    background-color: #e8f0fe;
  }
  
  .insightspage-nav-item.active .insightspage-nav-icon {
    background-color: #1a73e8;
    color: #fff;
  }
  
  /* 主内容区域样式 */
  .insightspage-main-content {
    margin-left: 320px;
    flex: 1;
    padding: 48px;
    width: calc(100% - 320px);
  }
  
  .insightspage-content-header {
    margin-bottom: 40px;
  }
  
  .insightspage-content-header h1 {
    font-size: 36px;
    font-weight: 600;
    color: #1a1a1a;
    margin: 0 0 12px 0;
  }
  
  .insightspage-content-header p {
    font-size: 18px;
    color: #666;
    margin: 0 0 16px 0;
    max-width: 600px;
  }
  
  .insightspage-content-meta {
    color: #666;
    font-size: 14px;
  }
  
  .insightspage-articles-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
    gap: 32px;
  }
  
  .insightspage-empty-state {
    text-align: center;
    padding: 80px 0;
    color: #666;
  }
  
  .insightspage-empty-icon {
    font-size: 48px;
    margin-bottom: 16px;
  }
  
  .insightspage-empty-state h3 {
    font-size: 24px;
    color: #1a1a1a;
    margin: 0 0 8px 0;
  }
  
  .insightspage-empty-state p {
    font-size: 16px;
    color: #666;
    margin: 0;
  }
  
  /* 响应式设计 */
  @media (max-width: 1024px) {
    .insightspage-articles-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 768px) {
    .insightspage-sidebar {
      display: none;
    }
    
    .insightspage-main-content {
      margin-left: 0;
      width: 100%;
      padding: 24px;
    }
    
    .insightspage-articles-grid {
      grid-template-columns: 1fr;
    }
  }