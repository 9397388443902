.sector-heat-container {
  padding: 32px 40px;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
}

.sector-heat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  padding-bottom: 20px;
  border-bottom: 1px solid #f0f0f0;
}

.sector-heat-title {
  font-size: 28px;
  font-weight: 600;
  color: #1a1a1a;
  letter-spacing: -0.5px;
}

.sector-heat-score-wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  background: #f8f9fa;
  padding: 8px 16px;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.sector-heat-score {
  font-size: 42px;
  font-weight: 700;
  color: #1a1a1a;
}

.sector-heat-icon {
  font-size: 36px;
  transition: all 0.3s ease;
}

.sector-heat-metrics-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 28px;
}

.sector-heat-metric {
  padding: 24px;
  background: #f8f9fa;
  border-radius: 16px;
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sector-heat-metric:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
  background: #ffffff;
}

.sector-heat-metric-label {
  font-size: 16px;
  color: #666666;
  margin-bottom: 12px;
  font-weight: 500;
  letter-spacing: 0.3px;
}

.sector-heat-metric-value {
  font-size: 24px;
  color: #1a1a1a;
  font-weight: 600;
  letter-spacing: -0.5px;
}

.sector-heat-loading,
.sector-heat-error {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  color: #666666;
  font-size: 18px;
  background: #f8f9fa;
  border-radius: 16px;
}

/* 图标颜色和动画效果 */
.heat-excellent {
  color: #1B5E20;
  animation: flame 2s infinite;
}

.heat-good {
  color: #2E7D32;
  animation: pulse 2s infinite;
}

.heat-neutral {
  color: #FFC107;
  animation: none;
}

.heat-poor {
  color: #D32F2F;
  animation: none;
}

.heat-cold {
  color: #B71C1C;
  animation: rotate 4s infinite;
}

@keyframes flame {
  0% {
    transform: scale(1) rotate(-5deg);
  }
  50% {
    transform: scale(1.1) rotate(5deg);
  }
  100% {
    transform: scale(1) rotate(-5deg);
  }
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

@keyframes rotate {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

/* 响应式调整 */
@media (max-width: 768px) {
  .sector-heat-container {
    padding: 24px;
  }

  .sector-heat-metrics-grid {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .sector-heat-score {
    font-size: 36px;
  }

  .sector-heat-title {
    font-size: 24px;
  }

  .sector-heat-metric {
    padding: 20px;
  }

  .sector-heat-metric-value {
    font-size: 22px;
  }

  .sector-heat-icon {
    font-size: 28px;
  }
}

.sector-heat-chart-section {
  margin-top: 48px;
  background: #fff;
  border-radius: 16px;
  padding: 32px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
}

.sector-heat-chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}

.sector-heat-chart-header h2 {
  font-size: 28px;
  color: #1a1a1a;
  margin: 0;
  font-weight: 600;
  letter-spacing: -0.5px;
}

.sector-heat-time-range-selector {
  display: flex;
  gap: 8px;
  background: #f8f9fa;
  padding: 4px;
  border-radius: 8px;
}

.sector-heat-time-range-button {
  padding: 8px 16px;
  border: 1px solid transparent;
  background: transparent;
  border-radius: 6px;
  cursor: pointer;
  color: #666;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
}

.sector-heat-time-range-button:hover {
  background: rgba(26, 115, 232, 0.1);
  color: #1a73e8;
}

.sector-heat-time-range-button.active {
  background: #1a73e8;
  color: #fff;
  border-color: #1a73e8;
}

.sector-heat-chart {
  border-radius: 12px;
  overflow: hidden;
  margin-top: 20px;
  background: #fff;
}

/* 响应式调整 */
@media (max-width: 768px) {
  .sector-heat-chart-section {
    padding: 16px;
  }

  .sector-heat-chart-header {
    flex-direction: column;
    gap: 16px;
  }

  .sector-heat-time-range-button {
    padding: 4px 8px;
    font-size: 12px;
  }
} 
