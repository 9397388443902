.longterm-risk-container {
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.longterm-risk-section {
  background: #fff;
  border-radius: 24px;
  padding: 40px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
}

.longterm-risk-title {
  color: #1a1a1a;
  font-size: 28px;
  margin-bottom: 40px;
  font-weight: 600;
}

.longterm-risk-content {
  display: flex;
  gap: 64px;
  align-items: flex-start;
}

.longterm-risk-gauge {
  position: relative;
  width: 200px;
  margin: 0 auto;
}

.longterm-risk-gauge-circle {
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background: #f5f5f5;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.longterm-risk-gauge-segments {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  transform: rotate(-90deg);
}

.longterm-risk-gauge-segment {
  position: absolute;
  width: 50%;
  height: 50%;
  transform-origin: 100% 100%;
  transition: opacity 0.3s ease;
  opacity: 0.3;
}

.longterm-risk-gauge-segment.active {
  opacity: 1;
}

.longterm-risk-gauge-segment.segment-A {
  transform: rotate(0deg) skew(30deg);
  background-color: #2E7D32;
}

.longterm-risk-gauge-segment.segment-B {
  transform: rotate(72deg) skew(30deg);
  background-color: #4CAF50;
}

.longterm-risk-gauge-segment.segment-C {
  transform: rotate(144deg) skew(30deg);
  background-color: #FFA726;
}

.longterm-risk-gauge-segment.segment-D {
  transform: rotate(216deg) skew(30deg);
  background-color: #EF5350;
}

.longterm-risk-gauge-segment.segment-E {
  transform: rotate(288deg) skew(30deg);
  background-color: #B71C1C;
}

.longterm-risk-gauge-value {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  margin-top: 16px;
  transition: color 0.3s ease;
}

.longterm-risk-metrics {
  flex: 1;
}

.longterm-risk-metrics-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
}

.longterm-risk-metric-card {
  background: linear-gradient(145deg, #ffffff 0%, #f8f9fa 100%);
  padding: 24px;
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.longterm-risk-metric-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
  border-color: rgba(26, 115, 232, 0.1);
}

.longterm-risk-metric-label {
  color: #666;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  letter-spacing: 0.3px;
}

.longterm-risk-metric-value {
  color: #1a1a1a;
  font-size: 24px;
  font-weight: 600;
  font-variant-numeric: tabular-nums;
  letter-spacing: -0.5px;
}

@media (max-width: 1024px) {
  .longterm-risk-content {
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }
  
  .longterm-risk-metrics {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .longterm-risk-container {
    padding: 20px;
  }

  .longterm-risk-section {
    padding: 24px;
    border-radius: 20px;
  }

  .longterm-risk-title {
    font-size: 24px;
    margin-bottom: 32px;
  }

  .longterm-risk-metrics-grid {
    grid-template-columns: 1fr;
    gap: 16px;
  }

  .longterm-risk-metric-card {
    padding: 20px;
  }
}

.longterm-risk-loading,
.longterm-risk-error {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  color: #666;
  font-size: 16px;
  text-align: center;
  background: #fff;
  border-radius: 24px;
  padding: 40px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
}

.longterm-risk-error {
  color: #d32f2f;
  background: #ffebee;
} 