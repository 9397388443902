.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 2rem;
  background-color: #f5f7fa;
}

.login-form {
  background-color: #ffffff;
  padding: 40px;
  border-radius: 16px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.06);
  width: 100%;
  max-width: 460px;
}

.login-form h2 {
  text-align: center;
  color: #1a73e8;
  margin-bottom: 32px;
  font-size: 32px;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #333;
}

.form-group input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.login-button {
  width: 100%;
  padding: 0.75rem;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.login-button:disabled {
  background-color: #89b8ee;
  cursor: not-allowed;
}

.button-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.spinner {
  width: 20px;
  height: 20px;
  border: 2px solid #ffffff;
  border-top: 2px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* 添加按钮点击效果 */
.login-button:not(:disabled):active {
  transform: scale(0.98);
}

/* 添加按钮悬停效果 */
.login-button:not(:disabled):hover {
  background-color: #357abd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* 加载状态下的按钮样式 */
.login-button.loading {
  background-color: #89b8ee;
}

.message.error {
  color: #f44336;
  margin-bottom: 1rem;
}

.toggle-text {
  text-align: center;
  margin-top: 1rem;
}

.toggle-text a {
  color: #4a90e2;
  text-decoration: none;
}

.toggle-text a:hover {
  text-decoration: underline;
}

.divider {
  margin: 40px 0;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.divider::before,
.divider::after {
  content: "";
  position: relative;
  width: 45%;
  height: 1px;
  background-color: #e0e0e0;
  margin: 0 10px;
}

.divider span {
  color: #666;
  font-size: 16px;
  padding: 0 12px;
  background: none;
}

.google-login-button {
  width: 100%;
  padding: 20px 24px;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #333;
  font-size: 20px;
  font-weight: 500;
  margin: 32px 0;
  box-shadow: 0 4px 12px rgba(0,0,0,0.08);
}

.google-login-button:hover {
  background-color: #f8f8f8;
  box-shadow: 0 6px 16px rgba(0,0,0,0.12);
  transform: translateY(-2px);
}

.google-login-button:active {
  transform: translateY(0);
  box-shadow: 0 4px 12px rgba(0,0,0,0.08);
}

.google-login-button:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.google-login-button img {
  width: 28px;
  height: 28px;
}

/* 加载状态下的 Google 按钮样式 */
.google-login-button.loading {
  color: #666;
}

.google-login-button .button-content {
  color: #666;
}

.forgot-password-text {
  text-align: center;
  margin-top: 1rem;
  color: #666;
  font-size: 14px;
  line-height: 1.5;
}

.forgot-password-text a {
  color: #1a73e8;
  text-decoration: none;
  font-weight: 500;
}

.forgot-password-text a:hover {
  text-decoration: underline;
}
