.longterm-container {
  display: flex;
  width: 100%;
  height: calc(100vh - 64px); /* 减去顶部导航栏高度 */
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

.longterm-sidebar {
  min-width: 260px;
  width: 260px;
  height: 100%;
  position: fixed; /* 固定位置 */
  top: 64px; /* 顶部导航栏高度 */
  left: 0;
  overflow-y: auto;
  overflow-x: hidden;
  border-right: 1px solid #e0e0e0;
  background: #fff;
  padding: 24px 0;
  z-index: 100;
  transition: transform 0.3s ease;
}

.longterm-main-content {
  flex: 1;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px;
  margin-left: 260px;
  width: calc(100% - 260px);
  transition: all 0.3s ease;
}

/* 折叠状态样式 */
.longterm-container.collapsed .longterm-sidebar {
  transform: translateX(-260px);
}

.longterm-container.collapsed .longterm-main-content {
  margin-left: 0;
  width: 100%;
}

/* 折叠按钮样式 */
.longterm-sidebar-toggle {
  position: fixed;
  left: 260px;
  top: 84px;
  width: 28px;
  height: 64px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-left: none;
  border-radius: 0 8px 8px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 101;
  transition: all 0.3s ease;
  box-shadow: 2px 0 4px rgba(0, 0, 0, 0.05);
  color: #666;
  font-size: 18px;
}

/* 折叠状态下的按钮样式 */
.longterm-container.collapsed .longterm-sidebar-toggle {
  left: 0;
  border: 1px solid #e0e0e0;
  border-right: none;
  box-shadow: -2px 0 4px rgba(0, 0, 0, 0.05);
}

/* 悬停效果 */
.longterm-sidebar-toggle:hover {
  background: #f8f9fa;
  color: #1a73e8;
  box-shadow: 3px 0 8px rgba(0, 0, 0, 0.1);
}

/* 修改滚动条样式 */
.longterm-sidebar::-webkit-scrollbar,
.longterm-main-content::-webkit-scrollbar {
  width: 6px;
}

.longterm-sidebar::-webkit-scrollbar-thumb,
.longterm-main-content::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 3px;
}

.longterm-sidebar::-webkit-scrollbar-track,
.longterm-main-content::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* 复用 ProductsPage.css 中的侧边栏样式 */
.longterm-sidebar-nav {
  padding: 0;
}

.longterm-nav-item-container {
  margin-bottom: 4px;
}

.longterm-sidebar-item {
  width: 100%;
  text-align: left;
  padding: 12px 20px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 15px;
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.2s ease;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
  letter-spacing: 0.3px;
  position: relative;
}

.longterm-sidebar-item.active {
  background-color: #f0f7ff;
  color: #1a73e8;
  font-weight: 500;
}

.longterm-sidebar-item:hover {
  background-color: #f5f5f5;
  color: #1a73e8;
}

.longterm-sidebar-item.group {
  font-weight: 600;
  font-size: 16px;
  color: #333333;
  background-color: transparent;
}

.longterm-sub-items {
  position: relative;
  margin-left: 0;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  background-color: #fafafa;
}

.longterm-feature-tag {
  display: inline-block;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 10px;
  margin-left: 8px;
  text-transform: uppercase;
}

.longterm-free-tag {
  background-color: #4caf50;
  color: white;
}

.longterm-pro-tag {
  background-color: #ffc107;
  color: #000;
  font-weight: 600;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 10px;
  margin-left: 8px;
}

.longterm-expand-icon {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #f0f0f0;
  transition: all 0.3s ease;
  font-size: 12px;
  color: #666666;
}

.longterm-sidebar-item:hover .longterm-expand-icon {
  background-color: #e0e0e0;
}

.longterm-sidebar-item[aria-expanded="true"] .longterm-expand-icon {
  transform: rotate(180deg);
  background-color: #1a73e8;
  color: white;
}

.longterm-stock-list {
  position: relative;
  margin-left: 20px;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  background-color: #fafafa;
}

.longterm-stock-item {
  padding-left: 40px !important;
  font-size: 14px !important;
}

/* Add transition for sub-items */
.longterm-sub-items {
  position: relative;
  margin-left: 0;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  background-color: #fafafa;
}

/* Add this new style for the PrimePicks title */
.primepicks-title {
  font-weight: 600;
  color: #333333; /* Reset to default color */
}

/* Add a gold indicator or badge to the PrimePicks sidebar item */
.longterm-sidebar-item.primepicks-header {
  position: relative;
  background-color: #fffbeb; /* Subtle gold/yellow background */
  border-top: 1px solid #f0e6c8;
  border-bottom: 1px solid #f0e6c8;
  margin-top: 8px;
  margin-bottom: 0;
}

/* Remove the PRO badge before the title */
.longterm-sidebar-item.primepicks-header::before {
  content: none;
}

/* Style the expanded PrimePicks section */
.primepicks-header[aria-expanded="true"] + .longterm-sub-items {
  background-color: #fffdf5; /* Lighter background for the expanded items */
  border-bottom: 1px solid #f0e6c8;
  margin-bottom: 8px;
}

/* Add a subtle left border to indicate premium content */
.primepicks-header,
.primepicks-header[aria-expanded="true"] + .longterm-sub-items {
  border-left: 3px solid #ffc107;
  padding-left: 17px; /* Adjust padding to account for the border */
}

/* Adjust the hover state */
.longterm-sidebar-item.primepicks-header:hover {
  background-color: #fff8d9;
} 