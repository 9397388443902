/* 添加命名空间以避免与 ProductsPage 样式冲突 */
.overview-component {
  padding: 24px;
  background: #fff;
}

.overview-btc-header {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  padding: 0;
}

.overview-btc-title {
  display: flex;
  align-items: center;
  gap: 16px;
}

.overview-btc-logo { 
  width: 42px;
  height: 42px;
}

.overview-btc-title h1 {
  font-size: 42px;
  color: #1a1a1a;
  font-weight: normal;
  margin: 0;
}

/* 主要指标网格 */
.metrics-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-bottom: 32px;
  background: #fff;
}

.metric-section {
  background: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  transition: transform 0.2s;
}

.metric-section:hover {
  transform: translateY(-2px);
}

.metric-section h3 {
  color: #1a73e8;
  font-size: 20px;
  font-weight: normal;
  text-transform: uppercase;
  margin: 0 0 12px 0;
}

/* 价格部分特殊样式 */
.price-container {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 8px;
}

.price-value {
  font-size: 28px;
  font-weight: normal;
  color: #1a1a1a;
}

.price-change {
  font-size: 20px;
  font-weight: normal;
}

/* 数据网格样式 */
.data-grid {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.data-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.data-label {
  font-size: 20px;
  color: #1a73e8;
  font-weight: normal;
}

.data-value {
  font-size: 28px;
  font-weight: normal;
  color: #1a1a1a;
}

/* 历史数据部分 */
.historical-data {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-top: 32px;
}

.historical-item {
  background: #f8f9fa;
  padding: 16px;
  border-radius: 8px;
}

.historical-label {
  font-size: 20px;
  color: #1a73e8;
  margin-bottom: 8px;
  font-weight: normal;
}

.historical-value {
  font-size: 28px;
  font-weight: normal;
  color: #1a1a1a;
}

.negative {
  color: #d32f2f;
}

.positive {
  color: #2E7D32;
}

/* 统一添加浮动效果 */
.metric-section, .historical-item {
  background: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  transition: transform 0.2s;
}

.metric-section:hover, .historical-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.history-chart-section {
  background: #fff;
  border-radius: 12px;
  padding: 24px;
  margin-top: 24px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.08);
}

.chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.time-range-selector {
  display: flex;
  gap: 8px;
  background: #f8f9fa;
  padding: 4px;
  border-radius: 8px;
}

.range-button {
  padding: 6px 12px;
  border: none;
  background: none;
  border-radius: 6px;
  color: #666;
  cursor: pointer;
  font-size: 13px;
  transition: all 0.2s;
}

.range-button:hover {
  background: rgba(26, 115, 232, 0.1);
  color: #1a73e8;
}

.range-button.active {
  background: #1a73e8;
  color: white;
}

.introduction-section {
  background: #fff;
  border-radius: 12px;
  padding: 0;
  padding-bottom: 24px;
  margin: 24px 0;
  box-shadow: 0 2px 8px rgba(0,0,0,0.08);
  width: 100%;
  box-sizing: border-box;
}

.introduction-content {
  padding: 0 24px;
  color: #333;
  line-height: 1.6;
  font-size: 16px;
}

.introduction-section .fin-section-title-wrapper {
  padding: 0 24px;
  margin: 24px 0;
}

.overview-show-more-button {
  background: #1a73e8;
  border: none;
  color: white;
  cursor: pointer;
  padding: 8px 16px;
  font-size: 15px;
  font-weight: 500;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.overview-show-more-button:hover {
  background: #1557b0;
  text-decoration: none;
}
html {
  scroll-behavior: smooth;
}

/* 为锚点目标添加一些上边距，避免被固定头部遮挡 */
#seasonality {
  scroll-margin-top: 100px; /* 根据你的导航栏高度调整 */
  scroll-snap-margin-top: 100px; /* 兼容性支持 */
}

/* 调整各区块的间距 */
.introduction-section,
.history-chart-section,
.monthly-returns-section {
  margin: 24px 0;
}

/* 添加过渡效果 */
.monthly-returns-section {
  transition: all 0.3s ease;
}
