.monthly-returns-section {
  background: #fff;
  border-radius: 12px;
  padding: 24px;
  margin-top: 24px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.08);
  margin-bottom: 32px;
}

.seasonality-description {
  margin-bottom: 24px;
}

.heatmap-container {
  overflow-x: auto;
  overflow-y: hidden;
  margin-right: -20px;
  padding-right: 20px;
  scrollbar-gutter: stable;
}

.heatmap-table {
  width: 100%;
  min-width: 1200px;
  border-collapse: separate;
  border-spacing: 1px;
  font-size: 14px;
  table-layout: fixed;
}

.heatmap-table th,
.heatmap-table td {
  padding: 12px;
  text-align: center;
  border-radius: 4px;
  transition: all 0.3s ease;
  width: calc(100% / 13);
}

.heatmap-table th {
  background: #f8f9fa;
  font-weight: 500;
  color: #000;
  padding: 16px 12px;
}

.year-cell, .month-header {
  background: #f8f9fa;
  font-weight: 500;
  color: #000;
  position: sticky;
}

.year-cell {
  left: 0;
  z-index: 2;
  width: 80px !important;
  min-width: 80px;
}

.month-header {
  top: 0;
  z-index: 2;
}

.month-number {
  font-size: 11px;
  color: #333;
  margin-top: 4px;
  font-weight: 400;
}

.return-cell {
  font-family: 'Monaco', 'Consolas', monospace;
  position: relative;
  min-width: 80px;
  transition: all 0.3s ease;
}

.return-cell:hover {
  transform: scale(1.05);
  z-index: 4;
  box-shadow: 0 4px 15px rgba(0,0,0,0.2);
}

/* 添加发光效果的基础样式 */
.return-cell::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  pointer-events: none;
  transition: all 0.3s ease;
}

/* 当前年份的单元格悬停效果 */
.return-cell[data-current-year="true"]:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 12px rgba(0,0,0,0.2);
}

.stats-row {
  background-color: #f8f9fa;
}

.stats-row td:first-child {
  font-weight: 500;
  background: #f8f9fa;
  color: #000;
}

/* 美化滚动条 */
.heatmap-container::-webkit-scrollbar {
  height: 8px;
  background-color: #f5f5f5;
}

.heatmap-container::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: 4px;
}

.heatmap-container::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 4px;
}

/* 确保表格容器有足够的外边距，防止和其他元素产生间距问题 */
.monthly-returns-section {
  margin-bottom: 32px;
}
 