.longterm-overview-container {
  padding: 32px;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.06);
}

/* 评级卡片样式 */
.longterm-ratings-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  margin-bottom: 40px;
}

.longterm-rating-card {
  background: #f8f9fa;
  border-radius: 16px;
  padding: 24px;
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.longterm-rating-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
}

.longterm-rating-header {
  margin-bottom: 16px;
}

.longterm-rating-header h3 {
  font-size: 20px;
  color: #1a1a1a;
  margin-bottom: 8px;
  font-weight: 600;
}

.longterm-rating-value {
  font-size: 14px;
  font-weight: 600;
  text-align: left;
}

.longterm-rating-bar-container {
  position: relative;
  height: 6px;
  width: 100%;
  border-radius: 3px;
  overflow: visible;
}

.longterm-rating-bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 3px;
}

.longterm-rating-indicator-wrapper {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease;
}

.longterm-rating-indicator {
  width: 16px;
  height: 16px;
  background: #000;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.longterm-rating-indicator-inner {
  width: 8px;
  height: 8px;
  background: #fff;
  border-radius: 50%;
}

.longterm-rating-card:hover .longterm-rating-indicator {
  transform: scale(1.2);
  transition: transform 0.2s ease;
}

/* 指标卡片样式 */
.longterm-metrics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 24px;
  margin-bottom: 40px;
}

.longterm-metric-card {
  background: #ffffff;
  border-radius: 16px;
  padding: 24px;
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.longterm-metric-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
}

.longterm-metric-label {
  font-size: 14px;
  color: #666666;
  margin-bottom: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.longterm-metric-value {
  font-size: 20px;
  color: #1a1a1a;
  font-weight: 600;
}

.longterm-price-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.longterm-price-change {
  font-size: 14px;
  font-weight: 500;
}

.longterm-price-change.positive {
  color: #34a853;
}

.longterm-price-change.negative {
  color: #ea4335;
}

.longterm-volume-wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 14px;
  color: #666666;
}

/* 公司简介部分 */
.longterm-description-section {
  background: #f8f9fa;
  border-radius: 12px;
  padding: 24px;
  margin-top: 24px;
  transition: all 0.3s ease;
}

.longterm-description-section:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.longterm-description-title {
  font-size: 20px;
  color: #1a1a1a;
  margin-bottom: 16px;
  font-weight: 600;
}

.longterm-description-content {
  max-height: 48px;
  overflow: hidden;
  transition: max-height 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  line-height: 1.6;
  color: #333;
}

.longterm-description-content.longterm-description-expanded {
  max-height: 2000px;
}

.longterm-description-content:not(.longterm-description-expanded)::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 48px;
  background: linear-gradient(to bottom, rgba(248, 249, 250, 0) 0%, rgba(248, 249, 250, 0.9) 50%, rgba(248, 249, 250, 1) 100%);
}

.longterm-description-toggle {
  background: #f8f9fa;
  border: 1px solid #e0e0e0;
  border-radius: 16px;
  color: #1a73e8;
  cursor: pointer;
  font-size: 13px;
  padding: 6px 16px;
  margin-top: 12px;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.longterm-description-toggle:hover {
  background: #f1f3f4;
  border-color: #1a73e8;
  text-decoration: none;
}

.longterm-description-toggle:active {
  background: #e8f0fe;
}

/* 响应式设计 */
@media (max-width: 1024px) {
  .longterm-ratings-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .longterm-ratings-grid {
    grid-template-columns: 1fr;
  }

  .longterm-metrics-grid {
    grid-template-columns: 1fr;
  }
}

.longterm-overview-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  font-size: 18px;
  color: #666666;
}

.longterm-history-chart {
  background: #fff;
  border-radius: 12px;
  padding: 24px;
  margin: 24px 0;
  box-shadow: 0 2px 8px rgba(0,0,0,0.08);
  width: 100%;
  box-sizing: border-box;
}

.longterm-chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.longterm-chart-header h3 {
  color: #1a1a1a;
  font-size: 20px;
  font-weight: 500;
  margin: 0;
}

.longterm-time-range-selector {
  display: flex;
  gap: 8px;
  background: #f8f9fa;
  padding: 4px;
  border-radius: 8px;
}

.longterm-range-button {
  padding: 6px 12px;
  border: none;
  background: none;
  border-radius: 6px;
  color: #666;
  cursor: pointer;
  font-size: 13px;
  transition: all 0.2s;
}

.longterm-range-button:hover {
  background: rgba(26, 115, 232, 0.1);
  color: #1a73e8;
}

.longterm-range-button-active {
  background: #1a73e8;
  color: #fff;
}

.longterm-no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  color: #666;
  font-size: 14px;
}

.longterm-chart-section {
  background: #fff;
  border-radius: 12px;
  padding: 24px;
  margin-top: 24px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.08);
  width: 100%;
  box-sizing: border-box;
}