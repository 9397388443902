.coming-soon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  padding: 24px;
  background: #fff;
}

.coming-soon-content {
  text-align: center;
  max-width: 500px;
  padding: 48px;
  background: linear-gradient(145deg, #ffffff, #f0f0f0);
  border-radius: 24px;
  box-shadow: 
    0 4px 15px rgba(0,0,0,0.1),
    0 8px 30px rgba(26,115,232,0.1);
  transition: transform 0.3s ease;
}

.coming-soon-content:hover {
  transform: translateY(-5px);
}

.coming-soon-icon {
  font-size: 64px;
  margin-bottom: 24px;
}

.coming-soon-content h1 {
  font-size: 42px;
  background: linear-gradient(45deg, #1a73e8, #34a853);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 24px;
}

.launch-badge {
  display: inline-block;
  background: linear-gradient(45deg, #1a73e8, #34a853);
  color: white;
  padding: 12px 24px;
  border-radius: 50px;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: 0 4px 15px rgba(26,115,232,0.3);
}

.coming-soon-content p {
  font-size: 18px;
  color: #666;
  line-height: 1.6;
  margin: 0;
} 