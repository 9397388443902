.etfdetails-risk-container {
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.etfdetails-risk-section {
  background: #fff;
  border-radius: 24px;
  padding: 40px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
}

.etfdetails-risk-title {
  color: #1a1a1a;
  font-size: 28px;
  margin-bottom: 40px;
  font-weight: 600;
}

.etfdetails-risk-content {
  display: flex;
  gap: 64px;
  align-items: flex-start;
}

.etfdetails-risk-gauge {
  position: relative;
  width: 200px;
  margin: 0 auto;
}

.etfdetails-risk-gauge-circle {
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background: #f5f5f5;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.etfdetails-risk-gauge-segments {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  transform: rotate(-90deg);
}

.etfdetails-risk-gauge-segment {
  position: absolute;
  width: 50%;
  height: 50%;
  transform-origin: 100% 100%;
  transition: opacity 0.3s ease;
}

.etfdetails-risk-gauge-segment.segment-A {
  transform: rotate(0deg) skew(30deg);
  background-color: #2E7D32; /* Very Low - Dark Green */
}

.etfdetails-risk-gauge-segment.segment-B {
  transform: rotate(72deg) skew(30deg);
  background-color: #4CAF50; /* Low - Light Green */
}

.etfdetails-risk-gauge-segment.segment-C {
  transform: rotate(144deg) skew(30deg);
  background-color: #FFA726; /* Medium - Orange */
}

.etfdetails-risk-gauge-segment.segment-D {
  transform: rotate(216deg) skew(30deg);
  background-color: #EF5350; /* High - Light Red */
}

.etfdetails-risk-gauge-segment.segment-E {
  transform: rotate(288deg) skew(30deg);
  background-color: #B71C1C; /* Very High - Dark Red */
}

.etfdetails-risk-gauge-needle {
  position: absolute;
  width: 4px;
  height: 50%;
  left: calc(50% - 2px);
  bottom: 50%;
  transform-origin: bottom center;
  transition: transform 0.3s ease;
  z-index: 2;
}

.etfdetails-risk-gauge-center {
  position: absolute;
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 50%;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 3;
}

.etfdetails-risk-gauge-value {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  margin-top: 16px;
  transition: color 0.3s ease;
}

.etfdetails-risk-grade {
  font-size: 18px;
  font-weight: 500;
  color: #1a1a1a;
  margin-top: 16px;
  text-align: center;
}

.etfdetails-risk-metrics {
  flex: 1;
}

.etfdetails-risk-metrics-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
}

.etfdetails-risk-metric-card {
  background: linear-gradient(145deg, #ffffff 0%, #f8f9fa 100%);
  padding: 24px;
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.etfdetails-risk-metric-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
  border-color: rgba(26, 115, 232, 0.1);
}

.etfdetails-risk-metric-label {
  color: #666;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  letter-spacing: 0.3px;
}

.etfdetails-risk-metric-value {
  color: #1a1a1a;
  font-size: 24px;
  font-weight: 600;
  font-variant-numeric: tabular-nums;
  letter-spacing: -0.5px;
}

.etfdetails-risk-loading,
.etfdetails-risk-error {
  text-align: center;
  padding: 48px;
  color: #666;
}

.leverage-highlight {
  color: #d32f2f;
}

@media (max-width: 1024px) {
  .etfdetails-risk-content {
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }
  
  .etfdetails-risk-metrics {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .etfdetails-risk-container {
    padding: 20px;
  }

  .etfdetails-risk-section {
    padding: 24px;
    border-radius: 20px;
  }

  .etfdetails-risk-title {
    font-size: 24px;
    margin-bottom: 32px;
  }

  .etfdetails-risk-metrics-grid {
    grid-template-columns: 1fr;
    gap: 16px;
  }

  .etfdetails-risk-metric-card {
    padding: 20px;
  }
}

/* Note 部分的样式 */
.etfdetails-risk-note-section {
  margin-top: 48px;
  padding: 32px;
  background: #f8f9fa;
  border-radius: 16px;
  border: 1px solid #e0e0e0;
}

.etfdetails-risk-note-title {
  color: #1a1a1a;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 24px;
}

.etfdetails-risk-note-subtitle {
  color: #333;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 16px;
}

.etfdetails-risk-note-text {
  color: #4a4a4a;
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 16px;
}

.etfdetails-risk-note-example {
  background: #fff;
  padding: 24px;
  border-radius: 12px;
  margin: 24px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.etfdetails-risk-note-example-title {
  color: #1a1a1a;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 16px;
}

.etfdetails-risk-note-list {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 16px;
}

.etfdetails-risk-note-list li {
  color: #4a4a4a;
  margin-bottom: 12px;
  padding-left: 24px;
  position: relative;
}

.etfdetails-risk-note-list li:before {
  content: "•";
  position: absolute;
  left: 8px;
  color: #1a73e8;
}

.etfdetails-risk-note-conclusion {
  color: #1a1a1a;
  font-size: 16px;
  line-height: 1.6;
  padding: 16px;
  background: #e8f0fe;
  border-radius: 8px;
  margin-top: 24px;
}

/* Leverage 高亮样式 */
.etfdetails-risk-metric-value.leverage-highlight {
  color: #d32f2f;
  font-weight: 600;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .etfdetails-risk-note-section {
    padding: 24px 16px;
    margin-top: 32px;
  }

  .etfdetails-risk-note-example {
    padding: 16px;
  }
} 