.etf-inflow-component {
  padding: 24px;
  background: #fff;
}

.etf-header {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  padding: 0;
}

.etf-title {
  display: flex;
  align-items: center;
  gap: 16px;
}

.etf-btc-logo {
  width: 42px;
  height: 42px;
}

.etf-title h1 {
  font-size: 42px;
  color: #1a1a1a;
  font-weight: normal;
  margin: 0;
}

.etf-metrics-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 32px;
}

.etf-metric-section {
  background: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  transition: transform 0.2s;
}

.etf-metric-section:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.etf-metric-section h3 {
  color: #1a73e8;
  font-size: 20px;
  font-weight: normal;
  text-transform: uppercase;
  margin: 0 0 12px 0;
}

.etf-data-grid {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.etf-data-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.etf-data-label {
  font-size: 20px;
  color: #1a73e8;
  font-weight: normal;
}

.etf-data-value {
  font-size: 28px;
  font-weight: normal;
  color: #1a1a1a;
}

.etf-price-container {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 8px;
}

.etf-price-value {
  font-size: 28px;
  font-weight: normal;
  color: #1a1a1a;
}

.etf-price-change {
  font-size: 20px;
  font-weight: normal;
}

.etf-price-change.positive {
  color: #2E7D32;
}

.etf-price-change.negative {
  color: #d32f2f;
}

/* 状态样式 */
.etf-loading-state,
.etf-error-state,
.etf-no-data-state {
  padding: 24px;
  text-align: center;
  color: #666;
  font-size: 16px;
}

.etf-content-sections {
  margin-top: 48px;
  padding: 0 24px;
  background: #f8f9fa;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.etf-content-section {
  padding: 24px;
  border-bottom: 1px solid #e0e0e0;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.etf-content-section:last-child {
  border-bottom: none;
}

.etf-content-section:hover {
  background-color: #f5f5f5;
  transform: translateX(8px);
  padding-left: 28px;
}

.etf-section-title {
  color: #1a73e8;
  font-size: 24px;
  margin-bottom: 12px;
  font-weight: 500;
  transition: transform 0.3s ease, color 0.3s ease;
}

.etf-content-section:hover .etf-section-title {
  transform: translateY(-2px);
  color: #1557b0;
}

.etf-section-text {
  color: #333;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 12px;
  transition: transform 0.3s ease;
}

.etf-content-section:hover .etf-section-text {
  transform: translateY(2px);
}

.etf-section-points {
  list-style-type: none;
  padding-left: 20px;
  margin: 16px 0;
  position: relative;
}

.etf-section-points li {
  margin-bottom: 12px;
  line-height: 1.5;
  color: #333;
  position: relative;
  padding-left: 20px;
}

.etf-section-points li strong {
  font-weight: 600;
}

.etf-section-points li span[style*="color: #4CAF50"] {
  color: #4CAF50 !important;
  font-weight: 500;
}

.etf-section-points li span[style*="color: #FF4B4B"] {
  color: #FF4B4B !important;
  font-weight: 500;
}

.etf-section-points li::before {
  content: "•";
  color: #1a73e8;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 20px;
  line-height: 1.5;
}

.etf-section-points li::before {
  content: "•";
  color: #1a73e8;
  position: absolute;
  left: 0;
}

.etf-content-section::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 4px;
  height: 100%;
  background: #1a73e8;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.etf-content-section:hover::after {
  opacity: 1;
}

/* Chart styles */
.etf-chart-section {
  margin-top: 48px;
  background: #fff;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.08);
}

.etf-chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.etf-chart-header h2 {
  font-size: 24px;
  color: #1a1a1a;
  margin: 0;
}

.etf-time-range-selector {
  display: flex;
  gap: 8px;
}

.etf-time-range-button {
  padding: 6px 12px;
  border: 1px solid #ddd;
  background: #fff;
  border-radius: 4px;
  cursor: pointer;
  color: #666;
  font-size: 14px;
  transition: all 0.2s;
}

.etf-time-range-button:hover {
  background: #f5f5f5;
}

.etf-time-range-button.active {
  background: #1a73e8;
  color: #fff;
  border-color: #1a73e8;
}

.etf-chart {
  border-radius: 8px;
  overflow: hidden;
  height: 600px !important;
}