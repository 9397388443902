.sector-recommendation-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.sector-recommendation-section {
  background: #fff;
  border-radius: 24px;
  padding: 32px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.06);
}

.sector-recommendation-section-title {
  color: #1a1a1a;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid #eef0f2;
}

.sector-recommendation-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 48px;
}

.sector-recommendation-meter-container {
  flex: 0 0 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.06);
}

.sector-recommendation-meter-container:hover {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  transform: translateY(-2px);
}

.sector-recommendation-meter {
  width: 380px;
}

.sector-recommendation-meter-label {
  font-size: 28px;
  font-weight: 600;
  color: #1a1a1a;
  margin-top: 16px;
  text-align: center;
}

.sector-recommendation-ratings {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  background: #f8f9fa;
  border-radius: 16px;
  min-width: 420px;
}

.sector-recommendation-rating-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  padding: 12px 16px;
  background: #fff;
  border-radius: 12px;
  transition: all 0.3s ease;
}

.sector-recommendation-rating-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.06);
}

.sector-recommendation-rating-label-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.sector-recommendation-rating-label {
  color: #666;
  font-size: 14px;
  font-weight: 500;
}

.sector-recommendation-rating-container {
  display: flex;
  align-items: center;
  gap: 16px;
}

.sector-recommendation-rating-track {
  width: 200px;
  height: 8px;
  background: #eef0f2;
  border-radius: 4px;
  overflow: hidden;
}

.sector-recommendation-rating-progress {
  height: 100%;
  background: linear-gradient(90deg, #4CAF50 0%, #2E7D32 100%);
  border-radius: 4px;
  transition: width 0.3s ease;
}

.sector-recommendation-rating-value {
  min-width: 40px;
  color: #1a1a1a;
  font-size: 14px;
  font-weight: 600;
  text-align: right;
}

.sector-recommendation-indicators-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 24px;
}

.sector-recommendation-indicator-card {
  background: #fff;
  border-radius: 16px;
  padding: 24px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.04);
  transition: all 0.3s ease;
  position: relative;
  overflow: visible;
}

.sector-recommendation-indicator-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
}

.sector-recommendation-indicator-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
}

.sector-recommendation-indicator-label {
  position: relative;
  color: #666;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.sector-recommendation-indicator-label .tooltip {
  position: relative;
  display: inline-block;
}

.sector-recommendation-indicator-label .tooltip-content {
  position: absolute;
  min-width: 200px;
  max-width: 300px;
  background: rgba(0, 0, 0, 0.85);
  color: #fff;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 12px;
  line-height: 1.5;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 8px;
  white-space: normal;
  pointer-events: none;
  z-index: 1000;
}

.sector-recommendation-indicator-label .tooltip-content::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 4px 4px 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.85) transparent transparent;
}

.sector-recommendation-indicator-value {
  color: #1a1a1a;
  font-size: 24px;
  font-weight: 600;
  font-variant-numeric: tabular-nums;
}

@media (max-width: 1200px) {
  .sector-recommendation-header {
    flex-direction: column;
    gap: 32px;
  }
  
  .sector-recommendation-meter-container,
  .sector-recommendation-ratings {
    width: 100%;
    min-width: unset;
  }
  
  .sector-recommendation-indicators-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .sector-recommendation-section {
    padding: 20px;
  }
  
  .sector-recommendation-indicators-grid {
    grid-template-columns: 1fr;
  }
  
  .sector-recommendation-meter {
    width: 100%;
  }
} 