.etf-vp-container {
  padding: 32px;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.06);
}

.etf-vp-rating-section {
  margin: 32px 0;
  padding: 0 12px;
}

.etf-vp-rating {
  max-width: 600px;
  margin: 0 auto;
}

.etf-vp-rating-bar-wrapper {
  width: 100%;
}

.etf-vp-rating-bar {
  width: 100%;
  height: 6px;
  background: linear-gradient(to right,
    #2e7d32 0%,
    #4caf50 25%,
    #ff9800 50%,
    #ef5350 75%,
    #d32f2f 100%
  );
  border-radius: 8px;
  position: relative;
  margin-bottom: 12px;
}

.etf-vp-rating-marker {
  position: absolute;
  top: 50%;
  width: 20px;
  height: 20px;
  background: #fff;
  border: 3px solid #1a1a1a;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.etf-vp-rating-labels {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #666;
  font-weight: 500;
}

.etf-vp-rating-value {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  color: #1a1a1a;
  margin-top: 24px;
}

.etf-vp-metrics-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin-top: 32px;
}

.etf-vp-metric-card {
  background: linear-gradient(145deg, #ffffff 0%, #f8f9fa 100%);
  padding: 24px;
  border-radius: 16px;
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.etf-vp-metric-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
  border-color: rgba(26, 115, 232, 0.1);
}

.etf-vp-metric-header {
  margin-bottom: 16px;
}

.etf-vp-metric-label {
  font-size: 18px;
  color: #666;
  font-weight: 500;
  letter-spacing: 0.2px;
}

.etf-vp-metric-value {
  font-size: 28px;
  color: #1a1a1a;
  font-weight: 600;
  font-variant-numeric: tabular-nums;
  letter-spacing: -0.5px;
}

.etf-vp-loading {
  text-align: center;
  padding: 48px;
  color: #666;
  font-size: 16px;
}

.etf-vp-rating-gauge {
  position: relative;
  width: 200px;
  height: 200px;
  margin: 0 auto;
}

.etf-vp-rating-circle {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #f5f5f5;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.etf-vp-rating-segments {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  transform: rotate(-90deg);
}

.etf-vp-rating-segment {
  position: absolute;
  width: 50%;
  height: 50%;
  transform-origin: 100% 100%;
  transition: opacity 0.3s ease;
}

.etf-vp-rating-segment.segment-A {
  transform: rotate(0deg) skew(30deg);
  background-color: #2E7D32; /* Very Low - Dark Green */
}

.etf-vp-rating-segment.segment-B {
  transform: rotate(72deg) skew(30deg);
  background-color: #4CAF50; /* Low - Light Green */
}

.etf-vp-rating-segment.segment-C {
  transform: rotate(144deg) skew(30deg);
  background-color: #FFA726; /* Medium - Orange */
}

.etf-vp-rating-segment.segment-D {
  transform: rotate(216deg) skew(30deg);
  background-color: #EF5350; /* High - Light Red */
}

.etf-vp-rating-segment.segment-E {
  transform: rotate(288deg) skew(30deg);
  background-color: #B71C1C; /* Very High - Darker Red */
}

.etf-vp-rating-needle {
  position: absolute;
  width: 4px;
  height: 50%;
  left: calc(50% - 2px);
  bottom: 50%;
  transform-origin: bottom center;
  transition: transform 0.3s ease;
  z-index: 2;
}

.etf-vp-rating-needle.animate {
  animation: spinNeedle 1.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.etf-vp-rating-center {
  position: absolute;
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 50%;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 3;
}

.etf-vp-rating-labels {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  padding: 0 16px;
}

.etf-vp-rating-labels span {
  font-size: 14px;
  color: #666;
  transition: color 0.3s ease;
}

.etf-vp-rating-labels span.active {
  color: #1a1a1a;
  font-weight: 600;
}

.etf-vp-rating-value {
  text-align: center;
  font-size: 28px;
  font-weight: 600;
  margin-top: 16px;
  transition: color 0.3s ease;
}

@media (max-width: 768px) {
  .etf-vp-metrics-grid {
    grid-template-columns: 1fr;
  }
  
  .etf-vp-container {
    padding: 24px;
  }
  
  .etf-vp-rating-section {
    margin: 24px 0;
    padding: 0;
  }
}

.etf-vp-charts-container {
  margin-top: 40px;
} 