.about-page {
  background: linear-gradient(135deg, #ffffff 0%, #f8f9fa 100%);
  min-height: calc(100vh - 80px);
  color: #333;
  font-family: 'Poppins', sans-serif;
  padding: 40px 20px;
}

.about-content-wrapper {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
}

.about-layout {
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 40px;
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  position: relative;
}

.about-layout::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(90deg, #4a90e2, #67b246, #4a90e2);
  background-size: 200% 100%;
  animation: gradient 15s ease infinite;
}

.about-categories {
  background: linear-gradient(135deg, #f8f9fa 0%, #ffffff 100%);
  padding: 20px 0;
  border-right: 1px solid rgba(74, 144, 226, 0.1);
}

.about-category-item {
  padding: 15px 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #333;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  font-size: 1.15em;
}

.about-category-item:hover {
  background: rgba(74, 144, 226, 0.05);
  color: #4a90e2;
}

.about-category-item.active {
  background: #ffffff;
  color: #4a90e2;
  border-left: 4px solid #4a90e2;
  font-weight: 600;
  font-size: 1.2em;
}

.about-main-content {
  padding: 30px;
}

.video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
}

.background-video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translateX(-50%) translateY(-50%);
  object-fit: cover;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(102, 126, 234, 0.7);
}

h1 {
  font-size: 2.5em;
  margin-bottom: 30px;
  color: #4a90e2;
  text-align: center;
}

.section {
  background: #f8f9fa;
  border-radius: 15px;
  padding: 30px;
  margin-bottom: 40px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

h2 {
  font-size: 1.8em;
  margin-bottom: 20px;
  color: #4a90e2;
}

p {
  font-size: 1em;
  line-height: 1.6;
  color: #666;
}

.team-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.team-member {
  text-align: center;
  margin: 20px;
  transition: transform 0.3s ease;
}

.team-member:hover {
  transform: translateY(-5px);
}

.team-member img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #4a90e2;
  margin-bottom: 10px;
}

.team-member h3 {
  font-size: 1.2em;
  margin-bottom: 5px;
  color: #333;
}

.team-member p {
  font-size: 0.9em;
  color: #666;
}

@media (max-width: 768px) {
  .team-container {
    flex-direction: column;
    align-items: center;
  }
}

.mission-section, .services-section, .why-choose-section {
  background: linear-gradient(135deg, #ffffff 0%, #f8f9fa 100%);
  padding: 25px 30px;
  border-radius: 15px;
  margin: 15px auto;
  position: relative;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.mission-section:hover, .services-section:hover, .why-choose-section:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.1);
}

.mission-section::before, .services-section::before, .why-choose-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(90deg, #4a90e2, #67b246, #4a90e2);
  background-size: 200% 100%;
  animation: gradient 15s ease infinite;
}

.about-section-title {
  font-size: 2em;
  color: #333;
  margin-bottom: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  padding-left: 15px;
}

.about-section-title::before {
  content: '';
  position: absolute;
  left: 0;
  height: 100%;
  width: 4px;
  background: #4a90e2;
  border-radius: 2px;
}

.section-content {
  font-size: 1.1em;
  line-height: 1.8;
  color: #666;
  margin-bottom: 15px;
  padding: 0;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 20px;
}

.service-card {
  background: white;
  padding: 25px;
  border-radius: 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid rgba(74, 144, 226, 0.1);
  position: relative;
  overflow: hidden;
}

.service-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(90deg, #4a90e2, #67b246);
  opacity: 0.7;
}

.service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.1);
}

.service-title {
  font-size: 1.4em;
  color: #333;
  margin-bottom: 15px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 10px;
}

.service-list {
  list-style-type: none;
  padding: 0;
}

.service-list li {
  margin-bottom: 12px;
  padding-left: 25px;
  position: relative;
  color: #666;
  line-height: 1.6;
}

.service-list li::before {
  content: "→";
  position: absolute;
  left: 0;
  color: #4a90e2;
  font-weight: bold;
}

@media (max-width: 1200px) {
  .services-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .services-grid {
    grid-template-columns: 1fr;
  }
  
  .content {
    padding: 0 15px;
  }
}

.mission-section, .services-section, .why-choose-section {
  position: relative;
}

.section-decorator {
  position: absolute;
  right: -20px;
  top: -20px;
  width: 100px;
  height: 100px;
  background: linear-gradient(135deg, #4a90e2 0%, #67b246 100%);
  opacity: 0.05;
  border-radius: 50%;
  transform: rotate(45deg);
}

.disclaimer-privacy-section {
  background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
  padding: 40px 0;
  margin-top: 30px;
  position: relative;
  overflow: hidden;
}

.disclaimer-privacy-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(90deg, #4a90e2, #67b246, #4a90e2);
  background-size: 200% 100%;
  animation: gradient 15s ease infinite;
}

.disclaimer-privacy-container {
  max-width: 1200px;
  gap: 30px;
  padding: 0 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.disclaimer-box, .privacy-box {
  background: white;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.disclaimer-box:hover, .privacy-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.1);
}

.disclaimer-box::before, .privacy-box::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: #4a90e2;
  opacity: 0.7;
}

.info-icon {
  font-size: 2em;
  color: #4a90e2;
  margin-bottom: 20px;
}

.fancy-title {
  color: #333;
  font-size: 1.8em;
  margin-bottom: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 10px;
}

.fancy-content {
  color: #666;
  line-height: 1.8;
  font-size: 1.1em;
}

/* Animation for gradient border */
@keyframes gradient {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

/* Responsive design */
@media (max-width: 768px) {
  .disclaimer-privacy-container {
    grid-template-columns: 1fr;
  }
}

/* Add some decorative elements */
.decorative-shape {
  position: absolute;
  opacity: 0.1;
  pointer-events: none;
}

.shape-1 {
  top: 20px;
  right: 20px;
  width: 100px;
  height: 100px;
  background: #4a90e2;
  border-radius: 50%;
  transform: rotate(45deg);
}

.shape-2 {
  bottom: 20px;
  left: 20px;
  width: 80px;
  height: 80px;
  background: #67b246;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}

html {
  scroll-behavior: smooth;
}

#privacy-section {
  scroll-margin-top: 80px; /* 调整滚动位置，避免被导航栏遮挡 */
}

.contact-section {
  background: linear-gradient(135deg, #ffffff 0%, #f8f9fa 100%);
  padding: 25px 30px;
  border-radius: 15px;
  margin: 15px auto;
  position: relative;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.contact-section:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.1);
}

.contact-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(90deg, #4a90e2, #67b246, #4a90e2);
  background-size: 200% 100%;
  animation: gradient 15s ease infinite;
}

.contact-content {
  text-align: center;
  padding: 20px 0;
}

.email-link {
  display: inline-block;
  font-size: 1.4em;
  color: #4a90e2;
  text-decoration: none;
  font-weight: 600;
  margin-top: 10px;
  padding: 10px 20px;
  border: 2px solid #4a90e2;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.email-link:hover {
  background: #4a90e2;
  color: white;
  transform: translateY(-2px);
}

.mission-section {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.content-block {
  background: linear-gradient(135deg, #ffffff 0%, #f8f9fa 100%);
  padding: 25px 30px;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
}

.content-block:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.1);
}

.highlight {
  color: #4a90e2;
  font-weight: 500;
}

.about-section-title {
  font-size: 1.8em;
  color: #333;
  margin-bottom: 20px;
  font-weight: 600;
  position: relative;
  padding-left: 15px;
}

.about-section-title::before {
  content: '';
  position: absolute;
  left: 0;
  height: 100%;
  width: 4px;
  background: #4a90e2;
  border-radius: 2px;
}
