.longterm-premium-metrics {
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.longterm-premium-metrics-section {
  background: #ffffff;
  border-radius: 20px;
  padding: 32px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  transition: transform 0.3s ease;
}

.longterm-premium-metrics-section:hover {
  transform: translateY(-4px);
}

.longterm-premium-metrics-title {
  font-size: 22px;
  font-weight: 700;
  color: #1e293b;
  margin: 0 0 28px 0;
  letter-spacing: -0.02em;
  position: relative;
  padding-left: 16px;
}

.longterm-premium-metrics-title::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 4px;
  height: 24px;
  background: linear-gradient(180deg, #3b82f6 0%, #1d4ed8 100%);
  border-radius: 2px;
}

.longterm-premium-metrics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 24px;
}

.longterm-premium-metric-card {
  background: #f8fafc;
  border-radius: 16px;
  padding: 24px;
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.04);
}

.longterm-premium-metric-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
  background: #ffffff;
}

.longterm-premium-metric-label {
  font-size: 15px;
  font-weight: 500;
  color: #64748b;
  margin-bottom: 12px;
}

.longterm-premium-metric-value {
  font-size: 28px;
  font-weight: 700;
  color: #1e293b;
}

@media (max-width: 768px) {
  .longterm-premium-metrics {
    margin-top: 32px;
    gap: 24px;
  }

  .longterm-premium-metrics-section {
    padding: 24px;
  }
} 