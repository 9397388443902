.stock-detail-container {
    padding: 24px;
  }
  
  .stock-detail-header {
    margin-bottom: 40px;
  }
  
  .stock-detail-title {
    font-size: 42px;
    margin: 0 0 24px 0;
    color: #1a1a1a;
    font-weight: normal;
    display: flex;
    align-items: center;
    gap: 16px;
  }
  
  .stock-detail-price-container {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  
  .stock-detail-price {
    font-size: 24px;
    font-weight: 500;
  }
  
  .stock-detail-change {
    font-size: 20px;
    font-weight: 500;
  }
  
  .stock-detail-positive {
    color: #388E3C;
  }
  
  .stock-detail-negative {
    color: #D32F2F;
  }
  
  .stock-detail-tab-navigation {
    display: flex;
    gap: 8px;
    margin-bottom: 32px;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 0;
  }
  
  .stock-detail-tab-button {
    padding: 12px 24px;
    border: none;
    background: none;
    color: #666;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    position: relative;
    transition: all 0.2s;
  }
  
  .stock-detail-tab-button:hover {
    color: #1a73e8;
  }
  
  .stock-detail-tab-button.stock-detail-tab-active {
    color: #1a73e8;
  }
  
  .stock-detail-tab-button.stock-detail-tab-active::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 3px;
    background-color: #1a73e8;
  }
  
  .stock-detail-metrics-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
    padding: 0 24px;
    margin-bottom: 32px;
  }
  
  .stock-detail-metric-card {
    background: #f8f9fa;
    padding: 24px;
    border-radius: 8px;
    transition: transform 0.2s;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .stock-detail-metric-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  }
  
  .stock-detail-metric-title {
    color: #1a73e8;
    font-size: 24px;
    font-weight: 500;
    margin: 0 0 20px 0;
    text-align: center;
    width: 100%;
  }
  
  .stock-detail-metric-value {
    font-size: 28px;
    font-weight: normal;
    color: #1a1a1a;
  }
  
  .stock-detail-grade-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    width: 100%;
    min-height: 120px;
    justify-content: center;
  }
  
  .stock-detail-horizontal-indicator {
    width: 120px;
    height: 8px;
    margin: 32px auto 16px;
    position: relative;
    background: linear-gradient(to right, #D32F2F, #FDD835, #388E3C);
    border-radius: 4px;
  }
  
  .stock-detail-marker {
    position: absolute;
    top: -4px;
    width: 16px;
    height: 16px;
    background: #fff;
    border: 3px solid #333;
    border-radius: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }
  
  .stock-detail-stacked-bars {
    display: flex;
    flex-direction: column-reverse;
    gap: 4px;
    height: 80px;
    width: 40px;
    margin: 16px auto;
  }
  
  .stock-detail-bar {
    width: 32px;
    height: 6px;
    border-radius: 2px;
  }
  
  .stock-detail-grade-description {
    font-size: 20px;
    color: #333;
    margin-top: 16px;
    text-align: center;
  }
  
  .stock-detail-loading,
  .stock-detail-no-data {
    padding: 24px;
    text-align: center;
    color: #666;
  }
  
  .stock-detail-error {
    padding: 24px;
    text-align: center;
    color: #D32F2F;
    background-color: #FFEBEE;
    border-radius: 4px;
    margin: 24px;
  }
  
  .stock-detail-price-section {
    margin-bottom: 24px;
    padding: 16px;
    background-color: #f8f9fa;
    border-radius: 8px;
  }
  
  .stock-detail-price-info {
    display: flex;
    align-items: baseline;
    gap: 12px;
  }
  
  .stock-detail-current-price {
    font-size: 32px;
    font-weight: 600;
    color: #1a1a1a;
  }
  
  .stock-detail-return {
    font-size: 20px;
    font-weight: 500;
    padding: 4px 8px;
    border-radius: 4px;
  }
  
  .stock-detail-return.positive {
    color: #388E3C;
    background-color: rgba(56, 142, 60, 0.1);
  }
  
  .stock-detail-return.negative {
    color: #D32F2F;
    background-color: rgba(211, 47, 47, 0.1);
  }
  
  .stock-detail-section {
    padding: 24px;
    border-bottom: 1px solid #e0e0e0;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
  }
  
  .stock-detail-section:hover {
    background-color: #f5f5f5;
    transform: translateX(8px);
    padding-left: 28px;
  }
  
  .stock-detail-section h3 {
    color: #1a73e8;
    font-size: 24px;
    margin-bottom: 12px;
    font-weight: 500;
  }
  
  .stock-detail-section p {
    color: #333;
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 12px;
  }
  
  .stock-detail-basic-info {
    margin-top: 24px;
    padding: 24px;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .stock-detail-metric-row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
    margin-bottom: 32px;
  }
  
  .stock-detail-metric {
    padding: 20px;
    background: #f8f9fa;
    border-radius: 8px;
    text-align: center;
    transition: all 0.3s ease;
  }
  
  .stock-detail-metric:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .stock-detail-metric h3 {
    font-size: 20px;
    color: #1a73e8;
    margin: 0 0 16px 0;
    text-transform: uppercase;
    font-weight: normal;
  }
  
  .stock-detail-value {
    font-size: 18px;
    color: #333;
    line-height: 1.6;
  }
  
  .stock-detail-volume {
    font-size: 18px;
    color: #333;
    line-height: 1.6;
  }
  
  /* Key Aspects 样式 */
  .stock-detail-key-aspects {
    margin-top: 32px;
    padding: 24px;
    background: #f8f9fa;
    border-radius: 8px;
  }
  
  .stock-detail-key-aspects h3 {
    font-size: 24px;
    color: #1a73e8;
    margin: 0 0 16px 0;
    font-weight: 500;
  }
  
  .stock-detail-key-aspects ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .stock-detail-key-aspects li {
    font-size: 18px;
    color: #333;
    line-height: 1.6;
    padding: 12px 0;
    border-bottom: 1px solid #e0e0e0;
    position: relative;
    padding-left: 24px;
  }
  
  .stock-detail-key-aspects li:last-child {
    border-bottom: none;
  }
  
  .stock-detail-key-aspects li::before {
    content: "•";
    color: #1a73e8;
    font-size: 24px;
    position: absolute;
    left: 0;
    top: 8px;
  }
  
  .stock-detail-section-title {
    color: #1a73e8;
    font-size: 24px;
    margin: 32px 0 16px;
  }
  
  .stock-detail-key-aspects ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .stock-detail-key-aspects li {
    margin-bottom: 16px;
    line-height: 1.6;
  }
  
  .stock-detail-key-aspects li strong {
    color: #1a73e8;
    font-weight: 600;
  }
  
  .stock-detail-introduction h3 {
    font-size: 20px;
    color: #1a73e8;
    margin: 0 0 16px 0;
    text-transform: uppercase;
  }
  
  .stock-detail-introduction p {
    font-size: 18px;
    line-height: 1.6;
    color: #333;
  }
  
  .stock-detail-price-info {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
  
  .stock-detail-price-chart {
    margin: 32px 0;
    padding: 24px;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .stock-detail-price-chart .chart-title {
    font-size: 20px;
    color: #1a73e8;
    margin: 0 0 24px 0;
    font-weight: 500;
  }
  
  .stock-detail-price-chart .chart-container {
    background: #fff;
    padding: 16px;
    border-radius: 8px;
  }
  
  /* 自定义 tooltip 样式 */
  .recharts-tooltip-wrapper .recharts-default-tooltip {
    background-color: rgba(255, 255, 255, 0.95) !important;
    border: 1px solid #ddd !important;
    border-radius: 4px;
    padding: 8px 12px !important;
  }
  
  /* 历史价格图表部分样式 */
  .stock-detail-history-chart {
    background: #fff;
    border-radius: 12px;
    padding: 24px;
    margin: 24px 0;
    box-shadow: 0 2px 8px rgba(0,0,0,0.08);
    width: 100%;
    box-sizing: border-box;
  }
  
  .stock-detail-history-chart .chart-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }
  
  .stock-detail-history-chart .chart-header h3 {
    color: #1a1a1a;
    font-size: 20px;
    font-weight: 500;
    margin: 0;
  }
  
  .stock-detail-history-chart .time-range-selector {
    display: flex;
    gap: 8px;
    background: #f8f9fa;
    padding: 4px;
    border-radius: 8px;
  }
  
  .stock-detail-history-chart .range-button {
    padding: 6px 12px;
    border: none;
    background: none;
    border-radius: 6px;
    color: #666;
    cursor: pointer;
    font-size: 13px;
    transition: all 0.2s;
  }
  
  .stock-detail-history-chart .range-button:hover {
    background: rgba(26, 115, 232, 0.1);
    color: #1a73e8;
  }
  
  .stock-detail-history-chart .range-button.active {
    background: #1a73e8;
    color: white;
  }
  
  /* 图表容器样式 */
  .stock-detail-history-chart .chart-container {
    height: 400px;
    position: relative;
  }
  
  /* 加载和错误状态样式 */
  .stock-detail-loading,
  .stock-detail-error,
  .stock-detail-no-data {
    text-align: center;
    padding: 40px;
    color: #666;
    font-size: 16px;
  }
  
  /* Tooltip 自定义样式 */
  .echarts-tooltip-custom {
    background: rgba(255, 255, 255, 0.95) !important;
    border: 1px solid #eee !important;
    border-radius: 4px;
    padding: 10px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.15);
  }
  
  /* 数据缩放控件样式 */
  .stock-detail-history-chart :global(.echarts-datazoom) {
    background-color: #f8f9fa;
    border-radius: 4px;
    border: 1px solid #eee;
  }
  
  .stock-detail-history-chart :global(.echarts-datazoom-handle) {
    border-color: #1a73e8;
    background-color: #1a73e8;
  }
  
  /* 确保图表区域内的文字样式统一 */
  .stock-detail-history-chart :global(.echarts-label) {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial;
    font-size: 12px;
    color: #666;
  }
  
  /* 添加 BCI 图表相关样式 */
  .stock-bci-charts-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 24px;
  }
  
  .stock-bci-chart {
    background: #fff;
    border-radius: 12px;
    padding: 24px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.08);
  }