.longterm-profitability-metrics {
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.longterm-profitability-metrics-section {
  background: #ffffff;
  border-radius: 20px;
  padding: 32px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  transition: transform 0.3s ease;
}

.longterm-profitability-metrics-section:hover {
  transform: translateY(-4px);
}

.longterm-profitability-metrics-title {
  font-size: 22px;
  font-weight: 700;
  color: #1e293b;
  margin: 0 0 28px 0;
  letter-spacing: -0.02em;
  position: relative;
  padding-left: 16px;
}

.longterm-profitability-metrics-title::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 4px;
  height: 24px;
  background: linear-gradient(180deg, #3b82f6 0%, #1d4ed8 100%);
  border-radius: 2px;
}

.longterm-profitability-metrics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 24px;
}

.longterm-profitability-metric-card {
  background: #f8fafc;
  border-radius: 16px;
  padding: 24px;
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.04);
  position: relative;
  overflow: visible;
}

.longterm-profitability-metric-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 3px;
  background: linear-gradient(90deg, #3b82f6 0%, #1d4ed8 100%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.longterm-profitability-metric-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
  background: #ffffff;
}

.longterm-profitability-metric-card:hover::before {
  opacity: 1;
}

.longterm-profitability-metric-label {
  font-size: 15px;
  font-weight: 500;
  color: #64748b;
  margin-bottom: 12px;
  letter-spacing: -0.01em;
  display: flex;
  align-items: center;
  gap: 6px;
}

/* 添加 tooltip 相关样式 */
.longterm-profitability-metric-label .tooltip-container {
  position: relative;
  z-index: 10;
}

.longterm-profitability-metric-label .tooltip-content {
  min-width: 280px;
  max-width: 320px;
}

.longterm-profitability-metric-value {
  font-size: 28px;
  font-weight: 700;
  background: linear-gradient(135deg, #1e293b 0%, #334155 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
  letter-spacing: -0.02em;
}

/* 为不同类型的指标添加特定样式 */
.longterm-profitability-metric-card[data-type="percentage"] .longterm-profitability-metric-value {
  color: #0f766e;
}

.longterm-profitability-metric-card[data-type="currency"] .longterm-profitability-metric-value {
  color: #0369a1;
}

/* 响应式设计优化 */
@media (max-width: 1024px) {
  .longterm-profitability-metrics-grid {
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  }
}

@media (max-width: 768px) {
  .longterm-profitability-metrics {
    margin-top: 32px;
    gap: 24px;
  }

  .longterm-profitability-metrics-section {
    padding: 24px;
  }

  .longterm-profitability-metrics-title {
    font-size: 20px;
    margin-bottom: 24px;
  }

  .longterm-profitability-metrics-grid {
    grid-template-columns: 1fr;
    gap: 16px;
  }

  .longterm-profitability-metric-card {
    padding: 20px;
  }

  .longterm-profitability-metric-value {
    font-size: 24px;
  }

  .longterm-profitability-metric-label .tooltip-content {
    min-width: 240px;
    max-width: 280px;
  }
} 