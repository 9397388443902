.fgi-container {
  padding: 24px;
  background: #fff;
}

.fgi-header {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  padding: 0;
}

.fgi-title {
  display: flex;
  align-items: center;
  gap: 16px;
}

.fgi-btc-logo {
  width: 42px;
  height: 42px;
}

.fgi-title h1 {
  font-size: 42px;
  color: #1a1a1a;
  font-weight: normal;
  margin: 0;
}

.fgi-content {
  display: flex;
  gap: 32px;
  margin-bottom: 48px;
}

.fgi-meter-section {
  flex: 1;
  min-width: 380px;
}

.fgi-stats-section {
  flex: 1;
  background: #f8f9fa;
  border-radius: 12px;
  padding: 24px;
}

.fgi-stats-header {
  margin-bottom: 24px;
}

.fgi-time-range-selector {
  display: flex;
  gap: 8px;
  background: #f8f9fa;
  padding: 4px;
  border-radius: 8px;
  justify-content: center;
}

.fgi-range-button {
  padding: 8px 16px;
  border: none;
  background: none;
  border-radius: 6px;
  color: #666;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s;
}

.fgi-range-button:hover {
  background: rgba(26, 115, 232, 0.1);
  color: #1a73e8;
}

.fgi-range-button.active {
  background: #1a73e8;
  color: #fff;
}

.fgi-stats-table {
  width: 100%;
}

.fgi-stats-table table {
  width: 100%;
  border-collapse: collapse;
}

.fgi-stats-table th,
.fgi-stats-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

.fgi-stats-table th {
  font-weight: 500;
  color: #666;
}

.fgi-stats-table td {
  color: #1a1a1a;
}

.fgi-chart-section {
  background: #fff;
  border-radius: 12px;
  padding: 24px;
  margin-top: 32px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.08);
  margin: 40px 0;
}

.fgi-chart-section h2 {
  font-size: 24px;
  color: #1a1a1a;
  margin-bottom: 24px;
}

.fgi-chart {
  border-radius: 8px;
  overflow: hidden;
  margin-top: 24px;
}

.fgi-loading,
.fgi-error {
  padding: 24px;
  text-align: center;
  color: #666;
  font-size: 16px;
}

.fgi-chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.fgi-chart-time-range {
  display: flex;
  gap: 8px;
}

.fgi-chart-range-button {
  padding: 6px 12px;
  border: 1px solid #ddd;
  background: #fff;
  border-radius: 4px;
  cursor: pointer;
  color: #666;
  font-size: 14px;
  transition: all 0.2s;
}

.fgi-chart-range-button:hover {
  background: #f5f5f5;
}

.fgi-chart-range-button.active {
  background: #1a73e8;
  color: #fff;
  border-color: #1a73e8;
}

.fgi-explanation {
  margin-top: 48px;
  padding: 24px;
  background: #fff;
  border-radius: 12px;
}

.fgi-section {
  margin-bottom: 48px;
}

.fgi-section h2 {
  font-size: 32px;
  color: #1a1a1a;
  margin-bottom: 28px;
  font-weight: 500;
}

.fgi-section h3 {
  font-size: 22px;
  color: #1a73e8;
  margin-bottom: 16px;
  font-weight: 500;
}

.fgi-section p {
  font-size: 18px;
  line-height: 1.7;
  color: #333;
  margin-bottom: 20px;
}

.fgi-highlight-list {
  list-style: none;
  padding: 0;
  margin: 16px 0;
}

.fgi-highlight-list li {
  font-size: 18px;
  line-height: 1.7;
  margin-bottom: 12px;
  padding-left: 28px;
  position: relative;
}

.fgi-highlight-list li::before {
  content: "•";
  color: #1a73e8;
  position: absolute;
  left: 8px;
}

.fgi-components {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 24px;
  margin: 24px 0;
}

.fgi-component {
  background: #f8f9fa;
  padding: 24px;
  border-radius: 8px;
  transition: transform 0.2s;
}

.fgi-component:hover {
  transform: translateY(-2px);
}

.fgi-trading-points {
  list-style: none;
  padding: 0;
  margin: 16px 0;
}

.fgi-trading-points li {
  font-size: 18px;
  line-height: 1.7;
  margin-bottom: 16px;
  padding-left: 28px;
  position: relative;
}

.fgi-trading-points li::before {
  content: "→";
  color: #1a73e8;
  position: absolute;
  left: 0;
}

.fgi-highlight {
  background: #e3f2fd;
  padding: 20px;
  border-radius: 8px;
  color: #1a73e8;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.7;
}

.fgi-component p {
  margin-bottom: 0;
} 