.stock-bci-container {
  padding: 32px;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.stock-bci-header {
  margin-bottom: 32px;
}

.stock-bci-title {
  font-size: 28px;
  color: #1a73e8;
  margin: 0 0 12px 0;
  font-weight: 500;
}

.stock-bci-description {
  color: #666;
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
}

.stock-bci-metrics-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  margin-bottom: 32px;
}

.stock-bci-metric-card {
  background: #f8f9fa;
  padding: 24px;
  border-radius: 12px;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-height: 120px;
}

.stock-bci-metric-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  background: #fff;
}

.stock-bci-metric-header {
  display: flex;
  align-items: center;
  gap: 8px;
}

.stock-bci-metric-label {
  color: #1a73e8;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.3px;
}

.stock-bci-metric-value {
  color: #1a1a1a;
  font-size: 28px;
  font-weight: 600;
  font-variant-numeric: tabular-nums;
}

.stock-bci-loading {
  text-align: center;
  padding: 48px;
  color: #666;
  font-size: 16px;
  background: #f8f9fa;
  border-radius: 12px;
}

@media (max-width: 768px) {
  .stock-bci-metrics-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .stock-bci-metrics-grid {
    grid-template-columns: 1fr;
  }
}

.stock-bci-indicator {
  background: #f8f9fa;
  padding: 24px;
  border-radius: 12px;
  margin-bottom: 32px;
}

.stock-bci-indicator-title {
  color: #1a73e8;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 12px;
}

.stock-bci-indicator-value {
  font-size: 24px;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 8px;
}

.stock-bci-indicator-bar {
  height: 8px;
  background: #e8eaed;
  border-radius: 4px;
  position: relative;
  margin-top: 12px;
}

.stock-bci-indicator-progress {
  position: absolute;
  height: 100%;
  left: 0;
  border-radius: 4px;
  background: linear-gradient(90deg, #fbbc05 0%, #34a853 100%);
  transition: width 0.3s ease;
}

.stock-bci-indicator-marker {
  position: absolute;
  width: 12px;
  height: 12px;
  background: #1a73e8;
  border-radius: 50%;
  top: -2px;
  transform: translateX(-50%);
  border: 2px solid #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.stock-bci-horizontal-indicator {
  width: 120px;
  height: 8px;
  margin: 16px auto;
  position: relative;
  background: linear-gradient(to right, #D32F2F, #FDD835, #388E3C);
  border-radius: 4px;
}

.stock-bci-marker {
  position: absolute;
  top: -4px;
  width: 16px;
  height: 16px;
  background: #fff;
  border: 3px solid #1a73e8;
  border-radius: 50%;
  transform: translateX(-50%);
  z-index: 1;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.stock-bci-value {
  font-size: 24px;
  font-weight: 600;
  color: #1a1a1a;
  text-align: center;
  margin: 12px 0;
}

.bci-indicator {
  width: 100%;
  max-width: 320px;
  margin: 0 auto 24px;
  text-align: center;
}

.stock-bci-indicator-bar {
  width: 120px;
  height: 8px;
  margin: 16px auto;
  position: relative;
  background: linear-gradient(to right, #fbbc05, #7cb342, #34a853);
  border-radius: 4px;
}

.stock-bci-marker {
  position: absolute;
  top: -4px;
  width: 16px;
  height: 16px;
  background: #fff;
  border: 3px solid #1a73e8;
  border-radius: 50%;
  transform: translateX(-50%);
  z-index: 1;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.stock-bci-status-text {
  margin-top: 12px;
  color: #666;
  font-size: 14px;
}

.stock-bci-value {
  font-size: 24px;
  font-weight: 600;
  color: #1a1a1a;
  margin: 12px 0;
}

.stock-bci-grade {
  font-size: 36px;
  font-weight: 600;
  color: #1a73e8;
  margin: 16px 0 8px;
}

.stock-bci-description {
  font-size: 18px;
  color: #666;
  margin-bottom: 16px;
  text-transform: capitalize;
}

/* 主指标卡片样式 */
.stock-bci-main-card {
  background: #f8f9fa;
  padding: 32px;
  border-radius: 12px;
  margin: 24px 0 40px;
  text-align: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.stock-bci-main-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.stock-bci-card-title {
  color: #666;
  font-size: 18px;
  margin: 0 0 24px;
  font-weight: normal;
}

.stock-bci-grade {
  font-size: 48px;
  font-weight: 600;
  color: #1a73e8;
  margin: 0 0 16px;
}

.stock-bci-grade-description {
  font-size: 20px;
  color: #333;
  margin-bottom: 24px;
}

.stock-bci-indicator {
  width: 200px;
  height: 8px;
  margin: 0 auto;
  position: relative;
  background: linear-gradient(to right, #D32F2F, #FDD835, #388E3C);
  border-radius: 4px;
}

.stock-bci-marker {
  position: absolute;
  top: -4px;
  width: 16px;
  height: 16px;
  background: #fff;
  border: 3px solid #1a73e8;
  border-radius: 50%;
  transform: translateX(-50%);
  z-index: 1;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

/* 指标网格样式 */
.stock-bci-metrics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 24px;
  margin-top: 32px;
}

.stock-bci-metric-card {
  background: #f8f9fa;
  padding: 24px;
  border-radius: 12px;
  text-align: center;
}

.stock-bci-category {
  font-size: 24px;
  color: #333;
  margin: 8px 0 24px;
  font-weight: 500;
  text-align: center;
  padding: 12px 24px;
  background: #f8f9fa;
  border-radius: 8px;
  display: inline-block;
}

/* BCI Overview Card Styles */
.stock-bci-overview-card {
  background: #f8f9fa;
  padding: 24px;
  border-radius: 12px;
  margin-bottom: 32px;
  text-align: center;
}

.stock-bci-overview-title {
  color: #1a73e8;
  font-size: 20px;
  margin: 0 0 24px;
  font-weight: 500;
}

.stock-bci-overview-content {
  max-width: 320px;
  margin: 0 auto;
}

.stock-bci-overview-indicator {
  width: 100%;
  margin: 16px 0;
}

.stock-bci-overview-bar {
  width: 200px;
  height: 8px;
  margin: 0 auto;
  position: relative;
  background: linear-gradient(to right, #D32F2F, #FDD835, #388E3C);
  border-radius: 4px;
}

.stock-bci-overview-marker {
  position: absolute;
  top: -4px;
  width: 16px;
  height: 16px;
  background: #fff;
  border: 3px solid #1a73e8;
  border-radius: 50%;
  transform: translateX(-50%);
  z-index: 1;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.stock-bci-overview-value {
  font-size: 24px;
  font-weight: 600;
  color: #1a1a1a;
  margin-top: 16px;
  text-transform: capitalize;
} 