.valuation-premium-container {
  padding: 24px;
}

.valuation-premium-metrics-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  margin-bottom: 24px;
}

.vp-metric-card {
  background: #f8f9fa;
  padding: 24px;
  border-radius: 12px;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  min-height: 120px;
  text-align: center;
}

.vp-metric-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  background: #fff;
}

.vp-metric-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-bottom: 8px;
}

.vp-metric-label {
  color: #1a73e8;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.3px;
}

.vp-metric-value {
  color: #1a1a1a;
  font-size: 28px;
  font-weight: 600;
  margin-top: auto;
}

.vp-tooltip-icon {
  display: none;
}

.nav-premium-history-chart {
  margin-top: 32px;
}

.chart-range-selector {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.range-button {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #333;
  cursor: pointer;
  font-size: 14px;
  margin: 0 8px;
  padding: 8px 16px;
  transition: all 0.3s ease;
}

.range-button:hover {
  background: #f5f5f5;
}

.range-button.active {
  background: #1a73e8;
  color: #fff;
}

@media (max-width: 1024px) {
  .valuation-premium-metrics-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 640px) {
  .valuation-premium-metrics-grid {
    grid-template-columns: 1fr;
  }
}

/* 添加饼图容器样式 */
.vp-chart-section {
  display: flex;
  justify-content: center;
  margin: 0 0 40px 0;
  padding: 24px;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.vp-chart-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 240px;
  padding: 24px 32px;
  background: #ffffff;
  border-radius: 16px;
}

.vp-level-label {
  font-size: 24px;
  font-weight: 500;
  color: #1a1a1a;
  margin-top: 24px;
  text-align: center;
  padding: 12px 24px;
  border-radius: 24px;
  background: #f8f9fa;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

/* 响应式调整 */
@media (max-width: 768px) {
  .vp-chart-section {
    margin: 24px 0;
  }
}

.vp-history-chart {
  background: #fff;
  border-radius: 12px;
  padding: 24px;
  margin-top: 24px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.vp-chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.vp-chart-title {
  font-size: 20px;
  color: #1a73e8;
  margin: 0;
  font-weight: 500;
}

.vp-time-range-selector {
  display: flex;
  gap: 8px;
  background: #f8f9fa;
  padding: 4px;
  border-radius: 8px;
}

.vp-range-button {
  padding: 6px 12px;
  border: none;
  background: none;
  border-radius: 6px;
  color: #666;
  cursor: pointer;
  font-size: 13px;
  transition: all 0.2s;
}

.vp-range-button:hover {
  background: rgba(26, 115, 232, 0.1);
  color: #1a73e8;
}

.vp-range-button.active {
  background: #1a73e8;
  color: white;
}

.vp-echarts {
  height: 400px;
  width: 100%;
} 