.fin-section-title-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 24px 0;
    padding: 0;
  }
  
  .fin-section-indicator {
    width: 4px;
    height: 24px;
    background-color: #1a73e8;
    border-radius: 2px;
    display: inline-block;
    flex-shrink: 0;
  }
  
  .fin-section-title {
    color: #1a1a1a;
    font-size: 24px;
    font-weight: 600;
    margin: 0;
    padding: 0;
    line-height: 1.2;
  }
  
  .fin-section-title a {
    color: inherit;
    text-decoration: none;
    display: inline-block;
    position: relative;
  }
  
  .fin-section-title a:hover {
    color: #1a73e8;
  }
  
  .fin-section-title a:hover::after {
    content: ' #';
    color: #1a73e8;
    opacity: 0.7;
  }