.failure-container {
  text-align: center;
  padding: 48px;
  background: white;
  border-radius: 16px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.08);
}

.failure-container h2 {
  color: #D32F2F;
  margin-bottom: 12px;
}

.action-buttons {
  display: flex;
  gap: 16px;
  justify-content: center;
  margin-top: 32px;
}

.action-buttons button {
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.retry-button {
  background-color: #4CAF50;
  color: white;
}

.retry-button:hover {
  background-color: #388E3C;
  transform: translateY(-1px);
}

.profile-button {
  background-color: #f5f5f5;
  color: #666;
}

.profile-button:hover {
  background-color: #e0e0e0;
  transform: translateY(-1px);
}

/* Animation for failure icon */
.failure-icon svg {
  animation: rotate 0.3s ease-out;
}

@keyframes rotate {
  0% { transform: rotate(-180deg); }
  100% { transform: rotate(0); }
} 