.longterm-premium-container {
  padding: 32px;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.06);
}

.longterm-premium-header {
  text-align: center;
  margin-bottom: 40px;
}

.longterm-premium-title {
  font-size: 32px;
  font-weight: 600;
  color: #1a1a1a;
  margin: 0 0 8px 0;
}

.longterm-premium-subtitle {
  font-size: 16px;
  color: #64748b;
}

.longterm-premium-main {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}

.longterm-premium-rating {
  max-width: 600px;
  margin: 0 auto;
}

.longterm-premium-rating-gauge {
  position: relative;
  width: 200px;
  height: 200px;
  margin: 0 auto;
}

.longterm-premium-rating-circle {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #f5f5f5;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.longterm-premium-rating-segments {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  transform: rotate(-90deg);
}

.longterm-premium-rating-segment {
  position: absolute;
  width: 50%;
  height: 50%;
  transform-origin: 100% 100%;
  transition: opacity 0.3s ease;
}

.longterm-premium-rating-segment.segment-A {
  transform: rotate(0deg) skew(30deg);
  background-color: #2E7D32;
}

.longterm-premium-rating-segment.segment-B {
  transform: rotate(72deg) skew(30deg);
  background-color: #4CAF50;
}

.longterm-premium-rating-segment.segment-C {
  transform: rotate(144deg) skew(30deg);
  background-color: #FFA726;
}

.longterm-premium-rating-segment.segment-D {
  transform: rotate(216deg) skew(30deg);
  background-color: #EF5350;
}

.longterm-premium-rating-segment.segment-E {
  transform: rotate(288deg) skew(30deg);
  background-color: #B71C1C;
}

.longterm-premium-rating-needle {
  position: absolute;
  width: 4px;
  height: 50%;
  left: calc(50% - 2px);
  bottom: 50%;
  transform-origin: bottom center;
  transition: transform 0.3s ease;
  z-index: 2;
}

.longterm-premium-rating-center {
  position: absolute;
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 50%;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 3;
}

.longterm-premium-rating-labels {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  padding: 0;
  width: 100%;
  max-width: 400px;
  margin: 24px auto 0;
}

.longterm-premium-rating-labels span {
  font-size: 14px;
  color: #64748b;
  transition: color 0.3s ease;
  padding: 0 4px;
  text-align: center;
  flex: 1;
  white-space: nowrap;
}

.longterm-premium-rating-labels span.active {
  color: #1a1a1a;
  font-weight: 600;
}

.longterm-premium-rating-value {
  text-align: center;
  font-size: 28px;
  font-weight: 600;
  margin-top: 16px;
  transition: color 0.3s ease;
  color: #fbbf24;
}

@media (max-width: 768px) {
  .longterm-premium-container {
    padding: 24px;
  }
  
  .longterm-premium-rating-gauge {
    width: 160px;
    height: 160px;
  }
  
  .longterm-premium-rating-value {
    font-size: 24px;
  }
} 