@import './SharedStyles.css';

.pricing-page {
  background: linear-gradient(135deg, #ffffff 0%, #f8f9fa 100%);
  min-height: calc(100vh - 80px);
  padding: 60px 0 0;
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: column;
}

.content {
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
  flex: 1;
  padding: 0 20px;
}

.pricing-title {
  text-align: center;
  font-size: 2.5em;
  color: #2d3436;
  margin-bottom: 16px;
  font-weight: 600;
  position: relative;
  display: inline-block;
  left: 50%;
  transform: translateX(-50%);
}

.pricing-title::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(90deg, #4a90e2, #67b246);
  background-size: 200% 100%;
  animation: gradient 15s ease infinite;
}

.pricing-subtitle {
  text-align: center;
  font-size: 1.1em;
  color: #666;
  margin-bottom: 48px;
}

.pricing-plans {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  max-width: 900px;
  margin: 0 auto 64px;
  padding: 20px;
}

.plan {
  background: white;
  border-radius: 12px;
  padding: 32px;
  text-align: center;
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 300px;
  border: 2px solid #e0e0e0;
}

.plan::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(90deg, #4a90e2, #67b246);
  border-radius: 12px 12px 0 0;
}

.plan:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
}

.plan:nth-child(2) {
  border-color: #ff9800;
  background-color: #fff3e0;
}

.plan-header {
  font-size: 1.5em;
  color: #2d3436;
  margin-bottom: 24px;
  font-weight: 500;
}

.plan-price {
  margin: 20px 0;
  position: relative;
}

.price-amount {
  font-size: 2.2em;
  font-weight: 600;
  color: #2d3436;
  line-height: 1.2;
}

.price-period {
  font-size: 1em;
  color: #666;
  margin-left: 2px;
}

.original-price {
  color: #999;
  text-decoration: line-through;
  font-size: 1.1em;
  margin-bottom: 8px;
}

.save-badge {
  position: absolute;
  top: 12px;
  right: 12px;
  background: linear-gradient(135deg, #4a90e2, #67b246);
  color: white;
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 0.85em;
  font-weight: 500;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.savings-text {
  font-size: 0.9em;
  color: #666;
  margin: 12px 0;
}

.subscribe-button {
  width: 100%;
  padding: 12px;
  background: linear-gradient(135deg, #4a90e2, #67b246);
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 1em;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-top: auto;
}

.subscribe-button:hover {
  background: linear-gradient(135deg, #357abd, #4a9132);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.subscribe-button:disabled {
  background: #ccc;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.free-trial-info,
.one-day-pass {
  background: white;
  border-radius: 15px;
  padding: 40px;
  margin: 48px auto;
  text-align: center;
  max-width: 800px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  position: relative;
  overflow: hidden;
}

.free-trial-info::before,
.one-day-pass::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(90deg, #4a90e2, #67b246);
  opacity: 0.7;
}

.free-trial-info h2,
.one-day-pass h2 {
  font-size: 1.8em;
  color: #2d3436;
  margin-bottom: 20px;
  font-weight: 500;
}

.free-trial-info p,
.one-day-pass p {
  font-size: 1.1em;
  color: #666;
  line-height: 1.6;
  margin-bottom: 24px;
}

@keyframes gradient {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

@media (max-width: 768px) {
  .pricing-plans {
    grid-template-columns: 1fr;
    padding: 10px;
  }

  .plan {
    padding: 24px;
    min-height: 280px;
  }

  .save-badge {
    top: 8px;
    right: 8px;
  }

  .pricing-title {
    font-size: 2em;
  }

  .free-trial-info,
  .one-day-pass {
    padding: 30px;
    margin: 30px auto;
  }
}

.footer-copyright {
  background: linear-gradient(90deg, #4a90e2, #67b246);
  color: white;
  text-align: center;
  padding: 16px;
  width: 100%;
  margin-top: 40px;
  font-size: 0.9em;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .footer-copyright {
    margin-top: 30px;
    padding: 12px;
    font-size: 0.85em;
  }
}

.upgrade-info {
  background: white;
  border-radius: 15px;
  padding: 40px;
  margin: 48px auto;
  text-align: center;
  max-width: 800px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  position: relative;
  overflow: hidden;
}

.upgrade-info::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(90deg, #4a90e2, #67b246);
  opacity: 0.7;
}

.upgrade-info h2 {
  font-size: 2em;
  color: #2d3436;
  margin-bottom: 40px;
  font-weight: 500;
}

.feature-list {
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 700px;
  margin: 0 auto;
  padding: 0 20px;
}

.feature-item {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.feature-header {
  font-size: 1.4em;
  color: #2d3436;
  font-weight: 600;
}

.feature-content {
  font-size: 1.2em;
  color: #666;
  line-height: 1.5;
}

.coming-soon {
  margin-top: 40px;
  font-size: 1.2em;
  color: #4a90e2;
  font-weight: 500;
}

@media (max-width: 768px) {
  .upgrade-info {
    padding: 30px 20px;
    margin: 30px auto;
  }

  .upgrade-info h2 {
    font-size: 1.8em;
    margin-bottom: 30px;
  }

  .feature-header {
    font-size: 1.2em;
  }

  .feature-content {
    font-size: 1.1em;
  }

  .feature-list {
    gap: 24px;
  }

  .coming-soon {
    margin-top: 30px;
    font-size: 1.1em;
  }
}

.offer-banner {
  background-color: #f8f9fa;
  padding: 20px;
  margin: 0 20px 40px;
  border: 2px solid #e0e0e0;
  border-radius: 10px;
  text-align: center;
}

.offer-banner h2 {
  color: #2d3436;
  margin-bottom: 16px;
}

.offer-banner p {
  margin: 8px 0;
  color: #666;
}

.offer-banner strong {
  color: #d9534f;
}
