.longterm-screener {
  padding: 32px;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.longterm-screener-header {
  margin-bottom: 40px;
  text-align: center;
}

.longterm-screener-header h1 {
  font-size: 32px;
  color: #1a1a1a;
  margin-bottom: 16px;
}

.longterm-screener-description {
  font-size: 16px;
  color: #666666;
  max-width: 600px;
  margin: 0 auto;
}

.longterm-screener-content {
  display: flex;
  justify-content: center;
  padding: 40px 0;
}

.longterm-screener-card {
  text-align: center;
  padding: 40px;
  background: #f8f9fa;
  border-radius: 12px;
  max-width: 400px;
}

.longterm-screener-icon {
  font-size: 64px;
  margin-bottom: 24px;
  animation: screener-float 3s ease-in-out infinite;
}

.longterm-screener-card h2 {
  font-size: 24px;
  color: #1a73e8;
  margin-bottom: 16px;
}

.longterm-screener-card p {
  color: #666666;
  line-height: 1.6;
}

@keyframes screener-float {
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
}

.longterm-screener-container {
  padding: 20px;
  width: 100%;
  overflow-x: hidden;
}

.longterm-screener-title {
  font-size: 28px;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid #eee;
}

/* Table wrapper styles */
.longterm-screener-table-wrapper {
  width: 100%;
  overflow-x: auto;
  border-radius: 8px;
  background: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.longterm-screener-table {
  width: 100%;
  border-collapse: collapse;
  background: white;
}

.longterm-screener-table th {
  background: #f8f9fa;
  font-weight: 500;
  color: #5f6368;
  border-bottom: 1px solid #e0e0e0;
  padding: 12px 16px;
  text-align: left;
}

.longterm-screener-table td {
  border-bottom: 1px solid #f0f0f0;
  padding: 12px 16px;
  vertical-align: middle;
}

.longterm-screener-table tr:hover {
  background-color: #f8f9fa;
}

/* Column width settings */
.longterm-screener-table th:nth-child(1),
.longterm-screener-table td:nth-child(1) {
  width: 80px;
  min-width: 80px;
}

.longterm-screener-table th:nth-child(2),
.longterm-screener-table td:nth-child(2) {
  width: 120px;
  min-width: 120px;
}

.longterm-screener-table th:nth-child(3),
.longterm-screener-table td:nth-child(3) {
  width: 140px;
  min-width: 140px;
}

.longterm-screener-table th:nth-child(n+4),
.longterm-screener-table td:nth-child(n+4) {
  width: 140px;
  min-width: 140px;
  text-align: center;
}

/* Price styles */
.longterm-screener-price {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 2px;
}

.price-value {
  font-weight: 500;
  color: #202124;
  font-size: 14px;
}

.price-change {
  font-size: 12px;
  font-weight: 500;
}

.positive-change {
  color: #34a853;
}

.negative-change {
  color: #ea4335;
}

/* Grade indicator styles - New */
.longterm-screener-indicator {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

/* Gradient bar styles for Growth, Momentum and Profitability */
.longterm-screener-indicator-bar {
  position: relative;
  width: 100px;
  height: 4px;
  border-radius: 2px;
  overflow: hidden;
}

.longterm-screener-indicator-pointer {
  position: absolute;
  top: -3px;
  width: 2px;
  height: 10px;
  background: #1a1a1a;
  transform: translateX(-50%);
}

.longterm-screener-indicator-label {
  font-size: 12px;
  color: #5f6368;
  margin-top: 4px;
  white-space: nowrap;
}

/* Color block styles for Premium, Risk, Sentiment */
.longterm-screener-bars-container {
  display: flex;
  flex-direction: row;
  gap: 2px;
  width: 100px;
  height: 4px;
}

.longterm-screener-bar-wrapper {
  flex: 1;
  height: 100%;
}

.longterm-screener-bar {
  width: 100%;
  height: 100%;
  background: #e0e0e0;
}

.longterm-screener-bar-wrapper.active .longterm-screener-bar {
  background: currentColor;
}

/* Grade colors */
.grade-color-A { color: #34a853; }  /* Green */
.grade-color-B { color: #93c47d; }  /* Light Green */
.grade-color-C { color: #fbbc04; }  /* Yellow */
.grade-color-D { color: #ea8235; }  /* Orange */
.grade-color-E { color: #ea4335; }  /* Red */

/* Grade backgrounds for recommendation */
.recommendation-grade {
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 13px;
  font-weight: 500;
  color: white;
  text-align: center;
}

.recommendation-Strong.Buy { background-color: #34a853; }
.recommendation-Buy { background-color: #93c47d; }
.recommendation-Hold { background-color: #fbbc04; }
.recommendation-Sell { background-color: #ea8235; }
.recommendation-Strong.Sell { background-color: #ea4335; }

/* Grade text styles */
.grade-text-very-high,
.grade-text-very-strong,
.grade-text-very-bullish { color: #34a853; }

.grade-text-high,
.grade-text-strong,
.grade-text-bullish { color: #93c47d; }

.grade-text-medium,
.grade-text-neutral { color: #fbbc04; }

.grade-text-low,
.grade-text-weak,
.grade-text-bearish { color: #ea8235; }

.grade-text-very-low,
.grade-text-very-weak,
.grade-text-very-bearish { color: #ea4335; }

/* Market cap styles */
.longterm-screener-metric {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
}

.longterm-screener-metric-value {
  font-weight: 500;
  color: #202124;
}

.longterm-screener-metric-rank {
  font-size: 12px;
  padding: 2px 8px;
  border-radius: 4px;
  font-weight: 500;
}

/* Filter tags styles */
.longterm-screener-filter-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin: 16px 0;
}

.filter-tag {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 6px 12px;
  background: #f1f3f4;
  border-radius: 16px;
  font-size: 13px;
  color: #202124;
}

.filter-tag:hover {
  background: #e8eaed;
}

.filter-tag-type {
  font-weight: 500;
  color: #5f6368;
}

.filter-tag-value {
  color: #1a73e8;
}

.filter-tag-remove {
  border: none;
  background: none;
  color: #5f6368;
  font-size: 18px;
  line-height: 1;
  padding: 0 0 0 4px;
  cursor: pointer;
}

.filter-tag-remove:hover {
  color: #ea4335;
}

/* Sort header styles */
.sortable-header {
  cursor: pointer;
  user-select: none;
}

.longterm-screener-th-content {
  display: flex;
  align-items: center;
  gap: 4px;
}

.sort-arrows {
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-left: 4px;
}

.sort-arrow {
  font-size: 8px;
  color: #bdc1c6;
  line-height: 1;
}

.sorted-ascending .sort-arrow.up,
.sorted-descending .sort-arrow.down {
  color: #1a73e8;
}

.sortable-header:hover .sort-arrow {
  color: #5f6368;
}

/* Ticker link styles */
.ticker-link {
  color: #1a73e8;
  font-weight: 500;
  text-decoration: none;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  font-size: 14px;
}

.ticker-link:hover {
  text-decoration: underline;
}

/* Loading and error states */
.longterm-screener-loading,
.longterm-screener-error {
  text-align: center;
  padding: 40px;
  color: #5f6368;
  font-size: 16px;
}

.longterm-screener-error {
  color: #ea4335;
}

.longterm-screener-grade {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.longterm-screener-grade-bar {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.longterm-screener-grade-label {
  color: white;
  font-weight: 600;
  font-size: 14px;
}

.longterm-screener-grade-description {
  font-size: 14px;
  color: #666;
}

.longterm-screener-ticker-link:hover {
  text-decoration: underline;
}

.grade-container {
  width: 100%;
  padding: 4px 0;
}

.grade-bar-container {
  width: 100%;
  height: 24px;
  background-color: #f1f3f4;
  border-radius: 12px;
  overflow: hidden;
}

.grade-bar {
  height: 100%;
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding: 0 8px;
  transition: width 0.3s ease;
}

.grade-text {
  color: white;
  font-size: 12px;
  white-space: nowrap;
}

.market-cap-container {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.market-cap-value {
  font-weight: 500;
  color: #202124;
}

.market-cap-rank {
  font-size: 12px;
  padding: 2px 6px;
  border-radius: 4px;
  display: inline-block;
  width: fit-content;
}

.market-cap-rank.large {
  background-color: #e8f0fe;
  color: #1a73e8;
}

.market-cap-rank.medium {
  background-color: #fce8e6;
  color: #ea4335;
}

.market-cap-rank.small {
  background-color: #fef7e0;
  color: #f9ab00;
}

.ticker-link:hover {
  text-decoration: underline;
}

/* Price styles */
.price-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.price-change {
  font-size: 12px;
}

.price-change.positive-change {
  color: #34a853;
}

.price-change.negative-change {
  color: #ea4335;
}

/* Sort indicators styles */
.longterm-screener-sort-indicators {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 16px 0;
  flex-wrap: wrap;
}

.sort-indicators-label {
  color: #5f6368;
  font-size: 14px;
}

.sort-indicator {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  background: #f1f3f4;
  border-radius: 16px;
  font-size: 13px;
}

.sort-indicator-text {
  display: flex;
  align-items: center;
  gap: 4px;
}

.sort-indicator-order {
  font-size: 11px;
  color: #5f6368;
}

.sort-indicator-direction {
  color: #1a73e8;
  font-weight: bold;
}

.sort-indicator-remove {
  border: none;
  background: none;
  color: #5f6368;
  cursor: pointer;
  padding: 0 4px;
  font-size: 16px;
  line-height: 1;
}

.sort-indicator-remove:hover {
  color: #ea4335;
}

/* Scroll container styles */
.longterm-screener-scroll-container {
  position: relative;
}

.longterm-screener-top-scroll-wrapper {
  overflow-x: auto;
  overflow-y: hidden;
  height: 20px;
  margin-bottom: -20px;
}

.longterm-screener-scroll-content {
  height: 1px;
}

.longterm-screener-table-wrapper {
  overflow-x: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.longterm-screener-top-scroll-wrapper::-webkit-scrollbar {
  height: 8px;
}

.longterm-screener-table-wrapper::-webkit-scrollbar {
  height: 8px;
}

/* Scrollbar track */
.longterm-screener-top-scroll-wrapper::-webkit-scrollbar-track,
.longterm-screener-table-wrapper::-webkit-scrollbar-track {
  background: #f1f3f4;
  border-radius: 4px;
}

/* Scrollbar handle */
.longterm-screener-top-scroll-wrapper::-webkit-scrollbar-thumb,
.longterm-screener-table-wrapper::-webkit-scrollbar-thumb {
  background: #dadce0;
  border-radius: 4px;
}

/* Scrollbar handle on hover */
.longterm-screener-top-scroll-wrapper::-webkit-scrollbar-thumb:hover,
.longterm-screener-table-wrapper::-webkit-scrollbar-thumb:hover {
  background: #bdc1c6;
}

/* 筛选器样式 */
.longterm-screener-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 20px;
  padding: 16px;
  background: #f8f9fa;
  border-radius: 8px;
}

.filter-dropdown {
  position: relative;
  min-width: 160px;
}

.filter-dropdown-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #dadce0;
  border-radius: 6px;
  background: white;
  cursor: pointer;
  font-size: 14px;
  color: #5f6368;
  transition: all 0.2s ease;
}

.filter-dropdown-button:hover {
  border-color: #1a73e8;
  background-color: #f8f9fa;
}

.filter-dropdown-button.active {
  border-color: #1a73e8;
  color: #1a73e8;
  background-color: #e8f0fe;
}

.filter-dropdown-arrow {
  font-size: 10px;
  transition: transform 0.2s ease;
}

.filter-dropdown-button.active .filter-dropdown-arrow {
  transform: rotate(180deg);
}

.filter-dropdown-content {
  position: fixed; /* 改为 fixed 定位 */
  min-width: 220px;
  margin-top: 4px;
  padding: 8px 0;
  background: white;
  border: 1px solid #dadce0;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-height: 300px;
  overflow-y: auto;
}

.filter-dropdown-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 16px;
  cursor: pointer;
  font-size: 14px;
  color: #3c4043;
  transition: background-color 0.2s ease;
}

.filter-dropdown-item:hover {
  background: #f1f3f4;
}

.filter-dropdown-item input[type="checkbox"] {
  margin: 0;
  width: 16px;
  height: 16px;
  border: 2px solid #5f6368;
  border-radius: 3px;
  cursor: pointer;
}

.filter-dropdown-item input[type="checkbox"]:checked {
  background-color: #1a73e8;
  border-color: #1a73e8;
} 